import moment from "moment";
const months = [
  "ENERO", "FEBRERO", 
  "MARZO", "ABRIL", "MAYO", 
  "JUNIO", "JULIO", "AGOSTO",
  "SEPTIEMBRE", "OCTUBRE", 
  "NOVIEMBRE", "DICIEMBRE"
];

export function COLUMNS_USER_ADMIN_SETTING(toggleEditUser,toggleDeleteUser) {
  return [
    {
      dataField: "usr_id",
      text: "EDITAR PERMISOS",
      formatter: toggleEditUser,
      headerAlign: 'center',
    },
    {
      dataField: "usr_name",
      text: "NOMBRE",
      headerAlign: 'center'
    },
    {
      dataField: "usr_user",
      text: "Usuario",
      headerAlign: 'center',
      classes: 'w-145px',
    },
    {
      dataField: "usr_sts",
      text: "ACTIVO",
      headerAlign: 'center',
      formatter: toggleDeleteUser,
    }
  ];
}

export function COLUMNS_USER_ADMIN(toggleEditUser,toggleDeleteUser) {
    return [
      {
        dataField: "usr_id",
        text: "OPCIONES",
        formatter: toggleEditUser,
        headerAlign: 'center',
      },
      {
        dataField: "usr_name",
        text: "NOMBRE",
        headerAlign: 'center'
      },
      {
        dataField: "usr_code",
        text: "CODIGO",
        headerAlign: 'center',
        classes: 'w-145px',
      },
      {
        dataField: "usr_price",
        text: "LIBRA",
        headerAlign: 'center'
      },
      {
        dataField: "usr_celphone",
        text: "TELEFONO",
        headerAlign: 'center'
      },
      {
        dataField: "usr_email",
        text: "EMAIL",
        headerAlign: 'center'
      },
      {
        dataField: "usr_sts",
        text: "ACTIVO",
        headerAlign: 'center',
        formatter: toggleDeleteUser,
      }
    ];
  }

  export function COLUMNS_REPORT_MENSUAL() {
    return [
      {
        dataField: "usr_name",
        text: "USUARIO",
        classes: 'text-center',
        headerAlign: 'center'
      },
      {
        dataField: "paquetes",
        text: "# PAQUETES",
        classes: 'text-center',
        headerAlign: 'center',
      },
      {
        dataField: "libras",
        text: "LIBRAS",
        classes: 'text-center',
        headerAlign: 'center'
      },
      {
        dataField: "tr_date_received ",
        text: "MES",
        classes: 'text-center',
        headerAlign: 'center',
        formatter: (cell, row) => (months[Number(row.tr_date_received)])
      }
    ];
  }

  export function COLUMNS_CHAT(toggleSelectUser) {
    return [
      {
        dataField: "usr_id",
        text: "CHATS",
        headerAlign: 'center',
        formatter: toggleSelectUser
      },
    ];
  }
  
  export function COLUMNS_COTIZACION_DETAIL(toggleCotizacion,sum) {
    return [
      {
        dataField: "cotd_lin",
        text: "OPCIONES",
        headerAlign: 'center',
        formatter: toggleCotizacion,
        footer: ''
      },
      {
        dataField: "cotd_art_descripcion",
        text: "DESCRIPCION",
        headerAlign: 'center',
        footer: ''
      },
      {
        dataField: "cotd_qty",
        text: "CANTIDAD",
        headerAlign: 'center',
        footer: ''
      },
      {
        dataField: "cotd_price",
        text: "PRECIO U",
        headerAlign: 'center',
         classes: 'w-90px',
        formatter: (cell, row) => "$ "+ (Number(row.cotd_price)).toFixed(2),
        footer: ''
      },
      {
        dataField: "cotd_itbmss",
        text: "ITBMS",
        headerAlign: 'center',
        formatter: (cell, row) => "$ "+(Number((row.cotd_price*row.cotd_qty)*(row.cotd_itbms/100)) ).toFixed(2),
        footer:"TOTAL"
      },
      {
        dataField: "cotd_total",
        text: "TOTAL",
        headerAlign: 'center',
        classes: 'w-90px text-left',
        formatter: (cell, row) => "$ "+(Number((row.cotd_price*row.cotd_qty)*(row.cotd_itbms/100)) + Number(row.cotd_price*row.cotd_qty)).toFixed(2),
        footer: "$ " +sum.toFixed(2),
      }
     
    ];
  }

  export function COLUMNS_COTIZACION(toggleCotizacion,togglePrint) {
    return [
      {
        dataField: "cot_id",
        text: "ID",
        headerAlign: 'center',
        formatter: toggleCotizacion
      },
      {
        dataField: "cot_name",
        text: "NOMBRE",
        headerAlign: 'center',
      },
      {
        dataField: "cot_observacion",
        text: "OBSERVACION",
        headerAlign: 'center',
      },
      {
        dataField: "cot_celular",
        text: "CELULAR",
        headerAlign: 'center',
      },
      {
        dataField: "cot_date",
        text: "FECHA",
        headerAlign: 'center',
        classes: 'w-90px text-center',
        formatter: (cell, row) =>  moment(row.cot_date).format("YYYY-MM-DD"),
      }
    ];
  }

  export function COLUMNS_CLIENTES() {
    return [
      {
        dataField: "usr_name",
        text: "NOMBRE",
        headerAlign: 'center',
      },
      {
        dataField: "usr_code",
        text: "CODIGO",
        headerAlign: 'center',
      }
    ];
  }

  export function COLUMNS_ORDERS(togleSelectTracking,toggleDelivered,toggleLink) {
    return [
      {
        dataField: "ord_id",
        text: "OPCIONES",
        headerAlign: 'center',
        classes: 'text-left',
        formatter: togleSelectTracking
      },
      {
        dataField: "usr_name",
        text: "CLIENTE",
        classes: 'text-left',
        headerAlign: 'center',
        formatter: (cell, row) => `${row.usr_name} ${row.usr_code}`,
      },
      {
        dataField: "ord_track",
        text: "TRACKING",
        classes: 'text-left',
        headerAlign: 'center',
      },
      {
        dataField: "ord_desc",
        text: "DESCRIPCION",
        classes: 'text-left',
        headerAlign: 'center',
      },
      {
        dataField: "ord_link",
        text: "LINK DEL ARTICULO",
        classes: 'text-left',
        headerAlign: 'center',
        formatter: toggleLink
      },
      {
        dataField: "ord_precio",
        text: "PRECIO",
        classes: 'text-center',
        headerAlign: 'center',
        formatter: (cell, row) => `B/. ${Number(row.ord_precio).toFixed(2)}`,
      },
      {
        dataField: "tr_price",
        text: "PRECIO TOTAL",
        classes: 'text-center',
        headerAlign: 'center',
        formatter: (cell, row) => `B/. ${(Number(row.ord_precio)+Number(row.tr_price)).toFixed(2)}`,
      },
      {
        dataField: "ord_date_deliverd",
        text: "ENTREGADO",
        classes: 'text-center',
        headerAlign: 'center',
        formatter:toggleDelivered
      }
    ];
  }

  export function COLUMNS_TRACKING_SELECT_LOST(toggleEdit,toggleDelivered) {
    return [
      {
        dataField: "los_id",
        text: "OPCIONES",
        headerAlign: 'center',
        classes: 'text-left',
        formatter: toggleEdit,
      },
      {
        dataField: "los_track",
        text: "TRACKING",
        classes: 'text-left',
        headerAlign: 'center',
      },
      {
        dataField: "usr_name",
        text: "USUARIO",
        classes: 'text-left',
        headerAlign: 'center',
      },
      {
        dataField: "usr_code",
        text: "CODIGO",
        classes: 'text-left',
        headerAlign: 'center',
      },
      {
        dataField: "los_desc",
        text: "DESCRIPCION",
        classes: 'text-left',
        headerAlign: 'center',
      },
      {
        dataField: "los_date",
        text: "FECHA REGISTRO",
        classes: 'text-center',
        headerAlign: 'center',
        formatter: (cell, row) =>  moment(row.los_date).format("YYYY-MM-DD"),
      },
      {
        dataField: "los_date_client",
        text: "ENTREGADO",
        formatter: toggleDelivered,
        classes: 'text-center',
        headerAlign: 'center',
      }
    ];
  }

  export function COLUMNS_TRACKING_SELECT(toggleMakeDelivered) {
    return [
      {
        dataField: "tr_id",
        text: "ID",
        headerAlign: 'center',
      },
      {
        dataField: "tr_track",
        text: "TRACKING",
        headerAlign: 'center',
      },
      {
        dataField: "usr_name",
        text: "USUARIO",
        headerAlign: 'center',
      },
      {
        dataField: "usr_code",
        text: "CODIGO",
        headerAlign: 'center',
      },
      {
        dataField: "tr_price",
        text: "PRECIO",
        classes: 'text-right',
        formatter: (cell, row) => `B/. ${row.tr_price}`,
        headerAlign: 'center',
      },
      {
        dataField: "lot_desc",
        text: "DESCRIPCION",
        headerAlign: 'center',
      },
      {
        dataField: "tr_make_delivered",
        text: "ENTREGADO",
        formatter: toggleMakeDelivered,
        headerAlign: 'center',
      }
    ];
  }

  export function COLUMNS_PRINT(togglePrint) {
    return [
      {
        dataField: "usr_name",
        text: "CLIENTE",
        classes: 'text-left',
        headerAlign: 'center',
        formatter: (cell, row) => `${row.usr_name} ${row.usr_code}`,
      },
      {
        dataField: "pr_print",
        text: "IMPRIMIR",
        formatter: togglePrint,
        headerAlign: 'center',
      }
    ];
  }

  export function COLUMNS_LOTE_ADMIN(toggleEditLot,toggleCloseLot) {
    return [
      {
        dataField: "lot_id",
        text: "OPCIONES",
        formatter: toggleEditLot,
        headerAlign: 'center',
      },
      {
        dataField: "lot_desc",
        text: "DESCRIPCION",
        headerAlign: 'center',
      },
      {
        dataField: "tr_qty",
        text: "PAQUETES",
        headerAlign: 'center',
        classes: 'text-center',
      },
      {
        dataField: "tr_libra",
        text: "LIBRAS",
        classes: 'text-center',
        headerAlign: 'center',
      },
      {
        dataField: "tr_price",
        text: "TOTAL",
        headerAlign: 'center',
        classes: 'text-center',
      },
      {
        dataField: "lot_date",
        text: "FECHA",
        headerAlign: 'center',
        classes: 'text-center',
        formatter: (cell, row) =>  moment(row.lot_date).format("YYYY-MM-DD"),
      },
      {
        dataField: "lot_process",
        text: "PROCESAR",
        formatter: toggleCloseLot,
        headerAlign: 'center',
      }
    ];
  }

  export function COLUMNS_TRACKING_ADMIN(toggleEditTracking,formatPrice) {
    return [
      {
        dataField: "tr_id",
        text: "OPCIONES",
        formatter: toggleEditTracking,
        headerAlign: 'center',
        footer: 'TOTAL',
      },
      {
        dataField: "tr_track",
        text: "TRACKING",
        classes: 'text-left',
        headerAlign: 'center',
        footer: ''
      },
      {
        dataField: "usr_name",
        text: "NOMBRE",
        headerAlign: 'center',
        footer: ''
      },
      {
        dataField: "usr_code",
        text: "CODIGO",
        headerAlign: 'center',
        footer: ''
      },
      {
        dataField: "tr_use_tarjeta",
        text: "USE MI TARJETA",
        formatter: (cell, row) => `${row.tr_use_tarjeta==="1"? "SI": "NO"}`,
        headerAlign: 'center',
        footer: ''
      },
      {
        dataField: "tr_libra",
        text: "LIBRA",
        headerAlign: 'center',
        footer: columnData => columnData.reduce((acc, item) => (Number(acc) + Number(item))  , 0) 
      },
      {
        dataField: "tr_price",
        text: "TOTAL",
        classes: 'text-right',
        formatter: (cell, row) => `$ ${parseFloat(row.tr_price).toFixed(2)}`,
        headerAlign: 'center',
        footer: columnData => "$ " + columnData.reduce((acc, item) => ((Number(acc) + Number(item))).toFixed(2)  , 0) 
      },
      {
        dataField: "tr_date_received",
        text: "RECIBIDO",
        headerAlign: 'center',
        footer: ''
      }
    ];
  }