import React, { Component } from "react";
import { Row, Col, Label, Container} from "reactstrap";
import { getUsers, postUser, putUser } from "../Services";
import { PENCIL} from "../SrcImages";

class Tracking extends Component {
  constructor() {
    super();
    this.state = {
      dataFormTracking: [],
      activeTab:"",
      formTracking: {
        tr_id: "",
        tra_id: "",
        tr_track: "",
        tr_desc: "",
        tr_price: "",
        tr_user: "",
        tr_type_box: "",
        tr_libra: "",
        tr_date_delivered: "",
        tr_date_received: "",
        tr_status: "",
      },
      loading: false,
      isOpenModalAddTracking: false,
      isOpenModalEditTracking: false,
    };
    this.onchangeFormTracking = this.onchangeFormTracking.bind(this);
    this.toggleSaveTracking = this.toggleSaveTracking.bind(this);
    this.handleModalAddTracking = this.handleModalAddTracking.bind(this);
    this.toggleEditTracking = this.toggleEditTracking.bind(this);
    this.fetchTracking = this.fetchTracking.bind(this);
    this.handlerSetTracking = this.handlerSetTracking.bind(this);
    this.handleModalEditTracking = this.handleModalEditTracking.bind(this);
  }

  async fetchTracking() {
    const response = await getUsers();
    this.setState({
      dataFormTracking: response,
      isOpenModalAddTracking: false,
      isOpenModalEditTracking: false,
      loading: false,
    });
  }

  toggleSaveTracking() {
    const props = this.state.formTracking;
    if (props.usr_id.length === 0) {
      const body = {
        tr_id: props.tr_id,
        tra_id: props.tra_id,
        tr_track: props.tr_track,
        tr_desc: props.tr_desc,
        tr_price: props.tr_price,
        tr_user: props.tr_user,
        tr_type_box: props.tr_type_box,
        tr_libra: props.tr_libra,
        tr_date_delivered: props.tr_date_delivered,
        tr_date_received: props.tr_date_received,
        tr_status: props.tr_status,
      };
      postUser(body);
      setTimeout(() => {
        this.fetchTracking();
      }, 1000);
    } else {
      const body = {
        tr_id: props.tr_id,
        tra_id: props.tra_id,
        tr_track: props.tr_track,
        tr_desc: props.tr_desc,
        tr_price: props.tr_price,
        tr_user: props.tr_user,
        tr_type_box: props.tr_type_box,
        tr_libra: props.tr_libra,
        tr_date_delivered: props.tr_date_delivered,
        tr_date_received: props.tr_date_received,
        tr_status: props.tr_status,
      };

      putUser(body);
      setTimeout(() => {
        this.fetchTracking();
      }, 1000);
    }
  }

  onchangeFormTracking(e) {
    this.setState({
      formTracking: {
        ...this.state.formTracking,
        [e.target.name]: e.target.value,
      },
    });
  }
  handleModalEditTracking = () => {
    this.setState((prevState) => ({
      isOpenModalEditTracking: !prevState.isOpenModalEditTracking,
      formTracking: {
        tr_id: "",
        tra_id: "",
        tr_track: "",
        tr_desc: "",
        tr_price: "",
        tr_user: "",
        tr_type_box: "",
        tr_libra: "",
        tr_date_delivered: "",
        tr_date_received: "",
        tr_status: "",
      },
    }));
  };

  handleModalAddTracking = () => {
    this.setState((prevState) => ({
      isOpenModalAddTracking: !prevState.isOpenModalAddTracking,
      formTracking: {
        tr_id: "",
        tra_id: "",
        tr_track: "",
        tr_desc: "",
        tr_price: "",
        tr_user: "",
        tr_type_box: "",
        tr_libra: "",
        tr_date_delivered: "",
        tr_date_received: "",
        tr_status: "",
      },
    }));
  };

  rechargeData() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchTracking();
    }, 1000);
  }
  // onClick={() => this.handlerSearchCuota(row)}
  handlerSetTracking(row) {
    this.setState((prevState) => ({
      isOpenModalEditTracking: !prevState.isOpenModalEditTracking,
      formTracking: row,
    }));
  }

  toggleEditTracking(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <Label
              className="txt-underline"
              onClick={() => this.handlerSetTracking(row)}
            >
              {row.usr_id}
            </Label>
            <img
              className="img-status img-search"
              onClick={() => this.handlerSetTracking(row)}
              src={PENCIL}
              alt=""
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  componentDidMount() {
    // this.fetchTracking();
  }

  openPage(id) {
if(id==="1"){
  window.open("https://tools.usps.com/go/TrackConfirmAction_input","_blank")
}
else if(id==="2"){
  window.open("https://es-us.ups.com/track?loc=es_US&requester=ST/","_blank")
}
else if(id==="3"){
  window.open("https://www.fedex.com/es-pa/tracking.html","_blank")
}
else if(id==="4"){
  window.open("https://www.dhl.com/es-es/home.html","_blank")
}
// else{
//   window.open("https://www.17track.net/es","_blank")
// }
  }
  render() {
    document.body.classList.add('body-class');
    return (
      <React.Fragment>
        <Container className="div-container">
        <Row >
        <Col xs={12} className="text-center">
              <h4> Paginas de rastreo</h4>
            </Col>
          <Col xs={12} className="mt-2">
          <iframe src={"https://fuzioncargo.com/seguimiento/"} height={"900"} width={"100%"} title="Iframe"/>         
          </Col>
        </Row>
        </Container>

       
      </React.Fragment>
    );
  }
}

export default Tracking;
