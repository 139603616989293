import './App.css';
import './assets/css/style.css';
import CrearTracking from './Components/Carga/CrearTrackingIndex'
import Navbar from "../src/Components/Menu/Index";
import Home from '../src/Components/Home/Index';
import Tracking from './Components/Carga/Index';
import CreateUser from './Components/User/Index';
import ConsultarUser from './Components/Trackings/Index';
import Lost from './Components/Lost/Index';
import Chat from './Components/Chat/Index';
import Print from './Components/Print/Index';
import Settings from "./Components/Settings/Index";
import Orders from "./Components/Orders/Index";
import Cotizaciones from "./Components/Cotizaciones/Index"
import { Switch, Route,Redirect, HashRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import StoreProvider from "./Components/Store/Provider";
import History from "./Components/Store/History";
import { RoutesPrivate } from "./Components/Store/Private";
import Login from "./Components/Menu/Login";

function App() {
  return (
    <HashRouter history={History} basename="/">
      <StoreProvider>
        <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route path="/login" component={Login} />
          <Route path='/admin/:path?' exact >
            <Navbar >
              <RoutesPrivate exact path='/admin/home' component={Home}  ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/crear' component={CreateUser}  ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/creartracking' component={CrearTracking}  ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/consultar' component={ConsultarUser} ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/carga' component={Tracking}  ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/lost' component={Lost}  ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/chat' component={Chat}  ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/orders' component={Orders}  ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/cotizacion' component={Cotizaciones} ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/print' component={Print}  ></RoutesPrivate>
              <RoutesPrivate exact path='/admin/settings' component={Settings}  ></RoutesPrivate>
            </Navbar>
          </Route>
        </Switch>
      </StoreProvider>
    </HashRouter>
  );
}

export default App;