
import React from "react";
import moment from "moment";
import History from './../Store/History';
import { CHANGE_PASSWORD } from "./../Util";
import StoreContext from "./../../Components/Store/Context";
import {LOGO} from "./../SrcImages";
import { url,postRegisterUser,getCountMsg,putPassword} from "./../Services";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Label,
} from "reactstrap";

class LayoutAccount extends React.Component{
  static contextType = StoreContext
  constructor(props) {
    super(props);
    this.state = {
      children:props.children,
      formSetting: {
        set_id: "",
        set_name: "",
        set_price_libra: "",
        set_price_manejo: "",
        set_price_tarjeta: "",
        set_siglas: "",
        set_address: "",
        count: "0",
        rol_home:"0",
        rol_user:"0",
        rol_track:"0",
        rol_carga:"0",
        rol_lost:"0",
        rol_orders:"0",
        rol_cot:"1",
        rol_chat:"0",
        rol_print:"0",
        rol_setting:"0"
      },
      formLogin:{
        usr_password:"",
        usr_password2:"",
      },
      isOpen: false,
      msg_error: "",
    isOpenModalPassword:false
    };
    this.handleModalLogin = this.handleModalLogin.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.login = this.login.bind(this);
    this.onChangeFLogin = this.onChangeFLogin.bind(this);
    this.setError = this.setError.bind(this);
    this.handlerRegister = this.handlerRegister.bind(this);
    this.toggleSaveRegister = this.toggleSaveRegister.bind(this);
    this.fetchSetting = this.fetchSetting.bind(this);
        this.toggle = this.toggle.bind(this);
        this.cerrarSession = this.cerrarSession.bind(this);
        this.handleModalPassword = this.handleModalPassword.bind(this);
        this.onchageFormPassword = this.onchageFormPassword.bind(this);
        this.toggleSavePassword = this.toggleSavePassword.bind(this);
      }
      onchageFormPassword(e) {
        this.setState({
          formLogin: {
            ...this.state.formLogin,
            [e.target.name]: e.target.value,
          },
        });
      }
      handleModalPassword = () => {
        this.setState((prevState) => ({
          isOpenModalPassword: !prevState.isOpenModalPassword,
          msg_error: "",
          dataFLogin: {
            celphone: "",
            password: "",
          },
        }));
      };
      toggleSavePassword() {
        const props = this.state.formLogin;
        if(props.usr_password.length>0 && props.usr_password2.length>0)
        {
          if(props.usr_password === props.usr_password2){
            const body = {
              usr_id: this.context.usr_id,
              usr_password:props.usr_password
            };
            putPassword(body);
            this.setState({
              msg_error:"",
              isOpenModalPassword:false
            });
          }
          else{
            this.setState({
              msg_error:"Las contraseñas no son iguales"
            });
          }
        }
        else{
          this.setState({
            msg_error:"Campos en Blanco"
          });
        }
      }
      componentDidMount() {
        this.fetchSetting();
        if(this.context.usr_name !== undefined){
          document.title = this.context.usr_name.toUpperCase() + " - ADMIN";
        }
      }
    
      async fetchSetting() {
        const response = await getCountMsg(this.context.usr_id);
        var rol_home ="0";
        var rol_user ="0";
        var rol_track ="0";
        var rol_carga ="0";
        var rol_lost ="0";
        var rol_orders ="0";
        var rol_cot ="0";
        var rol_chat ="0";
        var rol_print ="0";
        var rol_setting ="0";
        if(response.role[0] !== undefined)
        {
          rol_home = response.role[0].rol_home;
          rol_user =response.role[0].rol_user;
          rol_track =response.role[0].rol_track;
          rol_carga =response.role[0].rol_carga;
          rol_lost =response.role[0].rol_lost;
          rol_orders =response.role[0].rol_orders;
          rol_cot =response.role[0].rol_cot;
          rol_chat =response.role[0].rol_chat;
          rol_print =response.role[0].rol_print;
          rol_setting =response.role[0].rol_setting;
        }
        this.context.setRole({
          rol_home:rol_home,
          rol_user:rol_user,
          rol_track:rol_track ,
          rol_carga:rol_carga ,
          rol_lost:rol_lost ,
          rol_orders:rol_orders ,
          rol_cot:rol_cot ,
          rol_chat:rol_chat ,
          rol_print:rol_print ,
          rol_setting:rol_setting 
        });
        this.setState({
          formSetting: {
            count: response.count[0].count,
            rol_home:rol_home,
            rol_user:rol_user,
            rol_track:rol_track ,
            rol_carga:rol_carga ,
            rol_lost:rol_lost ,
            rol_orders:rol_orders ,
            rol_cot:rol_cot ,
            rol_chat:rol_chat ,
            rol_print:rol_print ,
            rol_setting:rol_setting 
          },
          loading: false,
          isOpen:false
        });
      }
    
      toggle() {
        this.setState((prevState) => ({
          isOpen: !prevState.isOpen,
        }));
      }

      cerrarSession() {
        this.context.quitToken();
      }

  toggleSaveRegister() {
    const props = this.state.dataFLogin;
    if ((props.usr_password1 = props.usr_password2)) {
     
      if (props.usr_name.length > 6 &&  props.usr_email.length > 0  &&  props.usr_address.length > 0  &&  props.usr_birthday.length > 0  &&  props.usr_celphone.length > 0 ) {
        const body = {
          usr_name: props.usr_name,
          usr_user: props.usr_celphone,
          usr_password: props.usr_password1,
          usr_email: props.usr_email,
          usr_cedula: props.usr_cedula,
          usr_address: props.usr_address,
          usr_birthday: moment(props.usr_birthday).format("YYYY-MM-DD"),
          usr_sex:props.usr_sex
        };
        postRegisterUser(body);
      } else {
        this.setState({
          msg_error: "Campos vacios",
        });
      }
    } else {
      this.setState({
        msg_error: "Contraseñas diferentes",
      });
    }
  }

  handleModalLogin() {
    this.setState((prevState) => ({
      loginOpen: !prevState.loginOpen,
      dataFLogin: {
        celphone: "",
        password: "",
      },
      msg_error: "",
      isCollapse: false,
    }));
  }

  setError(error) {
    this.setState({

      msg_error: error,

    });
  }

  async login({ user, password }) {
    if (user.length > 0 && password.length > 0) {
      const dataBody = {
        user,
        password,
      };
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(dataBody),
      };
      const response = await fetch(
        url + "LoginController/login",
        requestOptions
      );
      const result = await response.json();

      if (result[0].count !=="0") {
        return {
          token: true,
          usr_id: result[0].usr_id,
          usr_name: result[0].usr_name,
          usr_type: result[0].usr_type,
        };
      } else {
        return { error: "Usuario o contraseña incorrectos" };
      }
    } else {
      return { error: "Campos estan vacios" };
    }
  }

  async onSubmit(event) {
    event.preventDefault();
    const props = this.state.dataFLogin;
    const { token, usr_id, usr_name, usr_type, error } = await this.login({
      user: props.celphone,
      password: props.password,
    });

    if (token) {
      this.context.quitToken();
      this.context.setToken({ token, usr_id, usr_name,usr_type});
      if(usr_type ==="2"){
        return History.push("/");
      }
      else{
        return  History.push("profile/account");
      }
      
    }
    this.setError(error);
  }

  toggleCollapse() {
    this.setState((prevState) => ({
      isCollapse: !prevState.isCollapse,
    }));
  }

  onChangeFLogin(e) {
    this.setState({
      dataFLogin: {
        ...this.state.dataFLogin,
        [e.target.name]: e.target.value,
      },
    });
  }

  handlerRegister(){
    this.setState({
      isRegisterOpen: true,
      dataFLogin: {
        celphone: "",
        password: "",
        usr_name:"",
        usr_email:"",
        usr_address:"",
        usr_birthday:"",
        usr_sex:"",
        usr_celphone:"",
      },
      msg_error: "",
    });
  };

  render() {
    const props = this.state;
    const role = props.formSetting;
    return (
      <React.Fragment>
          {props.isOpenModalPassword && (
          <CHANGE_PASSWORD
          onChange={this.onchageFormPassword}
          isOpen = {props.isOpenModalPassword}
          toggle ={this.handleModalPassword}
          toggleSave={this.toggleSavePassword}
          formLogin ={props.formLogin}
            msg_error={props.msg_error}
          />
        )}

      <div className="themed-container containt-contentt mb-1">
         <Row>
           <Col xs={12}>
             <Navbar  light expand="md" className="navbar-default">
               <NavbarToggler onClick={this.toggle} />
               <NavbarText className="text-white d-flex d-block d-sm-none">
                <img className="header-logo" alt="" src={LOGO} title="Desarrollado por www.mtechnologypanama.com Diego Martinez" onClick={() => {
                      this.handleModalPassword();
                    }}/>
                <Label className="title-header">
                  {this.context.usr_name === undefined ? null: this.context.usr_name.toUpperCase()}
                  </Label>
                </NavbarText>
               <Collapse isOpen={this.state.isOpen} navbar>
                 <Nav className="mr-auto" navbar>
                   {role.rol_home==="1"?
                   <NavItem>
                     <NavLink className="text-white pl-10px" tag={Link} to="home" onClick={this.fetchSetting} >
                       Inicio
                     </NavLink>
                  </NavItem> 
                  :null}
                  {role.rol_user==="1"?
                   <NavItem>
                     <NavLink className="text-white pl-10px" tag={Link} to="crear" onClick={this.fetchSetting} >
                       Usuarios
                     </NavLink>
                   </NavItem>
                   :null}

                   <NavItem>
                     <NavLink
                      className="text-white pl-10px"
                      tag={Link}
                      to="creartracking"
                      onClick={this.fetchSetting}
                    >
                      Rastreo
                    </NavLink>
                  </NavItem>
                  {role.rol_track==="1"?
                  <NavItem>
                    <NavLink className="text-white pl-10px" tag={Link} to="consultar" onClick={this.fetchSetting} >
                      Trackings
                    </NavLink>
                  </NavItem>
:null}
 {role.rol_carga==="1"?
                  <NavItem>
                    <NavLink className="text-white pl-10px" tag={Link} to="carga" onClick={this.fetchSetting} >
                      Carga
                    </NavLink>
                  </NavItem>
                  :null}
                   {role.rol_lost==="1"?
                  <NavItem>
                    <NavLink className="text-white pl-10px" tag={Link} to="lost"onClick={this.fetchSetting}  >
                      Perdidos
                    </NavLink>
                  </NavItem>
                  :null}
                   {role.rol_orders==="1"?
                  <NavItem>
                    <NavLink className="text-white pl-10px" tag={Link} to="orders" onClick={this.fetchSetting} >
                      Pedidos
                    </NavLink>
                  </NavItem>
                   :null}
                   {role.rol_cot==="1"?
                  <NavItem>
                    <NavLink className="text-white pl-10px" tag={Link} to="cotizacion" onClick={this.fetchSetting} >
                      Cotizaciones
                    </NavLink>
                  </NavItem>
                    :null}
                     {role.rol_chat==="1"?
                  <NavItem>
                    <NavLink className="text-white pl-10px" tag={Link} to="chat" onClick={this.fetchSetting} >
                      Chat
                      {props.formSetting.count !== "0" ? (
                        <Label className="red-label ml-2"><Label className="label-count-msg">{props.formSetting.count} </Label></Label>
                      ) : null}
                    </NavLink>
                  </NavItem>
                  :null}
                   {role.rol_print==="1"?
                  <NavItem>
                    <NavLink className="text-white pl-10px" tag={Link} to="print" onClick={this.fetchSetting} >
                      Imprimir
                    </NavLink>
                  </NavItem>
                  :null}
                    {role.rol_setting==="1"?
                  <NavItem>
                    <NavLink className="text-white pl-10px" tag={Link} to="settings" onClick={this.fetchSetting} >
                      Ajustes
                    </NavLink>
                  </NavItem>
                   :null}
                  <NavItem>
                    <NavLink className="text-white pl-10px" tag={Link} to="settings"    onClick={() => {
                      this.cerrarSession();
                    }} >
                      Cerrar sesión
                    </NavLink>
                  </NavItem>
                </Nav>
                <NavbarText className="text-white d-flex">
                <img className="header-logo  visible-xs-logo " alt="" src={LOGO} onClick={() => {
                      this.handleModalPassword();
                    }} title="Desarrollado por www.mtechnologypanama.com Diego Martinez" />
                <Label className="title-header  visible-xs-logo">
                {this.context.usr_name === undefined ? null: this.context.usr_name.toUpperCase()}
                  </Label>
                </NavbarText>
              </Collapse>
            </Navbar>
            <Col sm={12} onClick={this.fetchSetting} >{this.state.children}</Col>

          </Col>
         
        </Row>

      </div>
      <footer className="footer-default text-center">
        <a href="https://mtechnologypanama.com/home/info" target="_blank" rel="noreferrer"> Desarrollado por wwww.mtechnologypanama.com</a>  
</footer>

      </React.Fragment>
    );
  }
}

export default LayoutAccount;


