import React, { Component } from "react";
import { Row, Col, Button, Label, Input, Container } from "reactstrap";
import {
  getTrackingAllLost,
  getTrackingByCodeUserLost,
  getListUser,
  postTrackingLost,
  putTrackingLost,
  putDeliveredLost
} from "./../Services";
import StoreContext from "./../../Components/Store/Context";
import moment from "moment";
import { OK ,PENCIL } from "../SrcImages";
import Datatable from "../Datatable/Index";
import { ModalTrackingLost } from "./../Util";
import { COLUMNS_TRACKING_SELECT_LOST } from "../Datatable/DatatableColumns";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  margin: 0 auto;
  position: relative;
  top: 35px;
  right: 10px;
`;

class Lost extends Component {
    static contextType = StoreContext
  constructor() {
    super();
    this.state = {
      DataClientes: [],
      dataTracking: [],
      formTracking: {
        los_id: "",
        los_track: "",
        los_desc: "",
        los_sts: "",
        usr_code:"",
        usr_id:""
      },
      loading: false,
      msg_error: "",
      isOpenModalTrackingLost: false,
      isOpenModalTrackingLostEdit: false,
    };

    this.fetchTracking = this.fetchTracking.bind(this);
    this.fetchTrackingByCode = this.fetchTrackingByCode.bind(this);
    this.rechargeData = this.rechargeData.bind(this);
    this.searchByCode = this.searchByCode.bind(this);
    this.onChangeData = this.onChangeData.bind(this);
    this.openModalAddTracking = this.openModalAddTracking.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.togleSelectCliente = this.togleSelectCliente.bind(this);
    this.handleModalAddTracking = this.handleModalAddTracking.bind(this);
    this.onchangeFormTracking = this.onchangeFormTracking.bind(this);
    this.toggleEditTracking = this.toggleEditTracking.bind(this);
    this.toggleSaveTracking = this.toggleSaveTracking.bind(this);
    this.toggleEditLos = this.toggleEditLos.bind(this);
    this.toggleDelivered = this.toggleDelivered.bind(this);
    this.handlerPutDelivered = this.handlerPutDelivered.bind(this);
  }

  handlerPutDelivered(row) {
    const body = {
      los_date_client: moment(new Date()).format("YYYY-MM-DD"),
      los_delivered:"1",
      los_id: row.los_id,
    };
    putDeliveredLost(body);
    setTimeout(() => {
      this.fetchTracking();
    }, 1000);
  }

  toggleDelivered(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            {row.los_date_client !== null ? moment(row.los_date_client).format("YYYY-MM-DD")  : (
              <Button
                color="primary"
                onClick={() => this.handlerPutDelivered(row)}
              >
                Entregar
              </Button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  openModalAddTracking() {
    this.setState((prevState) => ({
      isOpenModalTrackingLost: !prevState.isOpenModalTrackingLost,
      formTracking: {
        los_id: "",
        los_track: "",
        los_desc: "",
        los_sts: "",
        usr_code:"",
        usr_id:""
      },
    }));
  }

  async fetchTrackingByCode() {
    const props = this.state.formTracking;
    const response = await getTrackingByCodeUserLost(props.usr_code);
    this.setState({
      dataTracking: response,
      loading: false,
    });
  }

  searchByCode() {
    this.fetchTrackingByCode();
  }

  async fetchTracking() {
    const response = await getTrackingAllLost(this.state.formTracking.usr_code);
    this.setState({
      dataTracking: response,
      loading: false,
      isOpenModalTrackingLost:false
    });
  }

  async fetchUser() {
    const response = await getListUser(this.context.usr_id);
    this.setState({
      DataClientes: response,
      loading: false,
    });
  }
  rechargeData() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchLot();
    }, 1000);
  }

  componentDidMount() {
    this.fetchTracking();
    this.fetchUser();
  }

  toggleEditLos(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <img
              className="img-status img-search"
              onClick={() => this.handleModalEditTracking(row)}
              src={PENCIL}
              alt=""
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  onChangeData(e) {
    this.setState({
      formTracking: {
        ...this.state.formTracking,
        [e.target.name]: e.target.value,
      },
    });
  }

  handlerSetUser(row) {
    this.setState({
      formTracking: {
        ...this.state.formTracking,
        los_usr: row.usr_id,
        usr_code: row.usr_code,
      },
    });
    // this.textInput.focus();
  }

  togleSelectCliente(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <Label
              className="txt-underline"
              onClick={() => this.handlerSetUser(row)}
            >
              {row.usr_id}
            </Label>
            <img
              className="img-status img-search"
              onClick={() => this.handlerSetUser(row)}
              src={OK}
              alt=""
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  handleModalAddTracking = () => {
    this.setState((prevState) => ({
      isOpenModalTrackingLost: !prevState.isOpenModalTrackingLost,
    }));
  };

  handleModalEditTracking = (row) => {
    this.setState((prevState) => ({
      isOpenModalTrackingLostEdit: !prevState.isOpenModalTrackingLostEdit,
      formTracking:row
    }));
  };

  rechargeDataTracking() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchTracking();
    }, 1000);
  }

  onchangeFormTracking(e) {
    if (e.target.name === "tr_libra") {
      this.setState({
        formTracking: {
          ...this.state.formTracking,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      this.setState({
        formTracking: {
          ...this.state.formTracking,
          [e.target.name]: e.target.value,
        },
      });
    }
  }
  
  toggleEditTracking() {
    const props = this.state.formTracking;
    if (props.los_track.length > 0 && props.usr_id.length >0) {
      const body = {
        los_id :props.los_id,
        los_usr:props.usr_id,
        los_track: props.los_track,
        los_desc: props.los_desc
      };
      putTrackingLost(body);
      setTimeout(() => {
        this.fetchTracking();
      }, 1000);
    } else {
      this.setState({
        msg_error:"Campos vacios"
      });
    }
  }

  toggleSaveTracking() {
    const props = this.state.formTracking;
    if (props.los_track.length > 0 && props.los_usr.length >0) {
      const body = {
        los_usr:props.los_usr,
        los_track: props.los_track,
        los_desc: props.los_desc
      };
      postTrackingLost(body);
      setTimeout(() => {
        this.fetchTracking();
      }, 1000);
    } else {
      this.setState({
        msg_error:"Campos vacios"
      });
    }
  }

  render() {
    document.body.classList.add('body-class');
    const props = this.state;

    return (
      <Container  className="div-container">
<React.Fragment>
        {props.isOpenModalTrackingLost && (
          <ModalTrackingLost
            DataClientes={props.DataClientes}
            togleSelectCliente={this.togleSelectCliente}
            setTypeCasillero={this.setTypeCasillero}
            toggleSaveTracking={this.toggleSaveTracking}
            onchangeFormTracking={this.onchangeFormTracking}
            dataFormTracking={props.formTracking}
            toggleModal={this.handleModalAddTracking}
            title="Agregar"
            isOpenModal={props.isOpenModalTrackingLost}
            type_local={props.type_local}
            msg_error={props.msg_error}
          />
        )}

        {props.isOpenModalTrackingLostEdit && (
          <ModalTrackingLost
          DataClientes={props.DataClientes}
          togleSelectCliente={this.togleSelectCliente}
          setTypeCasillero={this.setTypeCasillero}
          toggleSaveTracking={this.toggleEditTracking}
          onchangeFormTracking={this.onchangeFormTracking}
          dataFormTracking={props.formTracking}
          toggleModal={this.handleModalEditTracking}
          title="Editar"
          isOpenModal={props.isOpenModalTrackingLostEdit}
          type_local={props.type_local}
          msg_error={props.msg_error}
          />
        )}
        <Row>
          <Col xs={12} className="text-center">
            <h4> Paquetes perdidos</h4>
          </Col>
          <Col xs={8}>
            <Label className="font-weight-bold">
              Codigo de cliente o tracking
            </Label>
            <Input
              type="text"
              name="usr_code"
              rows="5"
              onChange={this.onChangeData}
              defaultValue={props.formTracking.usr_code}
              id="usr_code"
              placeholder="Codigo de cliente o tracking"
              min={0}
              max={1000}
            />
          </Col>
          <Col xs={4}>
            <Button
              color="primary"
              className="mt-2 mb-2 w-100 box-h-90px info label-area-desc-bold btn-buscar"
              onClick={() => this.searchByCode()}
            >
              Buscar
            </Button>
          </Col>
          <Col xs={12} className="text-right mt-2">
            <ClipLoader
              css={override}
              size={23}
              color={"#36D7B7"}
              loading={this.state.loading}
            />
            <Button
              color="info"
              className="mr-2"
              onClick={() => this.rechargeDataTracking()}
            >
              Recargar
            </Button>
            <Button
              color="primary"
              className="mr-2"
              onClick={() => this.openModalAddTracking()}
            >
              Agregar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="scroll-ac">
            <Datatable
              data={props.dataTracking}
              columns={COLUMNS_TRACKING_SELECT_LOST(this.toggleEditLos,this.toggleDelivered)}
              keyProp="los_id"
              exportD={false}
              searchs={true}
              selectRows={false}
              RadioBtn={false}
              optionSelectRow={null}
            />
          </Col>
        </Row>
      </React.Fragment>
      </Container>
      
    );
  }
}

export default Lost;