import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import StoreContext from "./Context";

const RoutesPrivate = ({ component: Component, ...rest }) => {
const { token,rol_home,rol_user,rol_track,rol_carga,rol_lost,rol_orders,rol_cot,rol_chat,rol_print,rol_setting } = useContext(StoreContext);
var flag= false;
if (rest.path.includes("admin")) {
  if (token === undefined || token === null || !token) {
    return <Redirect to={"/login"} />;
  } else {
    if (!rest.path.includes("admin")) {
      return <Redirect to={"/login"} />;
    }
    else {
      if(rol_home ==="1" && rest.path.includes("admin/home")) {
      flag= true;
      }
      if(rol_user ==="1" && rest.path.includes("admin/crear")) {
        flag= true;
      }
      if(rol_track ==="1" && rest.path.includes("admin/consultar")) {
        flag= true;
      }
      if(rol_carga ==="1" && rest.path.includes("admin/carga")) {
        flag= true;
      }
      if(rol_lost ==="1" && rest.path.includes("admin/lost")) {
        flag= true;
      }
      if(rol_orders ==="1" && rest.path.includes("admin/orders")) {
        flag= true;
      }
      if(rol_cot ==="1" && rest.path.includes("admin/cotizacion")) {
        flag= true;
      }
      if(rol_chat ==="1" && rest.path.includes("admin/chat")) {
        flag= true;
      }
      if(rol_print ==="1" && rest.path.includes("admin/print")) {
        flag= true;
      }
      if(rol_setting ==="1" && rest.path.includes("admin/settings")) {
        flag= true;
      }
      if(flag){
        return <Route {...rest} render={() => token !== null && <Component {...rest} />} />;
      }
      else{
        return <Redirect to={"/admin/creartracking"} />;
      }
    }
  }
}
};

export { RoutesPrivate };
