import React, { Component } from "react";
import { Row, Col, Button, Label, FormGroup, Input, Container, Nav, NavItem, NavLink, TabPane, TabContent, ButtonGroup } from "reactstrap";
import { putSettings, getSetting, getListUserAdmin, putChangeStatusUser, putUsuarioAdmin, postUserAdmin, putCorreo,getAllUser } from "./../Services";
import ClipLoader from "react-spinners/ClipLoader";
import { CreateUserAdmin, FIND_DESC_SETTING } from "../Util";
import StoreContext from "./../../Components/Store/Context";
import { css } from "@emotion/core";
import NumberFormat from "react-number-format";
import { PENCIL, LOGO } from "./../SrcImages";
import moment from "moment";
import Datatable from "../Datatable/Index";
import { COLUMNS_USER_ADMIN_SETTING } from "../Datatable/DatatableColumns";
import REACT_PRINT_COMPONENT from "../Datatable/React_print";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
const override = css`
  margin: 0 auto;
  position: relative;
  top: 10px;
  right: 10px;
`;

class Settings extends Component {
  static contextType = StoreContext
  constructor(props) {
    super(props);
    this.state = {
      formSetting: {
        set_id: "",
        set_name: "",
        set_price_libra: "",
        set_price_manejo: "",
        set_price_tarjeta: "",
        set_siglas: "",
        set_address: "",
        set_correoheader:"",
        set_correobody:"",
        set_asunto:"",
        set_direccion:"",
        set_celphone:"",
        set_ruc:"", 
        set_email:""
      },
      dataUser: [],
      dataUserAll:[],
      formUser: {
        usr_id: "",
        usr_name: "",
        usr_user: "",
        usr_status: "",
        rol_id: "",
        rol_home: "",
        rol_user: "",
        rol_track: "",
        rol_carga: "",
        rol_lost: "",
        rol_orders: "",
        rol_cot: "",
        rol_chat: "",
        rol_print: "",
        rol_setting: ""
      },
      loading: false,
      msg_error: "",
      activeTab: "1",
      isPrinting: false,
      isOpenModalEditUser: false,
      isOpenModalAddUser: false,
      editorState: "",
      editorStateCuerpo:""
    };

    this.rechargeData = this.rechargeData.bind(this);
    this.rechargeDataUser = this.rechargeDataUser.bind(this);
    this.onchangeFormSettings = this.onchangeFormSettings.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.toggleEditUser = this.toggleEditUser.bind(this);
    this.toggleDeleteUser = this.toggleDeleteUser.bind(this);
    this.iPrintBody = this.iPrintBody.bind(this);
    this.print = this.print.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handlerChangeSts = this.handlerChangeSts.bind(this);
    this.handleModalAddUsuarios = this.handleModalAddUsuarios.bind(this);
    this.handleModalEditUsuarios = this.handleModalEditUsuarios.bind(this);
    this.toggleSaveUser = this.toggleSaveUser.bind(this);
    this.onchangeFormUser = this.onchangeFormUser.bind(this);
    this.setTypeRole = this.setTypeRole.bind(this);
    this.handlerSetUsuario = this.handlerSetUsuario.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.onEditorStateChangeCuerpo = this.onEditorStateChangeCuerpo.bind(this);
    this.toggleSaveCorreo = this.toggleSaveCorreo.bind(this);
  }

  handlerSetUsuario(row) {
    this.setState((prevState) => ({
      isOpenModalEditUser: !prevState.isOpenModalEditUser,
      isOpenModalAddUser: false,
      formUser: row,
    }));
  }

  onchangeFormUser(e) {
    this.setState({
      formUser: {
        ...this.state.formUser,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleModalEditUsuarios = () => {
    this.setState((prevState) => ({
      isOpenModalEditUser: !prevState.isOpenModalEditUser,
      msg_error: "",
    }));
  };

  handleModalAddUsuarios = () => {
    this.setState((prevState) => ({
      isOpenModalAddUser: !prevState.isOpenModalAddUser,
      formUser: {
        usr_id: "",
        usr_name: "",
        usr_user: "",
        usr_status: "",
        rol_id: "",
        rol_home: "",
        rol_user: "",
        rol_track: "",
        rol_carga: "",
        rol_lost: "",
        rol_orders: "",
        rol_cot: "",
        rol_chat: "",
        rol_print: "",
        rol_setting: ""
      },
      msg_error: "",
    }));
  };

  handlerChangeSts(row) {
    const body = {
      usr_id: row.usr_id,
      usr_status: row.usr_status,
    };

    this.setState({ loading: true });
    putChangeStatusUser(body);
    setTimeout(() => {
      this.fetchUser();
    }, 1000);
  }

  setTypeRole({ rol_home,
    rol_user,
    rol_track,
    rol_carga,
    rol_lost,
    rol_orders,
    rol_cot,
    rol_chat,
    rol_print,
    rol_setting }) {

    if (rol_home !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_home: rol_home
        },
      });
    }
    if (rol_user !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_user: rol_user
        },
      });
    }
    if (rol_track !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_track: rol_track
        },
      });
    }
    if (rol_carga !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_carga: rol_carga
        },
      });
    }
    if (rol_lost !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_lost: rol_lost
        },
      });
    }
    if (rol_orders !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_orders: rol_orders
        },
      });
    }
    if (rol_cot !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_cot: rol_cot
        },
      });
    }
    if (rol_chat !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_chat: rol_chat
        },
      });
    }
    if (rol_setting !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_setting: rol_setting
        },
      });
    }
    if (rol_print !== undefined) {
      this.setState({
        formUser: {
          ...this.state.formUser,
          rol_print: rol_print
        },
      });
    }
  }

  async fetchUser() {
    const response = await getAllUser(this.context.usr_id);
    this.setState({
      dataUser: response.usrAdmin,
      dataUserAll: response.allUser,
      loading: false,
      msg_error: "",
      isOpenModalAddUser: false,
      isOpenModalEditUser: false
    });
  }

  handleGoBack() {
    this.setState({ isPrinting: false });
  }

  toggleDeleteUser = (cell, row) => {
    return (
      <Row>
        <Col sm={12} className="text-center m-auto">
          <ButtonGroup>
            <Button outline onClick={() => this.handlerChangeSts(row)} active={row.usr_status === "1" ? true : false}>
              SI
            </Button>
            <Button outline onClick={() => this.handlerChangeSts(row)} active={row.usr_status === "0" ? true : false}>
              NO
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    );
  };

  saveSettings() {
    const props = this.state.formSetting;
    if (props.set_name.length > 0 && props.set_price_libra.length > 0 && props.set_price_manejo.length > 0 && props.set_price_tarjeta > 0 && props.set_siglas.length > 0 && props.set_address.length > 0) {
      const body = {
        set_name: props.set_name,
        set_price_libra: props.set_price_libra,
        set_price_manejo: props.set_price_manejo,
        set_price_tarjeta: props.set_price_tarjeta,
        set_siglas: props.set_siglas,
        set_address: props.set_address,
        set_usr: this.context.usr_id,
        set_direccion:props.set_direccion,
        set_celphone:props.set_celphone,
        set_ruc:props.set_ruc,
        set_email: props.set_email
      };
      putSettings(body);
      this.setState({
        loading: true
      });
      setTimeout(() => {
        this.fetchSetting();
      }, 1000);
    } else {
      this.setState({
        msg_error: "Campos vacios"
      });
    }
  }

  print() {
    this.setState({ isPrinting: true });
  }

  iPrintBody = () => {
    const props = this.state;
    return (
      <React.Fragment>
        <Row className=" mb-5" key={new Date().toString()}>
          <Col xs={2}>
          </Col>
          <Col xs={8} className="text-center mb-4">
            <img alt="" className="logo-school-informe" src={LOGO}></img>
          </Col>
          <Col xs={2}>
          </Col>
          <Col xs={1}>
          </Col>
          <Col xs={10} className="text-left mb-3">
            {/* <Label className="font-weight-bold">Fecha : {moment(new Date(props.dateIDairy)).format("YYYY-MM-DD")}</Label> <br></br> */}
            <Label className="font-weight-bold">
              Fecha de impresion: {moment(new Date()).format("YYYY-MM-DD")}
            </Label>{" "}
          </Col>
          <Col xs={1}>
          </Col>
          <Col xs={1}>
          </Col>
          <Col xs={10} className="text-center border-print">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center w-300px">ID</th>
                  <th className="text-center w-250px">NOMBRE</th>
                  <th className="text-center">CODIGO</th>
                  <th className="text-center">PRECIO</th>
                  <th className="text-center w-300px">TELEFONO</th>
                  <th className="text-center w-250px">EMAIL</th>
                </tr>
              </thead>
              <tbody>
                {props.dataUser.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-left font-weight-bold">{data.usr_id}</td>
                      <td className="text-left">
                        {data.usr_user}
                      </td>
                      <td className="text-left">{data.usr_code}</td>
                      <td className="text-left">{data.usr_price}</td>
                      <td className="text-left">{data.usr_celphone}</td>
                      <td className="text-left">{data.usr_email}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
          <Col xs={1}>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  changeTab(num) {
    this.setState({
      activeTab: num
    })
  }

  rechargeData() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchSetting();
    }, 1000);
  }

  rechargeDataUser() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchUser();
    }, 1000);
  }

  componentDidMount() {
    this.fetchSetting();
    this.fetchUser()
  }


  async fetchSetting() {
    const response = await getSetting(this.context.usr_id);
    var contentBlockHeader = htmlToDraft(response[0].set_correoheader);
    var contentBlockBody = htmlToDraft(response[0].set_correobody);
    var editorStateBody = "";
    if (contentBlockBody) {
      var contentStateBody = ContentState.createFromBlockArray(contentBlockBody.contentBlocks);
      editorStateBody = EditorState.createWithContent(contentStateBody);
    }

    var editorStateHeader = "";
    if (contentBlockHeader) {
      var contentStateHeader = ContentState.createFromBlockArray(contentBlockHeader.contentBlocks);
       editorStateHeader = EditorState.createWithContent(contentStateHeader);
    }
    if (response[0] !== undefined) {
      this.setState({
        formSetting: {
          set_id: response[0].set_id,
          set_name: response[0].set_name,
          set_price_libra: response[0].set_price_libra,
          set_price_manejo: response[0].set_price_manejo,
          set_price_tarjeta: response[0].set_price_tarjeta,
          set_siglas: response[0].set_siglas,
          set_address: response[0].set_address,
          set_correoheader: editorStateHeader,
          set_correobody: editorStateBody,
          set_direccion:response[0].set_direccion,
          set_celphone:response[0].set_celphone,
          set_ruc:response[0].set_ruc,
          set_email:response[0].set_email
        },
        loading: false,
      });
    }
    else {
      this.setState({
        formSetting: {
          set_id: "",
          set_name: "",
          set_price_libra: "",
          set_price_manejo: "",
          set_price_tarjeta: "",
          set_siglas: "",
          set_address: "",
        },
        loading: false,
      });
    }
  }

  onEditorStateChange(editorState) {
    this.setState({
      formSetting: {
        ...this.state.formSetting,
        set_correoheader: editorState
      },
    });
  };

  onEditorStateChangeCuerpo(editorStateCuerpo) {
    this.setState({
      formSetting: {
        ...this.state.formSetting,
        set_correobody: editorStateCuerpo
      },
    });
  };
 
  onchangeFormSettings(e) {
    this.setState({
      formSetting: {
        ...this.state.formSetting,
        [e.target.name]: e.target.value
      },
      msg_error: ""
    });
  }

  toggleEditUser(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <img
              className="img-status img-search"
              onClick={() => this.handlerSetUsuario(row)}
              src={PENCIL}
              alt=""
            />

          </Col>
        </Row>
      </React.Fragment>
    );
  }
  

  toggleSaveCorreo() {
    const props = this.state.formSetting;
      const body = {
        set_id: props.set_id,
        set_correoheader: draftToHtml(convertToRaw(props.set_correoheader.getCurrentContent())) ,
        set_correobody: draftToHtml(convertToRaw(props.set_correobody.getCurrentContent())),
        set_asunto: props.set_asunto
      };

      putCorreo(body);
      setTimeout(() => {
        this.fetchSetting();
      }, 1000);
  }
  

  toggleSaveUser() {
    const props = this.state.formUser;
    if (props.usr_id.length === 0) {
      if (FIND_DESC_SETTING(this.state.dataUserAll, props.usr_user)) {
        this.setState({
          msg_error: "El numero de telefono ya se encuentra registrado",
        });
      } else {
        if (
          props.usr_name.length > 0 &&
          props.usr_user.length > 0
        ) {
          const body = {
            usr_name: props.usr_name,
            usr_user: props.usr_user,
            usr_id: props.usr_id,
            rol_home: props.rol_home,
            rol_user: props.rol_user,
            rol_track: props.rol_track,
            rol_carga: props.rol_carga,
            rol_lost: props.rol_lost,
            rol_orders: props.rol_orders,
            rol_cot: props.rol_cot,
            rol_chat: props.rol_chat,
            rol_print: props.rol_print,
            rol_setting: props.rol_setting,
            usr_suc: this.context.usr_id
          };
          postUserAdmin(body);
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        } else {
          this.setState({ msg_error: "Algunos campos estan en blanco" });
        }
      }
    } else {
      const body = {
        usr_id: props.usr_id,
        usr_name: props.usr_name,
        usr_user: props.usr_user,
        rol_userid: props.usr_id,
        rol_home: props.rol_home,
        rol_user: props.rol_user,
        rol_track: props.rol_track,
        rol_carga: props.rol_carga,
        rol_lost: props.rol_lost,
        rol_orders: props.rol_orders,
        rol_cot: props.rol_cot,
        rol_chat: props.rol_chat,
        rol_print: props.rol_print,
        rol_setting: props.rol_setting
      };

      putUsuarioAdmin(body);
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
  }



  render() {
    document.body.classList.add('body-class');
    const props = this.state.formSetting;
    const MAX_VAL = 500;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL) return inputObj;
    };
    return (
      <Container className="div-container">
        <React.Fragment>

          {this.state.isOpenModalEditUser && (
            <CreateUserAdmin
              toggleSaveUser={this.toggleSaveUser}
              onchangeFormUser={this.onchangeFormUser}
              dataFormUser={this.state.formUser}
              setTypeRole={this.setTypeRole}
              toggleModal={this.handleModalEditUsuarios}
              title="Editar Usuario"
              isOpenModal={this.state.isOpenModalEditUser}
              msg_error={this.state.msg_error}
            />
          )}

          {this.state.isOpenModalAddUser && (
            <CreateUserAdmin
              toggleSaveUser={this.toggleSaveUser}
              onchangeFormUser={this.onchangeFormUser}
              dataFormUser={this.state.formUser}
              setTypeRole={this.setTypeRole}
              toggleModal={this.handleModalAddUsuarios}
              title="Agregar Usuario"
              isOpenModal={this.state.isOpenModalAddUser}
              msg_error={this.state.msg_error}
            />
          )}
          <Row>
            <Col xs={12} className="text-center">
              <h4>Ajustes</h4>
            </Col>
            <Col xs={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      this.changeTab("1");
                    }}
                  >
                    Tienda
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      this.changeTab("2");
                    }}

                  >
                    Administradores
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "3" ? "active" : ""}
                    onClick={() => {
                      this.changeTab("3");
                    }}

                  >
                    Correo
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col xs={12} className="text-right mt-1 mb-2">
                      <ClipLoader
                        css={override}
                        size={23}
                        color={"#36D7B7"}
                        loading={this.state.loading}
                      />
                      {this.state.loading ? <Button
                        color="info"
                        className="mr-2"
                        disabled
                      >
                        Recargar
                      </Button> :
                        <Button
                          color="info"
                          className="mr-2"
                          onClick={() => this.rechargeData()}
                        >
                          Recargar
                        </Button>
                      }
                    </Col>
                    <Col xs={4}>
                    <FormGroup>
                        <Label for="Nombre">Dirección</Label>
                        <Input
                          type="text"
                          name="set_direccion"
                          id="set_direccion"
                          placeholder="Dirección"
                          onChange={this.onchangeFormSettings}
                          defaultValue={props.set_direccion}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                    <FormGroup>
                        <Label for="Nombre">Telefono</Label>
                        <Input
                          type="text"
                          name="set_celphone"
                          id="set_celphone"
                          placeholder="Telefono"
                          onChange={this.onchangeFormSettings}
                          defaultValue={props.set_celphone}
                        />
                      </FormGroup>
                      </Col>
                      <Col xs={4}>
                      <FormGroup>
                        <Label for="Nombre">RUC</Label>
                        <Input
                          type="text"
                          name="set_ruc"
                          id="set_ruc"
                          placeholder="RUC"
                          onChange={this.onchangeFormSettings}
                          defaultValue={props.set_ruc}
                        />
                      </FormGroup>
                      </Col>
                    <Col xs={4}>
                      <FormGroup>
                        <Label for="Nombre">Nombre de la tienda</Label>
                        <Input
                          type="text"
                          name="set_name"
                          id="set_name"
                          placeholder="Nombre de la tienda"
                          onChange={this.onchangeFormSettings}
                          defaultValue={props.set_name}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                    <FormGroup>
                        <Label for="Nombre">Correo</Label>
                        <Input
                          type="text"
                          name="set_email"
                          id="set_email"
                          placeholder="Correo"
                          onChange={this.onchangeFormSettings}
                          defaultValue={props.set_email}
                        />
                      </FormGroup>
                      </Col>
                    <Col xs={2}>
                      <FormGroup>
                        <Label for="set_price_libra">Precio x libra</Label>
                        <NumberFormat
                          format="#.##"
                          name="set_price_libra"
                          onChange={this.onchangeFormSettings}
                          allowEmptyFormatting={true}
                          className="form-control"
                          value={Number(props.set_price_libra).toFixed(
                            2
                          )}
                          placeholder="Precio libra"
                          min="0"
                          max="10"
                          step="0.25"
                          isAllowed={withValueCap}
                        />

                      </FormGroup>
                    </Col>
                    <Col xs={2}>
                      <FormGroup>
                        <Label for="set_price_manejo">Manejo</Label>
                        <NumberFormat
                          format="#.##"
                          name="set_price_manejo"
                          onChange={this.onchangeFormSettings}
                          allowEmptyFormatting={true}
                          className="form-control"
                          value={Number(props.set_price_manejo).toFixed(
                            2
                          )}
                          placeholder="Precio manejo"
                          min="0"
                          max="10"
                          step="0.25"
                          isAllowed={withValueCap}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={2}>
                      <FormGroup>
                        <Label for="set_price_tarjeta">Uso de tarjeta</Label>
                        <NumberFormat
                          format="#.##"
                          name="set_price_tarjeta"
                          onChange={this.onchangeFormSettings}
                          allowEmptyFormatting={true}
                          className="form-control"
                          value={Number(props.set_price_tarjeta).toFixed(
                            2
                          )}
                          placeholder="Precio tarjeta"
                          min="0"
                          max="10"
                          step="0.25"
                          isAllowed={withValueCap}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={2}>
                      <FormGroup>
                        <Label for="set_siglas">Siglas</Label>
                        <Input
                          type="text"
                          name="set_siglas"
                          id="set_siglas"
                          placeholder="Siglas"
                          onChange={this.onchangeFormSettings}
                          value={props.set_siglas}
                        />
                      </FormGroup>

                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for="set_address">Casillero</Label>
                        <Input
                          type="textarea"
                          rows={7}
                          name="set_address"
                          id="set_address"
                          placeholder="Direccion"
                          onChange={this.onchangeFormSettings}
                          defaultValue={props.set_address}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} className="text-right">
                      {this.state.loading ? <Button color="success" disabled className="ml-2" >
                        Guardar
                      </Button> :
                        <Button color="success" className="ml-2" onClick={this.saveSettings}>
                          Guardar
                        </Button>
                      }

                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  {this.state.isPrinting ? (
                    <Row className="mt-3">
                      <Col xs={1}></Col>
                      <Col xs={10} className="text-right">
                        <Button
                          color="primary"
                          onClick={() => this.handleGoBack()}
                        >
                          Cerrar
                        </Button>
                      </Col>
                      <Col xs={1}></Col>
                      <Col xs={12}>
                        <REACT_PRINT_COMPONENT body={this.iPrintBody()} />
                      </Col>
                    </Row>
                  ) :
                    <Row>
                      <Col xs={12} className="text-right mt-2">
                        <ClipLoader
                          css={override}
                          size={23}
                          color={"#36D7B7"}
                          loading={this.state.loading}
                        />
                        <Button
                          color="info"
                          className="mr-2"
                          onClick={() => this.rechargeDataUser()}
                        >
                          Recargar
                        </Button>
                        <Button color="danger" className="mr-2" onClick={() => this.print()}>
                          PDF
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => this.handleModalAddUsuarios()}
                        >
                          Agregar
                        </Button>
                      </Col>
                      <Col xs={12} className="scroll-ac">
                        <Datatable
                          data={this.state.dataUser}
                          columns={COLUMNS_USER_ADMIN_SETTING(this.toggleEditUser, this.toggleDeleteUser)}
                          keyProp="usr_id"
                          exportD={false}
                          searchs={true}
                          selectRows={false}
                          RadioBtn={false}
                          optionSelectRow={null}
                        />
                      </Col>
                    </Row>}
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col xs={12} className="text-right mt-2">
                      <ClipLoader
                        css={override}
                        size={23}
                        color={"#36D7B7"}
                        loading={this.state.loading}
                      />
                      <Button
                        color="info"
                        className="mr-2"
                        onClick={() => this.rechargeDataUser()}
                      >
                        Recargar
                      </Button>
                      <Button
                        color="success"
                        onClick={() => this.toggleSaveCorreo()}
                      >
                        Guardar
                      </Button>
                    </Col>
                    <Col xs={12} className="mt-2">
                      <Label>
                       <b>
                       ASUNTO
                         </b> 
                      </Label>
                    </Col>
                    <Col xs={12}>
                    <FormGroup>
                        <Input
                          type="text"
                          name="set_asunto"
                          id="set_asunto"
                          placeholder="Asunto"
                          onChange={this.onchangeFormSettings}
                          value={props.set_asunto}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} className="mt-2">
                      <Label>
                       <b>
                       ENCABEZADO DEL CORREO
                         </b> 
                      </Label>
                    </Col>
                    <Col xs={12} className="h-20vh">
                      <Editor
                        editorState={this.state.formSetting.set_correoheader}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    </Col>
                    <Col xs={12}>
                      <Label>
                        <b>
                        CUERPO DEL CORREO
                        </b>
                        
                      </Label>
                    </Col>
                    <Col xs={12} className="h-20vh" >
                      <Editor
                        editorState={this.state.formSetting.set_correobody}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChangeCuerpo}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </React.Fragment>
      </Container>
    );
  }
}

export default Settings;