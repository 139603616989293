//settings, notificacion de guardado, mensaje de campos vacios
import moment from "moment";
import $ from 'jquery';
//DEV
// export const url = "http://localhost/trackingApi/";
//PRODUCCION
  //  export const url = "https://www.mtechnologypanama.com/db/index.php/";
  export const url = "https://apservices507.website/db/index.php/";
 //  export const url = "https://www.apservices507.com/db/index.php/";
  // export const url = "https://www.internetpayrineshelle.com/db/index.php/";
// export const url = "https://admventascouriermg.com/db/index.php/";
export const nonce="enc-base64";
export const path="login";
export const privateKey="MIICXAIBAAKBgQCqGKukO1De7zhZj6+H0qtjTkVxwTCpvKe4eCZ0FPqri0cb2JZfXJ";
export const precio_libra_pr = 3;
export const precio_uso_tarjeta = 6.50;
export const logo_url="couriermg/logo.jpeg";
export const  NOMBRE_EMPRESA ="@APSERVICES507";
export const  RUC ="4-809-459 DV 65";
export const  UBICACION="La Concepción, Chiriqui, Panama, avenida central, diagonal al rest. Gloton's";
export const  TELEFONO ="TELEFONO 62326607";
// EMAIL SETTING MTECHNOLOGY
// export const service_id = "service_7jdx559";
// export const template_id =  "template_kct3jbt";
// export const user_id =  "sTdI4ajqCX3oFhmHc";
// export const usr_name =  "MTECHNOLOGY";
// EMAIL SETTING APBOX
// export const service_id = "service_7jdx559";
// export const template_id =  "template_kct3jbt";
// export const user_id =  "sTdI4ajqCX3oFhmHc";
// export const usr_name =  "APBOX";
// EMAIL SETTING COURIERMG
export const service_id = "service_o6m3sds";
export const template_id =  "template_kct3jbt";
export const user_id =  "Raf_lWCEDRWtM0417";//PUBLIC_KEY
export const usr_name =  "APBOX";

export  async function print_receipt(datas) {
  $.ajax({
    // url: 'http://localhost/printer_tracking/ticket_guia.php',
    url: 'http://localhost:8080/printer_tracking/ticket_track.php',
    data:JSON.stringify(datas),
    type: 'POST',
    success: function(response){
        if(response===1){
        }else{
          console.log(response)
        }
    }
}); 
  return true;
}

export async function postUserAdmin(data) {
  const response = await fetch(url + "UserController/postUserAdmin", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function postUser(data) {
  const response = await fetch(url + "UserController/postUsuario", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function postCotizacionDetail(data) {
  const response = await fetch(url + "CotizacionController/postCotizacionDetail", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}


export async function postCotizacion(data) {
  const response = await fetch(url + "CotizacionController/postCotizacion", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putCorreo(data) {
  const response = await fetch(url + "UserController/putCorreo", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putCotizacionDetailDelete(data) {
  const response = await fetch(url + "CotizacionController/putCotizacionDetailDelete", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putCotizacionDelete(data) {
  const response = await fetch(url + "CotizacionController/putCotizacionDelete", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putCotizacionDetail(data) {
  const response = await fetch(url + "CotizacionController/putCotizacionDetail", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putCotizacion(data) {
  const response = await fetch(url + "CotizacionController/putCotizacion", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function postMessages(data) {
  const response = await fetch(url + "MessagesController/postMessages", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function getReportMensual(date) {
  const response = fetch(url + "UserController/getReportMensual/" + date+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss"))
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getCotizacionDetail(cot_id) {
  const response = fetch(url + "CotizacionController/getCotizacionDetail/" + cot_id+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss"))
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getCotizacion(cot_suc) {
  const response = fetch(url + "CotizacionController/getCotizacion/" + cot_suc+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss"))
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getMessages(msg_id) {
  const response = fetch(url + "MessagesController/show/" + msg_id+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss"))
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getPersonList(usr_id) {
  const response = fetch(url + "MessagesController/getPersonList/" + usr_id+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss"))
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function processLotAll(data) {
  const response = await fetch(url + "LotController/processLotAll", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function processLot(data) {
  const response = await fetch(url + "LotController/processLot", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putStatusMsg(data) {
  const response = await fetch(url + "MessagesController/putStatusMsg", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function deleteLot(data) {
  const response = await fetch(url + "LotController/deleteLot", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putStatusTracking(data) {
  const response = await fetch(url + "LotController/putStatusTracking", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function postTrackingLost(data) {
  const response = await fetch(url + "TrackingController/postTrackingLost", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putDeliveredLost(data) {
  const response = await fetch(url + "TrackingController/putDeliveredLost", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putTrackingLost(data) {
  const response = await fetch(url + "TrackingController/putTrackingLost", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putDelivered(data) {
  const response = await fetch(url + "TrackingController/putDelivered", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putChangeStatusUser(data) {
  const response = await fetch(url + "UserController/putChangeStatusUser", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putUsuarioAdmin(data) {
  const response = await fetch(url + "UserController/putUsuarioAdmin", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putUser(data) {
  const response = await fetch(url + "UserController/putUsuario", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function getDataToPrint(usr_id,usr_suc) {
  var urlFetch = url + "TrackingController/getDataToPrint/"+usr_id+"/"+usr_suc+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getTrackingByCodeUser(usr_code) {
  var urlFetch = url + "TrackingController/getTrackingByCodeUser/"+usr_code+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getOrdersSinRecibir() {
  var urlFetch = url + "OrderController/getOrdersSinRecibir"+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getOrders() {
  var urlFetch = url + "OrderController/getOrder"+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function putStatusOrder(data) {
  const response = await fetch(url + "OrderController/putStatusOrder", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putDeliveredOrder(data) {
  const response = await fetch(url + "OrderController/putDeliveredOrder", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putOrder(data) {
  const response = await fetch(url + "OrderController/putOrder", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function getTrackingByCodeUserLost(usr_code) {
  var urlFetch = url + "TrackingController/getTrackingByCodeUserLost/"+usr_code+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getReport(year,month) {
  var urlFetch = url + "TrackingController/getReport/"+year+"/"+month+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getUsers(suc_id) {
  var urlFetch = url + "UserController/getUsers/"+suc_id+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function postRegisterUser(data) {
  const response = await fetch(url + "UserController/postRegisterUser", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function validateUser(usr,pass) {
  const response = fetch(url + "LoginController/validateUser/" + usr+"/"+pass+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss"))
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getCountMsg(usr_id) {
  var urlFetch = url + "UserController/getCountMsg/" + usr_id+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getSetting(usr_id) {
  var urlFetch = url + "UserController/getSetting/" + usr_id+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getListUser(usr_suc) {
  var urlFetch = url + "UserController/getListUser/" + usr_suc+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getAllUser(usr_suc) {
  var urlFetch = url + "UserController/getAllUser/" + usr_suc+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getListUserAdmin(usr_suc) {
  var urlFetch = url + "UserController/getListUserAdmin/" + usr_suc+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function postOrder(data) {
  const response = await fetch(url + "OrderController/postOrder", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function postLot(data) {
  const response = await fetch(url + "LotController/postLot", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putPassword(data) {
  const response = await fetch(url + "UserController/putPassword", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putSettings(data) {
  const response = await fetch(url + "TrackingController/putSettings", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putPrint(data) {
  const response = await fetch(url + "TrackingController/putPrint", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putLot(data) {
  const response = await fetch(url + "LotController/putLot", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function getLot() {
  var urlFetch = url + "LotController/getLot"+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function sendEmail(data) {
  const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    // mode: 'no-cors'
  }).then(function(response) {
    console.log('SUCCESS!',data.template_params.to_name);
}).then(function(error) {
  // console.log('Oops... ' + JSON.stringify(error));
});
  return response;
}

export async function puttrackingEmail(data) {
  const response = await fetch(url + "TrackingController/puttrackingEmail", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function postTracking(data) {
  const response = await fetch(url + "TrackingController/postTracking", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function putTracking(data) {
  const response = await fetch(url + "TrackingController/putTracking", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function getTracking(lot_id) {
  var urlFetch = url + "TrackingController/getTracking/"+lot_id+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getGroupToPrint(usr_suc) {
  var urlFetch = url + "TrackingController/getGroupToPrint/"+usr_suc+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getTrackingAll() {
  var urlFetch = url + "TrackingController/getTrackingAll"+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}

export async function getTrackingAllLost() {
  var urlFetch = url + "TrackingController/getTrackingAllLost"+"/"+moment(new Date().toString()).format("YYYYMMDDHHmmss");
  const response = fetch(urlFetch)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return response;
}