import React from "react";
import { Button, Col, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../assets/css/style.css";

export default function Datatable({ data, columns, keyProp,exportD,searchs, selectRows,optionSelectRow,RadioBtn }) {
  const { SearchBar } = Search;
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div className="text-right">
        <Button color="info" onClick={ handleClick }>Exportar!</Button>
      </div>
    );
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando { from } a { to } de { size } Resultados
    </span>
  );

  const options = {
    paginationSize: 7,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
    firstPageText: "Primera",
    prePageText: "Atrás",
    nextPageText: "Siguiente",
    lastPageText: "Anterior",
    nextPageTitle: "Primera página",
    prePageTitle: "Página anterior",
    firstPageTitle: "Siguiente página",
    lastPageTitle: "Página anterior",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
    sizePerPageList: [
      {
        text: "3",
        value: 20,
      }
    ],
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: optionSelectRow,
    bgColor: '#cbd5d9'
  };
  
  const selectRow2 = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: optionSelectRow,
    bgColor: '#cbd5d9'
  };
  return (
      <ToolkitProvider keyField={keyProp} data={data} columns={columns}  search={ { searchFormatted: true } } exportCSV>
      {(props) => (
        <React.Fragment>
          <Row className="mt-2">
            <Col sm={6} className="text-left">
            {searchs && (
              <SearchBar {...props.searchProps}   placeholder="Buscar..." />
            )}
            </Col>
            <Col sm={6} className="text-right">
            {exportD && (
            <MyExportCSV { ...props.csvProps } />
            )}
            </Col>
            <Col sm={12} className="mt-2">
              {
                selectRows ? <BootstrapTable selectRow={ RadioBtn?selectRow2:selectRow}  {...props.baseProps} pagination={paginationFactory(options)} />
                :
                (<BootstrapTable  {...props.baseProps} pagination={paginationFactory(options)}   striped  footerClasses="text-right" keyField="tr_id"
                hover
                condensed/>)
              }
              
            </Col>
          </Row>
        </React.Fragment>
      )}
    </ToolkitProvider>
  );
}
