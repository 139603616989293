import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import StoreContext from "./../Store/Context";
import { Row, Col, Button, Label, Container, FormGroup, Form, Input } from "reactstrap";
import { LOGO } from "./../SrcImages";
import { url } from "./../Services";
import "./../../assets/css/Login.css";

function initialState() {
  return { user: "", password: "" };
}

async function login({ user, password }) {
  if (user.length > 0 && password.length > 0) {
    const dataBody = {
      user,
      password,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(dataBody),
    };
    const response = await fetch(url + "LoginController/login", requestOptions);
    const result = await response.json();
    if (result[0].count !== "0" && result[0].usr_type === "2") {
      return { token: true, usr_id:result[0].usr_id,usr_name: result[0].usr_name};
    } else {
      return { error: "Usuario o contraseña incorrectos" };
    }
  } else {
    return { error: "Campos estan vacios" };
  }
}

const UserLogin = () => {
  const [values, setValues] = useState(initialState);
  const [error, setError] = useState(null);
  const { setToken, quitToken } = useContext(StoreContext);
  const History = useHistory();


  function onChange(event) {
    const { value, name } = event.target;

    setValues({
      ...values,
      [name]: value
    });
    setError("");
  }

  async function onSubmit(event) {
    event.preventDefault();
    const { token, usr_type, usr_id, usr_name, error } = await login(values);
    if (token) {
      quitToken();
      setToken({ token, usr_id: usr_id, usr_name: usr_name, allow: null, per_admin: null, usr_type:"2" });
      return History.push("/admin/creartracking");
    }
    setError(error);
    setValues(initialState);
  }

  return (
    <React.Fragment>
      <Container>
        <Row className="mt-5">
          <Col sm={12} className="text-center mt-5">
            <h1 className="user-login__title">Iniciar sesión</h1>
          </Col>
          <Col sm={12} className="text-center mt-0">
            <img className="login-logo" alt="" src={LOGO} title="Desarrollado por www.mtechnologypanama.com Diego Martinez" />
          </Col>
          <Col sm={4}></Col>
          <Col sm={4}>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Label for="name">Usuario </Label>
                <Input id="user" type="text" name="user" onChange={onChange} value={values.user} placeholder="Usuario" />
              </FormGroup>
              <FormGroup>
                <Label for="name">Contraseña </Label>
                <Input id="password" type="password" name="password" onChange={onChange} value={values.password} placeholder="Contraseña" />
              </FormGroup>
              {error && <div className="user-login__error font-weight-bold">{error}</div>}
              <Button type="submit" color="primary" className="user-login__submit-button">
                Entrar
              </Button>
            </Form>
          </Col>
          <Col sm={4}></Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default UserLogin;
