import React from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
// import ButtonGroup from "@material-ui/core/ButtonGroup";
import Box from "@material-ui/core/Box";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import { reactLocalStorage } from "reactjs-localstorage";
import { nonce, path, privateKey } from "./Services"
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Row,
  Col,
  FormGroup,
  ButtonGroup
} from "reactstrap";
import NumberFormat from "react-number-format";
import { MESSAGE_ICON,SEND_EMAIL} from "../Components/SrcImages";
import Datatable from "../Components/Datatable/Index";
import { COPY } from "./SrcImages";
import moment from "moment";
import { COLUMNS_CLIENTES, COLUMNS_CHAT } from "../Components/Datatable/DatatableColumns";
const override = css`
  margin: 0 auto;
  position: relative;
  top: 10px;
  right: 10px;
`;
const validateTokenStorage = () => {
  var x = reactLocalStorage.getObject("tokenP");
  const hashDigest = sha256(nonce + x.usr_type + x.usr_id);
  const hmacDigest = Base64.stringify(
    hmacSHA512(path + hashDigest, privateKey)
  );
  if (x.key !== undefined) {
    if (x.key !== hmacDigest) {
      return true;
    }
  }
  return false;
};
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.mtechnologypanama.com/">
        M-TECHNOLOGY
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const CreateUserAdmin = ({
  toggleSaveUser,
  onchangeFormUser,
  dataFormUser,
  setTypeRole,
  toggleModal,
  title,
  isOpenModal,
  msg_error
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} sm ={4}>
              <FormGroup>
                <Label for="Nombre">Nombre Completo</Label>
                <Input
                  type="text"
                  name="usr_name"
                  id="usr_name"
                  placeholder="Nombre Completo"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_name}
                />
              </FormGroup>
              </Col>

            <Col xs={6} sm ={2}>
              <FormGroup>
                <Label for="usr_user">Celular</Label>
                <Input
                  type="text"
                  name="usr_user"
                  id="usr_user"
                  placeholder="Celular"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_user}
                />
              </FormGroup>
              </Col>
            <Col  xs={6} sm ={2}>
              <FormGroup>
                <Label for="usr_user">Usuario</Label>
                <Input
                  type="text"
                  name="usr_user"
                  id="usr_user"
                  placeholder="Usuario"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_user}
                  disabled
                />
              </FormGroup>
              </Col>

            <Col  xs={12} sm ={12}>
            <Label for="rol" className="mr-3">Permisos</Label>
            <FormGroup>
              <ButtonGroup>
                <Button
                 outline
                className={dataFormUser.rol_home ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_home: dataFormUser.rol_home ==="1"?"0":"1" })}
                >
                  Inicio
                </Button>
                <Button
                outline
                className={dataFormUser.rol_user ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_user: dataFormUser.rol_user ==="1"?"0":"1" })}              
                >
                  Usuarios
                </Button>
                <Button
             outline
             className={dataFormUser.rol_track ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_track:dataFormUser.rol_track ==="1"?"0":"1" })}
                >
                  Trackings
                </Button>
                <Button
                 outline
                 className={dataFormUser.rol_carga ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_carga: dataFormUser.rol_carga ==="1"?"0":"1"  })}
                >
                  Carga
                </Button>
                <Button
                outline
                className={dataFormUser.rol_lost ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_lost:dataFormUser.rol_lost ==="1"?"0":"1"})}
                >
                  Perdidos
                </Button>
                <Button
                outline
                className={dataFormUser.rol_orders ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_orders: dataFormUser.rol_orders ==="1"?"0":"1" })}
                >
                  Pedidos
                </Button>
                <Button
                outline
                className={dataFormUser.rol_cot ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_cot: dataFormUser.rol_cot ==="1"?"0":"1"  })}
                >
                  Cotizaciones
                </Button>
                <Button
                 outline
                 className={dataFormUser.rol_chat ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_chat: dataFormUser.rol_chat ==="1"?"0":"1"  })}
                >
                  Chat
                </Button>
                <Button
                outline
                className={dataFormUser.rol_print ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_print:dataFormUser.rol_print ==="1"?"0":"1" })}
                >
                  Imprimir
                </Button>
                <Button
                outline
                className={dataFormUser.rol_setting ==="1"?"active":""}
                  onClick={() => setTypeRole({ rol_setting: dataFormUser.rol_setting ==="1"?"0":"1" })}
                >
                  Ajustes
                </Button>
              </ButtonGroup>
              </FormGroup>
              </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={12} className="text-right">
              {msg_error.length > 0 ? (
                <Label className="l-color-danger">{msg_error}</Label>
              ) : null}
            </Col>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              <Button color="success" className="ml-2" onClick={toggleSaveUser}>
                Guardar
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const CreateUser = ({
  toggleSaveUser,
  onchangeFormUser,
  dataFormUser,
  setTypeLanguage,
  toggleModal,
  title,
  isOpenModal,
  n_box,
  set_address,
  msg_error,
  seTypeHandlerPayment,
  seTypeSex,
  seTypeHandlerNotification,
  loading
}) => {
  const MAX_VAL = 90000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} sm ={4}>
              <FormGroup>
                <Label for="Nombre">Nombre Completo</Label>
                <Input
                  type="text"
                  name="usr_name"
                  id="usr_name"
                  placeholder="Nombre Completo"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_name}
                />
              </FormGroup>
              </Col>
            <Col xs={12} sm ={4}>
              <FormGroup>
                <Label for="usr_email">Email</Label>
                <Input
                  type="text"
                  name="usr_email"
                  id="usr_email"
                  placeholder="Email"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_email}
                />
              </FormGroup>
            </Col>
            <Col xs={6} sm ={2}>

              <FormGroup>
                <Label for="usr_celphone">Telefono</Label>
                <Input
                  type="text"
                  name="usr_celphone"
                  id="usr_celphone"
                  placeholder="Telefono"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_celphone}
                />
              </FormGroup>
              </Col>
            <Col  xs={6} sm ={2}>
              <FormGroup>
                <Label for="usr_user">Usuario</Label>
                <Input
                  type="text"
                  name="usr_user"
                  id="usr_user"
                  placeholder="Usuario"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_celphone}
                  disabled
                />
              </FormGroup>
              </Col>
            <Col  xs={6} sm ={2}>
              <FormGroup>
                <Label for="exampleEmail">Password</Label>
                <Input
                  type="text"
                  name="usr_password"
                  id="usr_password"
                  placeholder="Password"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_celphone}
                  disabled
                />
              </FormGroup>
              </Col>
            <Col  xs={6} sm ={2}>
              <FormGroup>
                <Label for="usr_cedula">Cedula</Label>
                <Input
                  type="text"
                  name="usr_cedula"
                  id="usr_cedula"
                  placeholder="Cedula"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_cedula}
                />
              </FormGroup>
              </Col>
            <Col  xs={6} sm ={2}>
              <FormGroup>
                <Label for="usr_cedula">Codigo de usuario</Label>
                <Input
                  type="text"
                  name="usr_code"
                  id="usr_code"
                  placeholder="Codigo de usuario"
                  onChange={onchangeFormUser}
                  defaultValue={n_box}
                // disabled
                />
              </FormGroup>

            </Col>
            <Col  xs={6} sm ={2}>
              <FormGroup>
                <Label for="password">Costo por Libra</Label> <br></br>
                <NumberFormat
                  format="#.##"
                  name="usr_price"
                  onChange={onchangeFormUser}
                  allowEmptyFormatting={true}
                  className="form-control"
                  defaultValue={Number(dataFormUser.usr_price).toFixed(
                    2
                  )}
                  placeholder="Costo por libra"
                  min="0"
                  max="10"
                  step="0.25"
                  isAllowed={withValueCap}
                />
              </FormGroup>
              </Col>
            <Col  xs={6} sm ={2}>
              <FormGroup>
                <Label for="usr_birthday">Nacio:</Label>
                <Input
                  type="date"
                  name="usr_birthday"
                  id="usr_birthday"
                  placeholder="Fecha de nacimiento"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_birthday}
                />
              </FormGroup>
              </Col>
            <Col  xs={6} sm ={2}>
            <Label for="usr_birthday" className="mr-3">Lenguaje:</Label>
            <FormGroup>
              
              <ButtonGroup>
                <Button
                  outline
                  color="primary"
                  onClick={() => setTypeLanguage({ usr_language: "1" })}
                  active={dataFormUser.usr_language === "1" ? true : false}
                >
                  Sp
                </Button>
                <Button
                  outline
                  color="success"
                  onClick={() => setTypeLanguage({ usr_language: "2" })}
                  active={dataFormUser.usr_language === "2" ? true : false}
                >
                  Eng
                </Button>
              </ButtonGroup>
              </FormGroup>
              </Col>
            <Col  xs={6} sm ={2}>
            <Label for="usr_birthday" className="">Manejo?</Label>
<FormGroup>

              <ButtonGroup>
                <Button
                  outline
                  color="primary"
                  onClick={() => seTypeHandlerPayment({ usr_handler: "1" })}
                  active={dataFormUser.usr_handler === "1" ? true : false}
                >
                  SI
                </Button>
                <Button
                  outline
                  color="success"
                  onClick={() => seTypeHandlerPayment({ usr_handler: "0" })}
                  active={dataFormUser.usr_handler === "0" ? true : false}
                >
                  NO
                </Button>
              </ButtonGroup>
</FormGroup>

              </Col>
            <Col  xs={6} sm ={2}>
            <Label for="usr_sex" className="">Sexo</Label>
       <FormGroup>
      
              <ButtonGroup>
                <Button
                  outline
                  color="primary"
                  onClick={() => seTypeSex({ usr_sex: "1" })}
                  active={dataFormUser.usr_sex === "1" ? true : false}
                >
                  M
                </Button>
                <Button
                  outline
                  color="success"
                  onClick={() => seTypeSex({ usr_sex: "2" })}
                  active={dataFormUser.usr_sex === "2" ? true : false}
                >
                  F
                </Button>
              </ButtonGroup>
       </FormGroup>
             
              </Col>
            <Col  xs={6} sm ={2}>
            <Label for="usr_birthday" className="">Notificaciones?</Label>
       <FormGroup>
      
              <ButtonGroup>
                <Button
                  outline
                  color="primary"
                  onClick={() => seTypeHandlerNotification({ usr_not: "1" })}
                  active={dataFormUser.usr_not === "1" ? true : false}
                >
                  SI
                </Button>
                <Button
                  outline
                  color="success"
                  onClick={() => seTypeHandlerNotification({ usr_not: "0" })}
                  active={dataFormUser.usr_not === "0" ? true : false}
                >
                  NO
                </Button>
              </ButtonGroup>
       </FormGroup>

            </Col>
            <Col  xs={12} sm ={6}>
            <Label for="usr_box_pr">Casillero</Label>
              <FormGroup>
              
                <Input
                  type="textarea"
                  rows={5}
                  name="usr_box_pr"
                  id="usr_box_pr"
                  placeholder="Box pr"
                  onChange={onchangeFormUser}
                  value={
                    dataFormUser.usr_code +
                    " " +
                    dataFormUser.usr_name.toUpperCase() +
                    "\n" +
                    set_address
                  }
                  disabled
                />
              </FormGroup>
              <img
                src={COPY}
                alt=""
                onClick={() => {
                  navigator.clipboard.writeText(
                    n_box +
                    " " +
                    dataFormUser.usr_name.toUpperCase() +
                    "\n" +
                    set_address
                  );
                }}
                className="img-copy"
              ></img>
              <FormGroup>
                <Label for="password" className="mt-3">Direccion</Label>
                <Input
                  type="textarea"
                  name="usr_address"
                  id="usr_address"
                  rows={2}
                  placeholder="Direccion"
                  onChange={onchangeFormUser}
                  defaultValue={dataFormUser.usr_address}
                />
              </FormGroup>
            </Col>

          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={12} className="text-right">
              {msg_error.length > 0 ? (
                <Label className="l-color-danger">{msg_error}</Label>
              ) : null}
            </Col>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              {loading?             null:              <Button color="success" className="ml-2" onClick={toggleSaveUser}>
                Guardar
              </Button> }

            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const LOGIN = ({
  onChange,
  isOpen,
  toggle,
  toggleSave,
  toggleSaveRegister,
  dataFTeacher,
  msg_error,
  isRegisterOpen,
  handlerRegister,
  seTypeSex,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-xs modal-content">
        <ModalBody>
          {isRegisterOpen ? (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  {/* <LockOutlinedIcon /> */}
                </Avatar>
                <Typography component="h1" variant="h5">
                  Registro de nuevo usuario
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_name"
                    label="Nombre completo"
                    name="usr_name"
                    onChange={onChange}
                    defaultValue={dataFTeacher.usr_name}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_email"
                    label="Email"
                    name="usr_email"
                    onChange={onChange}
                    defaultValue={dataFTeacher.usr_email}
                    autoFocus
                  />
                  <TextField
                    className="w-100"
                    variant="outlined"
                    margin="dense"
                    required
                    id="usr_birthday"
                    name="usr_birthday"
                    label="Fecha de nacimiento"
                    type="date"
                    defaultValue={dataFTeacher.usr_birthday}
                    onChange={onChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ButtonGroup

                    variant="contained"
                    size="large"
                    className="w-100"
                  >
                    <Button
                      color="primary"
                      onClick={() => seTypeSex({ usr_sex: "1" })}
                      className="w-50"
                    >
                      Hombre
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => seTypeSex({ usr_sex: "2" })}
                      className="w-50"
                    >
                      Mujer
                    </Button>
                  </ButtonGroup>

                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_celphone"
                    label="Celular"
                    name="usr_celphone"
                    defaultValue={dataFTeacher.usr_celphone}
                    onChange={onChange}
                    autoFocus
                  />

                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_password1"
                    label="Contraseña"
                    name="usr_password1"
                    defaultValue={dataFTeacher.usr_password1}
                    onChange={onChange}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_password2"
                    label="Repetir contraseña"
                    name="usr_password2"
                    defaultValue={dataFTeacher.usr_password2}
                    onChange={onChange}
                    autoFocus
                  />

                  <Button
                    className="mt-2"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={toggleSaveRegister}
                  >
                    Registrarme
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={toggle}
                    className="mt-2"
                  >
                    Cancelar
                  </Button>
                </form>
              </div>
              <div className="w-100 text-right">
                {msg_error.length > 0 ? (
                  <label className="l-color-danger m-auto">{msg_error}</label>
                ) : null}
              </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            </Container>
          ) : (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  {/* <LockOutlinedIcon /> */}
                </Avatar>
                <Typography component="h1" variant="h5">
                  Iniciar sesión
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="celphone"
                    label="Celular"
                    name="celphone"
                    autoComplete="Celular"
                    onChange={onChange}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    onChange={onChange}
                    autoComplete="current-password"
                  />
                  {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                  <Button
                    // type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    // className={classes.submit}
                    onClick={toggleSave}
                  >
                    Iniciar sesión
                  </Button>

                  <Button
                    // type="submit"
                    // fullWidth
                    // variant="contained"
                    color="primary"
                    className="m-auto"
                    onClick={handlerRegister}
                  >
                    Registrarme
                  </Button>
                </form>
              </div>
              <div className="w-100 text-right">
                {msg_error.length > 0 ? (
                  <label className="l-color-danger m-auto">{msg_error}</label>
                ) : null}
              </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            </Container>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const CreateLote = ({
  toggleSaveLot,
  onchangeFormLot,
  dataFormLot,
  toggleModal,
  title,
  isOpenModal,
  msg_error,
  isSaving
}) => {

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
        <ModalBody>
          <Row>

            <Col xs={12}>
              <FormGroup>
                <Label for="lot_desc">Descripcion</Label>
                <Input
                  type="text"
                  name="lot_desc"
                  id="lot_desc"
                  placeholder="Descripcion"
                  onChange={onchangeFormLot}
                  defaultValue={dataFormLot.lot_desc}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lot_date">Fecha</Label>
                <Input
                  type="date"
                  name="lot_date"
                  id="lot_date"
                  placeholder="Fecha"
                  onChange={onchangeFormLot}
                  defaultValue={moment(dataFormLot.lot_date).format(
                    "YYYY-MM-DD"
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              {isSaving ? null :               <Button color="success" className="ml-2" onClick={toggleSaveLot}>
                Guardar
              </Button>}
            </Col>
            {msg_error.length > 0 ? (
              <Col sm={12} className="text-right">
                <Label className="text-red">Campos vacios</Label>
              </Col>
            ) : null}
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const ExportJson = ({
  toggleSaveLot,
  onchangeFormJson,
  json,
  toggleModal,
  title,
  isOpenModal,
  msg_error,
}) => {

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="lot_date">Fecha</Label>
                <Input
                  type="textarea"
                  name="json"
                  id="json"
                  placeholder="JSON"
                  onChange={onchangeFormJson}
                  defaultValue={json}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              <Button color="success" className="ml-2" onClick={toggleSaveLot}>
                Guardar
              </Button>
            </Col>
            {msg_error.length > 0 ? (
              <Col sm={12} className="text-right">
                <Label className="text-red">Campos vacios</Label>
              </Col>
            ) : null}
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const CreateTracking = ({
  toggleSaveTracking,
  onchangeFormTracking,
  dataFormTracking,
  toggleModal,
  title,
  isOpenModal,
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} className="mt-2 mb-2 text-center">
              <h4>Tracking</h4>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <Label for="Lote">Lote ID </Label>
                <Input
                  type="text"
                  name="lot_id"
                  id="lot_id"
                  placeholder="Lote ID"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.lot_id}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tr_track">Track</Label>
                <Input
                  type="text"
                  name="tr_track"
                  id="tr_track"
                  placeholder="Track"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.tr_track}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tr_desc">Descripcion</Label>
                <Input
                  type="textarea"
                  name="tr_desc"
                  id="tr_desc"
                  placeholder="Descripcion"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.tr_desc}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tr_price">Precio</Label>
                <Input
                  type="text"
                  name="tr_price"
                  id="tr_price"
                  placeholder="Precio"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.tr_price}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tr_user">Usuario</Label>
                <Input
                  type="text"
                  name="tr_user"
                  id="tr_user"
                  placeholder="Usuario"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.tr_user}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tr_type_box">Tipo de caja</Label>
                <Input
                  type="text"
                  name="tr_type_box"
                  id="ustr_type_boxr_cedula"
                  placeholder="Tipe Box"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.tr_type_box}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Label for="tr_libra">Libra</Label>
                <Input
                  type="text"
                  name="tr_libra"
                  id="tr_libra"
                  placeholder="Libra"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.tr_libra}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tr_date_delivered">Fecha de entrega</Label>
                <Input
                  type="text"
                  name="tr_date_delivered"
                  id="tr_date_delivered"
                  placeholder="Fecha de Entrega"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.tr_date_delivered}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tr_date_received">Box volumen</Label>
                <Input
                  type="text"
                  rows={5}
                  name="tr_date_received"
                  id="tr_date_received"
                  placeholder="Fecha de Recibido"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.tr_date_received}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tr_status">Estado</Label>
                <Input
                  type="text"
                  rows={5}
                  name="tr_status"
                  id="tr_status"
                  placeholder="Estado"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.tr_status}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              <Button
                color="primary"
                className="ml-2"
                onClick={toggleSaveTracking}
              >
                Agregar
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const ModalTracking = ({
  setTarjeta,
  DataClientes,
  togleSelectCliente,
  toggleSaveTracking,
  onchangeFormTracking,
  dataFormTracking,
  toggleModal,
  title,
  isOpenModal,
  setTypeCasillero,
  type_local,
  msg_error,
  isSaving
}) => {
  const MAX_VAL = 900000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        {/* <ModalHeader toggle={toggleModal}>
          {title} {type_local === "1" ? "APBOX" : "MTECHNOLOGY"}
        </ModalHeader> */}
        <ModalBody>
          <Row>
            <Col xs={12} className="content-list-user">
              <Datatable
                data={DataClientes}
                columns={COLUMNS_CLIENTES()}
                keyProp="usr_id"
                exportD={false}
                searchs={true}
                selectRows={true}
                RadioBtn={true}
                optionSelectRow={togleSelectCliente}
              />
            </Col>
            <Col xs={4}>
              <Label for="tr_track" className="w-25  pt-2">TRACKING</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="tr_track"
                  id="tr_track"
                  placeholder="Tracking"
                  onChange={onchangeFormTracking}
                  value={dataFormTracking.tr_track}
                  autoFocus={true}
                />
              </FormGroup>

            </Col>
            <Col xs={2}>
              <Label for="tr_libra" className="w-25  pt-2">PESO </Label>
              <FormGroup className="d-flex">
                <NumberFormat
                  // format="#####.##"
                  name="tr_libra"
                  onChange={onchangeFormTracking}
                  allowEmptyFormatting={true}
                  className="form-control pl-2"
                  value={Number(dataFormTracking.tr_libra)}
                  placeholder="Peso (LB)"
                  min="1"
                  max="1000"
                  step="1"
                  isAllowed={withValueCap}
                  disabled={dataFormTracking.usr_code.length > 0 ? false : true}
                />
              </FormGroup>
            </Col>
            <Col xs={2}>
              <Label for="tr_price" className="w-25  pt-2">PRECIO</Label>
              <FormGroup className="d-flex">
                <Input
                  type="text"
                  name="tr_price"
                  id="tr_price"
                  className="pl-2"
                  placeholder="Precio"
                  onChange={onchangeFormTracking}
                  value={dataFormTracking.tr_price}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col xs={2} className="text-center">
              <FormGroup >
                <Label for="cotd_price">TARJETA? </Label>
                <br></br>
                <ButtonGroup>
                  <Button outline color="info" onClick={() => setTarjeta({ tr_use_tarjeta: "1" })}
                    active={
                      dataFormTracking.tr_use_tarjeta === "1" ? true : false
                    }
                    disabled={
                      dataFormTracking.tr_libra === "" ? true : false
                    }
                  >
                    SI
                  </Button>
                  <Button outline color="info" onClick={() => setTarjeta({ tr_use_tarjeta: "0" })}
                    active={
                      dataFormTracking.tr_use_tarjeta === "0" ? true : false
                    }
                    disabled={dataFormTracking.tr_libra === "" ? true : false}

                  >
                    NO
                  </Button>
                </ButtonGroup>

              </FormGroup>


            </Col>
            {/* <Label for="tr_type_box w-50 pt-2 pr-2">CASILLERO</Label>
              <FormGroup className="d-flex">
                <ButtonGroup className="pl-2">
                  <Button
                    outline
                    color="primary"
                    onClick={() => setTypeCasillero({ tr_type_box: "1" })}
                    active={dataFormTracking.tr_type_box === "1" ? true : false}
                    disabled
                  >
                    VOL
                  </Button>
                  <Button
                    outline
                    color="success"
                    onClick={() => setTypeCasillero({ tr_type_box: "2" })}
                    active={dataFormTracking.tr_type_box === "2" ? true : false}
                  >
                    P. REAL
                  </Button>
                </ButtonGroup>
              </FormGroup> */}

            {/* <Label for="tr_date_received" className ="w-25 pt-2">FECHA</Label>
              <FormGroup className ="d-flex">
                <Input
                  type="date"
                  name="tr_date_received"
                  id="tr_date_received"
                  placeholder="Fecha"
                  onChange={onchangeFormTracking}
                  defaultValue={moment(dataFormTracking.tr_date_received).format("YYYY-MM-DD")}
                  disabled
                />
              </FormGroup> */}
            <Col xs={2}>
              <Label for="tr_user" className="w-25 pt-2">USUARIO </Label>
              <FormGroup className="d-flex">
                <Input
                  type="text"
                  name="tr_user"
                  id="tr_user"
                  placeholder="Usuario"
                  className="pl-2"
                  onChange={onchangeFormTracking}
                  value={dataFormTracking.usr_code}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={12} className="text-right">
              {msg_error.length > 0 ? (
                <Label className="l-color-danger">{msg_error}</Label>
              ) : null}
            </Col>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              {isSaving ? null : <Button
                color="success"
                className="ml-2"
                onClick={toggleSaveTracking}
              >
                Guardar
              </Button>}

            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const ModalTrackingLost = ({
  DataClientes,
  togleSelectCliente,
  setTypeCasillero,
  toggleSaveTracking,
  onchangeFormTracking,
  dataFormTracking,
  toggleModal,
  title,
  isOpenModal,
  type_local,
  msg_error,
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        <ModalHeader toggle={toggleModal}>
          {title} {type_local === "1" ? "APBOX" : "MTECHNOLOGY"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} className="content-list-user">
              <Datatable
                data={DataClientes}
                columns={COLUMNS_CLIENTES(togleSelectCliente)}
                keyProp="usr_id"
                exportD={false}
                searchs={true}
                selectRows={false}
                RadioBtn={false}
                optionSelectRow={null}
              />
            </Col>
            <Col xs={1}></Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="los_track">TRACKING</Label>
                <Input
                  type="text"
                  name="los_track"
                  id="los_track"
                  placeholder="Tracking"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.los_track}
                  autoFocus={true}
                />
              </FormGroup>
              <FormGroup>
                <Label for="tr_user">Codigo de casillero</Label>
                <Input
                  type="text"
                  name="tr_user"
                  id="tr_user"
                  placeholder="Usuario"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.usr_code}
                  disabled
                />
              </FormGroup>

            </Col>
            <Col xs={1}></Col>
            <Col xs={1}></Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="tr_user">Descripcion</Label>
                <Input
                  type="textarea"
                  rows={6}
                  name="los_desc"
                  id="los_desc"
                  placeholder="Descripcion"
                  onChange={onchangeFormTracking}
                  defaultValue={dataFormTracking.los_desc}
                />
              </FormGroup>
            </Col>
            <Col xs={1}></Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={12} className="text-right">
              {msg_error.length > 0 ? (
                <Label className="l-color-danger">{msg_error}</Label>
              ) : null}
            </Col>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              <Button
                color="success"
                className="ml-2"
                onClick={toggleSaveTracking}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const ModalAddCotizacionDetail = ({
  dataFormCotizacion,
  toggleSaveCotizacion,
  onchangeFormCotizacion,
  toggleModal,
  title,
  isOpenModal,
  msg_error,
  setItbms
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        <ModalHeader toggle={toggleModal}>
          {title}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="cot_name">DESCRIPCION: </Label>
                <Input
                  type="textarea"
                  name="cotd_art_descripcion"
                  rows={10}
                  id="cotd_art_descripcion"
                  placeholder="DESCRIPCION"
                  onChange={onchangeFormCotizacion}
                  defaultValue={dataFormCotizacion.cotd_art_descripcion}
                  autoFocus={true}
                />
              </FormGroup>

            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label for="cotd_qty">CANTIDAD</Label>
                <Input
                  type="number"

                  name="cotd_qty"
                  id="cotd_qty"
                  placeholder="CANTIDAD"
                  onChange={onchangeFormCotizacion}
                  defaultValue={dataFormCotizacion.cotd_qty}
                />
              </FormGroup>

            </Col>

            <Col xs={4}>

              <FormGroup>
                <Label for="cotd_price">PRECIO </Label>
                <Input
                  type="number"
                  name="cotd_price"
                  id="cotd_price"
                  placeholder="PRECIO "
                  onChange={onchangeFormCotizacion}
                  defaultValue={dataFormCotizacion.cotd_price}
                  autoFocus={true}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup >
                <Label for="cotd_price">IMPUESTOS </Label>
                <br></br>
                <ButtonGroup>
                  <Button outline color="info" onClick={() => setItbms({ cotd_itbms: "7" })} active={dataFormCotizacion.cotd_itbms === "7" ? true : false}>
                    7% ITBMS
                  </Button>
                  <Button outline color="info" onClick={() => setItbms({ cotd_itbms: "0" })} active={dataFormCotizacion.cotd_itbms === "0" ? true : false}>
                    SIN ITBMS
                  </Button>
                </ButtonGroup>

              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={12} className="text-right">
              {msg_error.length > 0 ? (
                <Label className="l-color-danger">{msg_error}</Label>
              ) : null}
            </Col>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              <Button
                color="success"
                className="ml-2"
                onClick={toggleSaveCotizacion}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const ModalAddCotizacion = ({
  dataFormCotizacion,
  toggleSaveCotizacion,
  onchangeFormCotizacion,
  toggleModal,
  title,
  isOpenModal,
  msg_error,
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        <ModalHeader toggle={toggleModal}>
          {title}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="cot_name">NOMBRE: </Label>
                <Input
                  type="text"
                  name="cot_name"
                  id="cot_name"
                  placeholder="Nombre"
                  onChange={onchangeFormCotizacion}
                  defaultValue={dataFormCotizacion.cot_name}
                  autoFocus={true}
                />
              </FormGroup>

            </Col>

            <Col xs={12}>
              <FormGroup>
                <Label for="cot_celular">CELULAR</Label>
                <Input
                  type="text"

                  name="cot_celular"
                  id="cot_celular"
                  placeholder="Celular"
                  onChange={onchangeFormCotizacion}
                  defaultValue={dataFormCotizacion.cot_celular}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Label for="cot_ruc">RUC</Label>
                <Input
                  type="text"

                  name="cot_ruc"
                  id="cot_ruc"
                  placeholder="RUC"
                  onChange={onchangeFormCotizacion}
                  defaultValue={dataFormCotizacion.cot_ruc}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Label for="cot_observacion">OBSERVACIONES: </Label>
                <Input
                  type="textarea"
                  rows={8}
                  name="cot_observacion"
                  id="cot_observacion"
                  placeholder="Observaciones"
                  onChange={onchangeFormCotizacion}
                  defaultValue={dataFormCotizacion.cot_observacion}
                  autoFocus={true}
                />
              </FormGroup>
            </Col>

          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={12} className="text-right">
              {msg_error.length > 0 ? (
                <Label className="l-color-danger">{msg_error}</Label>
              ) : null}
            </Col>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              <Button
                color="success"
                className="ml-2 btn_primary"
                onClick={toggleSaveCotizacion}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
const ModalEmail = ({
  title,
  isOpenModal,
}) => {

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        // toggle={toggleModal}
        className="modal-email"
      >
        <ModalHeader >
          {title}
        </ModalHeader>
        <ModalBody className="modal-email-content">
          <Row>
            <Col xs={12} className="content-list-user text-center">
            <Label className="">Enviando....</Label>  
            <img className="icon-msg" src={SEND_EMAIL} alt="" />
            </Col>
          </Row>
        </ModalBody>
        
      </Modal>
    </React.Fragment>
  );
};
const ModalTrackingOrder = ({
  DataClientes,
  togleSelectCliente,
  toggleSaveTracking,
  onchangeFormOrder,
  dataFormOrder,
  toggleModal,
  title,
  isOpenModal,
  msg_error,
}) => {
  const MAX_VAL = 90000000000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className="modal-xs"
      >
        <ModalHeader toggle={toggleModal}>
          {title}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} className="content-list-user">
              <Datatable
                data={DataClientes}
                columns={COLUMNS_CLIENTES(togleSelectCliente)}
                keyProp="usr_id"
                exportD={false}
                searchs={true}
                selectRows={true}
                RadioBtn={true}
                optionSelectRow={togleSelectCliente}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={6}>
              <FormGroup>
                <Input
                  type="textarea"
                  rows={2}
                  name="ord_desc"
                  id="ord_desc"
                  placeholder="Descripcion"
                  onChange={onchangeFormOrder}
                  defaultValue={dataFormOrder.ord_desc}
                  autoFocus={true}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  rows={2}
                  name="ord_link"
                  id="ord_link"
                  placeholder="Link"
                  onChange={onchangeFormOrder}
                  defaultValue={dataFormOrder.ord_link}
                />
              </FormGroup>

            </Col>

            <Col xs={6}>
              <FormGroup>
                <Input
                  type="text"
                  name="ord_track"
                  id="ord_track"
                  placeholder="Tracking"
                  onChange={onchangeFormOrder}
                  defaultValue={dataFormOrder.ord_track}
                />
              </FormGroup>
              <FormGroup>
                <NumberFormat
                  // format="#####.##"
                  name="ord_precio"
                  onChange={onchangeFormOrder}
                  allowEmptyFormatting={true}
                  className="form-control"
                  defaultValue={dataFormOrder.ord_precio}
                  placeholder="Precio en la página"
                  min=""
                  max="10"
                  step="0.25"
                  isAllowed={withValueCap}
                />
              </FormGroup>
              <FormGroup>
                <NumberFormat
                  // format="#####.##"
                  name="ord_precio"
                  onChange={onchangeFormOrder}
                  allowEmptyFormatting={true}
                  className="form-control"
                  defaultValue={dataFormOrder.ord_precio}
                  placeholder="Precio estimado"
                  min=""
                  max="10"
                  step="0.25"
                  isAllowed={withValueCap}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="usr_name"
                  id="usr_name"
                  placeholder="Usuario"
                  onChange={onchangeFormOrder}
                  value={dataFormOrder.usr_name}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs={12} className="text-right">
              {msg_error.length > 0 ? (
                <Label className="l-color-danger">{msg_error}</Label>
              ) : null}
            </Col>
            <Col sm={12} className="text-right">
              <Button color="danger" onClick={toggleModal}>
                Cancelar
              </Button>
              <Button
                color="success"
                className="ml-2"
                onClick={toggleSaveTracking}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const FIND_DESC = (arr, elm) => {
  // eslint-disable-next-line array-callback-return
  const result = arr.find(function (item) {
    if (item.usr_celphone !== undefined) {
      if (
        item.usr_celphone.toLowerCase() ===
        elm.toLowerCase().replace(/\s+/g, "")
      )
        return true;
    }
  });
  if (result !== undefined) return true;
  else return false;
};

const FIND_DESC_SETTING = (arr, elm) => {
  // eslint-disable-next-line array-callback-return
  const result = arr.find(function (item) {
    if (item.usr_user !== undefined) {
      if (
        item.usr_user.toLowerCase() ===
        elm.toLowerCase().replace(/\s+/g, "")
      )
        return true;
    }
  });
  if (result !== undefined) return true;
  else return false;
};

const MESSAGE_PERSON_LIST = ({
  props,
  setPerson,
  reload,
  handlerGoback,
  isBack,
}) => {
  return (
    <React.Fragment>
      <Row>
        {isBack && (
          <Col xs={12} className="text-right">
            <Button color="primary" onClick={() => handlerGoback()}>
              Regresar
            </Button>
          </Col>
        )}
        <Col xs={12} className="text-right mt-2">
          <ClipLoader
            css={override}
            size={23}
            color={"#36D7B7"}
            loading={props.loading}
          />
          <Button color="info" className="mr-2" onClick={() => reload()}>
            Recargar
          </Button>
        </Col>
        {props.dataPerson.length > 0 ? (
          <Col xs={12} className="text-left mt-3">
            <Datatable
              data={props.dataPerson}
              columns={COLUMNS_CHAT(setPerson)}
              keyProp="usr_id"
              exportD={false}
              searchs={true}
              selectRows={true}
              RadioBtn={true}
              optionSelectRow={null}
            />

            {/* <ListGroup className="overflow-auto">
              {props.dataPerson.map((index, i) => (
                <ListGroupItem
                  className="d-flex lh-40"
                  key={index.usr_id}
                  tag="a"
                  action
                  onClick={() =>
                    setPerson({
                      usr_id: index.usr_id,
                      usr_name: index.usr_name,
                    })
                  }
                >
                  <img className="list-person-icon" src={MESSAGE_ICON} alt="" />
                  <Label className="ml-3 label-area-desc-bold">
                    {index.usr_name}
                  </Label>
                </ListGroupItem>
              ))}
            </ListGroup> */}
          </Col>
        ) : (
          <React.Fragment>
            <Col className="text-center mb-1 p-0" xs={12}>
              <img className="icon-msg" src={MESSAGE_ICON} alt="" />
            </Col>
            <Col className="text-center p-0" xs={12}>
              <Label className="font-weight-bold result-none">
                Sin Resultados
              </Label>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );
};

const MESSAGE_CHAT_INTERFACE = ({
  per_person,
  props,
  handlerSendMessage,
  onChangeMessage,
  isOpenMessage,
  handlerGoback,
  isOpenButtonback,
  reload,
  handleKeyPress
}) => {
  return (
    <React.Fragment>
      {isOpenButtonback && (
        <Col xs={12} className="text-right mb-4 p-0 d-block d-sm-none">
          <ClipLoader
            css={override}
            size={23}
            color={"#36D7B7"}
            loading={props.loading}
          />
          <Button color="info" className="mr-2" onClick={() => reload()}>
            Recargar
          </Button>
          <Button color="danger" className="info" onClick={() => handlerGoback()}>
            Cerrar
          </Button>
        </Col>
      )}
      {props.isOpenMessage ? (
        props.personForm.usr_name.length > 0 && (
          <React.Fragment>
            <Col xs={12} className="text-right mb-4 p-0 d-none d-sm-block">
              <ClipLoader
                css={override}
                size={23}
                color={"#36D7B7"}
                loading={props.loading}
              />
              <Button color="info" className="mr-2 info" onClick={() => reload()}>
                Recargar
              </Button>
              <Button color="danger" className="ok" onClick={() => handlerGoback()}>
                Cerrar
              </Button>
            </Col>
            <div
              className="p-0 chat-window col-xs-12 col-md-12"
              id="chat_window_1"
            >
              <div className="panel panel-default">
                <div className="panel-heading top-bar">
                  <div className="col-md-12 col-xs-12">
                    <label className="panel-title d-flex">
                      <span className="glyphicon glyphicon-comment"></span>{" "}
                      <img
                        className="icon-msg-chat"
                        src={MESSAGE_ICON}
                        alt=""
                      />
                      Chat - {props.personForm.usr_name}
                    </label>
                  </div>
                </div>
                <div
                  className="panel-body msg_container_base"
                  id="scrollContainer"
                >
                  {props.dataMessages.map((data, i) =>
                    data.usr_id === per_person ? (
                      <div className="row msg_container base_sent" key={i}>
                        <div className="col-2"></div>
                        <div className="col-8">
                          <div className="messages msg_sent">
                            <p className="label-name-chat">{data.usr_name}</p>
                            <p className="pl-2">{data.msg_desc}</p>
                            <time dateTime="2009-11-13T20:00">
                              {moment(data.msg_time).format("YYYY-MM-DD")}
                            </time>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row msg_container base_receive" key={i}>
                        <div className="col-8">
                          <div className="messages msg_receive">
                            <p
                              className="label-name-chat"
                              style={{ color: "cornflowerblue" }}
                            >
                              {data.usr_name}
                            </p>
                            <p className="pl-2">{data.msg_desc}</p>
                            <time dateTime="2009-11-13T20:00">
                              {moment(data.msg_time).format("YYYY-MM-DD")}
                            </time>
                          </div>
                        </div>
                        <div className="col-2"></div>
                      </div>
                    )
                  )}
                </div>
                <div className="panel-footer">
                  <div className="input-group">
                    <input
                      type="text"
                      name="msg_desc"
                      onChange={onChangeMessage}
                      value={props.formMessages.msg_desc}
                      // id="msg_desc"
                      onKeyPress={(e) =>
                        handleKeyPress(e)
                      }
                      className="form-control input-sm rounded-0"
                      placeholder="Escribe tu mensaje aqui ..."
                    />
                    <span className="input-group-btn">
                      {props.formMessages.msg_desc.length > 0 ? (
                        <button
                          className="btn btn-primary info rounded-0"
                          onClick={() => handlerSendMessage()}
                        >
                          Enviar
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary info rounded-0"
                          disabled
                        >
                          Enviar
                        </button>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      ) : (
        <React.Fragment>
          <Col className="text-center mb-1 p-0" xs={12}>
            <img className="icon-msg2" src={MESSAGE_ICON} alt="" />
          </Col>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const MESSAGE_CHAT_CONTAINER = ({
  usr_id,
  props,
  handlerSendMessage,
  onChangeMessage,
  setPerson,
  isOpenMessage,
  handlerGoback,
  reload,
  reload2,
  handleKeyPress
}) => (
  <React.Fragment>
    <Col className="containerDocList d-block d-sm-none" xs={12} sm={4}>
      {" "}
      {/* MOBILE */}
      {!isOpenMessage ? (
        <MESSAGE_PERSON_LIST
          props={props}
          setPerson={setPerson}
          reload={reload2}
          isBack={false}
        />
      ) : (
        <MESSAGE_CHAT_INTERFACE
          per_person={usr_id}
          props={props}
          handlerSendMessage={handlerSendMessage}
          onChangeMessage={onChangeMessage}
          isOpenMessage={isOpenMessage}
          handlerGoback={handlerGoback}
          isOpenButtonback={true}
          reload={reload}
          handleKeyPress={handleKeyPress}
        />
      )}
    </Col>
    <Col className="containerDocList d-none d-sm-block desktop" xs={12}>
      {/* DESKTOP */}
      <Row>
        <Col xs={1}>
        </Col>
        <Col xs={3}>
          <MESSAGE_PERSON_LIST
            props={props}
            setPerson={setPerson}
            reload={reload2}
            isBack={false}
          />
        </Col>
        <Col xs={7}>
          <MESSAGE_CHAT_INTERFACE
            per_person={usr_id}
            props={props}
            handlerSendMessage={handlerSendMessage}
            onChangeMessage={onChangeMessage}
            isOpenMessage={isOpenMessage}
            handlerGoback={handlerGoback}
            isOpenButtonback={false}
            reload={reload}
            handleKeyPress={handleKeyPress}
          />
        </Col>
        <Col xs={1}></Col>
      </Row>
    </Col>
  </React.Fragment>
);

const CHANGE_PASSWORD = ({
  onChange,
  isOpen,
  toggle,
  toggleSave,
  formLogin,
  msg_error
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-password modal-content">
        <ModalBody>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                </Avatar>
                <Typography component="h1" variant="h5">
                 Cambiar contraseña
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_password"
                    label="Contraseña"
                    name="usr_password"
                    onChange={onChange}
                    defaultValue={formLogin.usr_password}
                    autoFocus
                  />
                   
                   <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="usr_password2"
                    label="Repetir contraseña"
                    name="usr_password2"
                    onChange={onChange}
                    defaultValue={formLogin.usr_password}
                    autoFocus
                  />
                  <Button
                    className="mt-2 ml-4"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={toggleSave}
                  >
                    Guardar
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={toggle}
                    className="mt-2 ml-2"
                  >
                    Cancelar
                  </Button>
                </form>
              </div>
              <div className="w-100 text-right">
                {msg_error.length > 0 ? (
                  <label className="l-color-danger m-auto">{msg_error}</label>
                ) : null}
              </div>
            </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export {ModalEmail,
  FIND_DESC_SETTING,
  CreateUserAdmin,
  validateTokenStorage,
  LOGIN,
  ExportJson,
  ModalAddCotizacionDetail,
  ModalTrackingOrder,
  CHANGE_PASSWORD,
  MESSAGE_CHAT_CONTAINER,
  FIND_DESC,
  ModalTracking,
  CreateUser,
  CreateLote,
  CreateTracking,
  ModalTrackingLost,
  ModalAddCotizacion
};
