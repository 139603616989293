import React, { Component } from "react";
import { Row, Col, Button,Label } from "reactstrap";
import { getGroupToPrint,getDataToPrint,putPrint } from "./../Services";
import Datatable from "../Datatable/Index";
import {LOGO} from './../../Components/SrcImages';
import REACT_PRINT_COMPONENT from "./../Datatable/React_print";
import { COLUMNS_PRINT } from "../Datatable/DatatableColumns";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import StoreContext from "./../../Components/Store/Context";

const override = css`
  margin: 0 auto;
  position: relative;
  top: 10px;
  right: 10px;
`;

class Print extends Component {
  static contextType = StoreContext
  constructor() {
    super();
    this.state = {
      dataPrint: [],
      dataPrintDetail:[],
      loading: false,
      msg_error: "",
    };
    
    this.fetchGroupToPrint = this.fetchGroupToPrint.bind(this);
    this.rechargeData = this.rechargeData.bind(this);
    this.print = this.print.bind(this);
    this.togglePrint = this.togglePrint.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async fetchGroupToPrint() {
    const response = await getGroupToPrint(this.context.usr_id);
    this.setState({
      dataPrint: response,
      loading: false,
    });
  }

  async fetchPrint(usr_id) {
    const body = {
      usr_id
    }
    
    const response = await getDataToPrint(usr_id,this.context.usr_id);
    this.setState({
      dataPrintDetail: response,
      loading: false,
    });
    setTimeout(() => {
      // putPrint(body);
    }, 3000);
  }

  rechargeData() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchGroupToPrint();
    }, 1000);
  }

  print(row) {
    this.setState({ loading: true });
    this.fetchPrint(row.usr_id);
  }

  componentDidMount() {
    this.fetchGroupToPrint();
  }

  togglePrint(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            <Button color="success" onClick={() => this.print(row)}>
              Imprimir
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  iPrintBody = () => {
    const props = this.state;
    var tl_monto = 0;
    var tl_amount = 0;
    var tl_handler = 0;
    var tl_lb =0;
    var tl_tarjeta = props.dataPrintDetail[0].set_price_tarjeta;
    var tl_amount_tarjeta =0;
    return (
      <React.Fragment>
        <Row className="mt-5 mb-5" key={new Date().toString()}>
            <Col xs={12} className="text-center mt-4 mb-4">
            <img alt="" className="logo-school-informe" src={LOGO}></img>
            {/* <h4 className="font-weight-bold h4-prelative">
              MTECHNOLOGY <br></br>
              <br></br>         
            </h4> */}
          </Col>
          <Col xs={2}>
            </Col>
          <Col xs={4} className="text-left mb-3">
            {/* <Label className="font-weight-bold">Fecha : {moment(new Date(props.dateIDairy)).format("YYYY-MM-DD")}</Label> <br></br> */}
            <Label className="font-weight-bold">
              Nombre : {props.dataPrintDetail[0].usr_name}
            </Label>{" "}
            <br></br>
            <Label className="font-weight-bold">
              Codigo de usuario : {props.dataPrintDetail[0].usr_code}
            </Label>{" "}
          </Col>
          <Col xs={4} className="text-right mb-3">
            {/* <Label className="font-weight-bold">Fecha : {moment(new Date(props.dateIDairy)).format("YYYY-MM-DD")}</Label> <br></br> */}
            <Label className="font-weight-bold">
             {moment(new Date()).format("YYYY-MM-DD")}
            </Label>{" "}
            <br></br>
          </Col>
          <Col xs={2}>
            </Col>

            <Col xs={1}>
            </Col>
          <Col xs={10} className="text-center border-print">
            <table className="table table-bordered">
              <thead>
                <tr>
                 
                  <th className="text-center w-70por">TRACKING</th>
                  <th className="text-center w-250px">Fecha de ENTREGA</th>
                  <th className="text-center w-250px">PESO LB</th>
                  <th className="text-center">MONTO $$</th>
                </tr>
              </thead>
              <tbody>
                {props.dataPrintDetail.map((data, i) => {
                  tl_monto = Number(tl_monto) + Number(data.tr_price);
                  tl_amount = Number(tl_amount) + 1;
                  if(data.tr_use_tarjeta ==="1"){
                    tl_amount_tarjeta = Number(tl_amount_tarjeta) + Number(tl_tarjeta);
                  }

                  if(data.usr_handler ==="1"){
                    tl_handler = Number(tl_handler) + Number(data.set_price_manejo);
                  }

                  // tl_handler
                  tl_lb = Number(tl_lb) + Number(data.tr_libra);
                  return (
                    <tr key={i}>
                      <td className="text-left font-weight-bold">{data.tr_track}</td>
                      <td>{data.tr_date_received}</td>
                      <td className="text-center">
                      {data.tr_libra}
                      </td>
                      <td className="text-right">{Number(data.tr_price).toFixed(2)}</td>
                    </tr>
                  );
                })}
<tr>
                  <td className=""></td>
                  <td className="text-left">
                    <Label className="font-weight-bold">CANTIDAD DE PAQUETES</Label>
                  </td>
                  <td className="text-right font-weight-bold">
                    {tl_amount}
                  </td>
                </tr>
                <tr>
                  <td className=""></td>
                  <td className="text-left">
                    <Label className="font-weight-bold">TOTAL DE LIBRAS</Label>
                  </td>
                  <td className="text-right font-weight-bold">
                    {tl_lb}
                  </td>
                </tr>
                <tr>
                  <td className=""></td>
                  <td className="text-left">
                    <Label className="font-weight-bold">CARGO DE USO DE TARJETA</Label>
                  </td>
                  <td className="text-right font-weight-bold">
                    {Number(tl_amount_tarjeta).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className=""></td>
                  <td className="text-left">
                    <Label className="font-weight-bold">MANEJO</Label>
                  </td>
                  <td className="text-right font-weight-bold">
                    {Number(tl_handler).toFixed(2)}
                  </td>
                </tr>
                
                <tr>
                  <td className=""></td>
                  <td className="text-left">
                    <Label className="font-weight-bold">TOTALES</Label>
                  </td>
                  <td className="text-right font-weight-bold">
                    {(tl_monto).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col xs={1}>
            </Col>
          <Col xs={12} className="text-left mt-3">
            <Label className="font-weight-bold">
              Revisador por : _________________________________________________
            </Label>
          </Col>

        </Row>
      </React.Fragment>
    );
  };

  handleGoBack() {
    this.setState({ dataPrintDetail: [] });
    this.fetchGroupToPrint();
  }
  render() {
    document.body.classList.add('body-class');
    const props = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            {props.dataPrintDetail.length > 0 ? (
              <React.Fragment>
             <Col xs={12} className="text-right">
             <Button
                color="primary"
                onClick={() => this.handleGoBack()}
              >
                Cerrar
              </Button>
             </Col>
             <REACT_PRINT_COMPONENT body={this.iPrintBody()} />
              </React.Fragment>
             
            ) : (
              <React.Fragment>
                  <Row>
                    <Col  xs={12}  className="text-center mt-3">
                    <h4>Imprimir</h4>
                    </Col>
                  <Col xs={1}></Col>
                    <Col xs ={10} className="text-right mt-3">
                    <ClipLoader
                css={override}
                size={23}
                color={"#36D7B7"}
                loading={this.state.loading}
              />
              <Button
                color="info"
                className="mr-2"
                onClick={() => this.rechargeData()}
              >
                Recargar
              </Button>
                    </Col>
                    <Col xs={1}></Col>
                  <Col xs={1}></Col>
                <Col xs={10}>
                  <Datatable
                    data={props.dataPrint}
                    columns={COLUMNS_PRINT(this.togglePrint)}
                    keyProp="usr_id"
                    exportD={false}
                    searchs={true}
                    selectRows={false}
                    RadioBtn={false}
                    optionSelectRow={null}
                  />
                </Col>
                <Col xs={1}></Col>
                  </Row>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Print;
