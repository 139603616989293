import React, {Component } from 'react';
import {reactLocalStorage} from 'reactjs-localstorage';
import StoreContext from './Context';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import {validateTokenStorage } from "../Util";

import{nonce,path,privateKey} from './../Services';

var x = reactLocalStorage.getObject('tokenP');
if(validateTokenStorage()){
  reactLocalStorage.clear();
}


class StoreProvider extends Component{
  constructor(props) {
    super(props);
    this.state = {
      children:props.children,
      token:x.token,
      usr_id:x.usr_id,
      usr_name: x.usr_name,
      usr_type:x.usr_type,
      children: props.children,
      key:x.key,
      rol_home:x.rol_home,
      rol_user:x.rol_user,
      rol_track:x.rol_track,
      rol_carga:x.rol_carga,
      rol_lost:x.rol_lost,
      rol_orders:x.rol_orders,
      rol_cot:x.rol_cot,
      rol_chat:x.rol_chat,
      rol_print:x.rol_print,
      rol_setting:x.rol_setting,
    };

    this.setToken = this.setToken.bind(this);
    this.quitToken = this.quitToken.bind(this);
  }

  getKeyToke({usr_id,usr_type}){
    const hashKey = sha256(nonce + usr_type +usr_id);
    const hmacKey = Base64.stringify(hmacSHA512(path + hashKey, privateKey));
    return hmacKey
  }

  setToken = ({token,usr_id,usr_name,usr_type})=> {
    const keyToken = this.getKeyToke({usr_id:usr_id,usr_type:usr_type})
    this.setState({
      token,
      usr_id,
      usr_name,
      usr_type,
      key:keyToken
    });
    reactLocalStorage.setObject('tokenP',{usr_id,usr_name,usr_type,token,key:keyToken});
  }

  setRole = ({rol_home,rol_user,rol_track,rol_carga,rol_lost,rol_orders,rol_cot,rol_chat,rol_print,rol_setting})=> {
    const keyToken = this.getKeyToke({usr_id:this.state.usr_id,usr_type:this.state.usr_type})
    this.setState({
      ...
      rol_home,
      rol_user,
      rol_track,
      rol_carga,
      rol_lost,
      rol_orders,
      rol_cot,
      rol_chat,
      rol_print,
      rol_setting,
      key:keyToken
    });
    reactLocalStorage.setObject('tokenP',{      rol_home,
      rol_user,
      rol_track,
      rol_carga,
      rol_lost,
      rol_orders,
      rol_cot,
      rol_chat,
      rol_print,
      rol_setting,
      key:keyToken,
      token:this.state.token,
      usr_id:this.state.usr_id,
      usr_name:this.state.usr_name,
      usr_type:this.state.usr_type,
    });
  }

  quitToken = ()=> {
    this.setState({
      allow:null,
      token:null,
      usr_type:null,
      per_id:null,
      year:null,
      per_admin:null,
      per_std:null,
      key:null
    });
    reactLocalStorage.clear();
  }
  
  render() {
    const props= this.state;
    return (
      <StoreContext.Provider value={{rol_home:props.rol_home,
        rol_user:props.rol_user,
        rol_track:props.rol_track,
        rol_carga:props.rol_carga,
        rol_lost:props.rol_lost,
        rol_orders:props.rol_orders,
        rol_cot:props.rol_cot,
        rol_chat:props.rol_chat,
        rol_print:props.rol_print,
        rol_setting:props.rol_setting,token:props.token,usr_id:props.usr_id,usr_name:props.usr_name,usr_type:props.usr_type,key:props.key,setToken:this.setToken,quitToken:this.quitToken, setRole:this.setRole}}>
            {props.children}
      </StoreContext.Provider>
    );
  }
}

export default StoreProvider;