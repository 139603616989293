import React, { Component } from "react";
import { CreateLote, ModalTracking,ModalEmail } from "../Util";
import { Row, Col, Button, Label, Container } from "reactstrap";
import REACT_PRINT_COMPONENT from "../Datatable/React_print";
import moment from "moment";
import StoreContext from "./../../Components/Store/Context";
import {
  getListUser,
  getLot,
  postLot,
  putLot,
  getTracking,
  postTracking,
  putTracking,
  processLot,
  processLotAll,
  deleteLot,
  getSetting,
  putStatusTracking,
  sendEmail,
  puttrackingEmail,
  service_id,template_id,user_id,usr_name
} from "../Services";
import { SEARCH, PENCIL, LOGO, LOGOUT } from "../SrcImages";
import Datatable from "../Datatable/Index";
import {
  COLUMNS_LOTE_ADMIN,
  COLUMNS_TRACKING_ADMIN,
} from "../Datatable/DatatableColumns";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  margin: 0 auto;
  position: relative;
  top: 10px;
  right: 10px;
`;

class Lote extends Component {
  static contextType = StoreContext
  constructor() {
    super();
    this.state = {
      dataFormLot: [],
      formSetting: {
        set_id: "",
        set_name: "",
        set_price_libra: "",
        set_price_manejo: "",
        set_price_tarjeta: "",
        set_siglas: "",
        set_address: "",
        set_correoheader:"",
        set_correobody:"",
        set_asunto:""
      },
      formLot: {
        lot_id: "",
        lot_desc: "",
        lot_date: new Date().toString(),
        lot_status: "",
      },
      DataClientes: [],
      dataFormTracking: [],
      formTracking: {
        tr_process: "",
        tr_not: "",
        tr_id: "",
        tr_lot: "",
        tra_id: "",
        tr_track: "",
        tr_desc: "",
        tr_price: "",
        tr_user: "",
        tr_type_box: "2",
        tr_libra: "",
        tr_date_delivered: "",
        tr_date_received: moment(new Date()).format("YYYY-MM-DD"),
        tr_status: "",
        usr_code: "",
        // usr_handler:"",
        tr_use_tarjeta: "0",
        tr_local: "1",
        usr_handler: "1",
        usr_price:""
      },
      local_selected: "1",
      loading: false,
      isOpenModalAddLot: false,
      isOpenModalEditLot: false,
      isOpenPanelTracking: false,
      isOpenModalAddTracking: false,
      isOpenModalEditTracking: false,
      msg_error: "",
      isPrinting: false,
      isSendEmail: false,
      isSaving: false,
      isJsonExport: false,
      jsonData: [],
    };
    this.textInput = React.createRef();
    this.onchangeFormLot = this.onchangeFormLot.bind(this);
    this.onchangeFormTracking = this.onchangeFormTracking.bind(this);
    this.toggleSaveLot = this.toggleSaveLot.bind(this);
    this.handleModalAddLot = this.handleModalAddLot.bind(this);
    this.toggleEditLot = this.toggleEditLot.bind(this);
    this.fetchLot = this.fetchLot.bind(this);
    this.handlerSetLot = this.handlerSetLot.bind(this);
    this.handleModalEditLot = this.handleModalEditLot.bind(this);
    this.closePlanelTracking = this.closePlanelTracking.bind(this);
    this.handlerSetTracking = this.handlerSetTracking.bind(this);
    this.toggleEditTracking = this.toggleEditTracking.bind(this);
    this.fetchTracking = this.fetchTracking.bind(this);
    this.handlerModalTracking = this.handlerModalTracking.bind(this);
    this.handleModalEditTracking = this.handleModalEditTracking.bind(this);
    this.setTypeCasillero = this.setTypeCasillero.bind(this);
    this.handlerDeleteLot = this.handlerDeleteLot.bind(this);
    this.togleSelectCliente = this.togleSelectCliente.bind(this);
    this.handlerSetUser = this.handlerSetUser.bind(this);
    this.setTarjeta = this.setTarjeta.bind(this);
    this.toggleEditCarga = this.toggleEditCarga.bind(this);
    this.setLocal = this.setLocal.bind(this);
    this.toggleSaveTracking = this.toggleSaveTracking.bind(this);
    this.rechargeDataTracking = this.rechargeDataTracking.bind(this);
    this.toggleCloseLot = this.toggleCloseLot.bind(this);
    this.closeLot = this.closeLot.bind(this);
    this.handlerModalEditTracking = this.handlerModalEditTracking.bind(this);
    this.togglePrint = this.togglePrint.bind(this);
    this.print = this.print.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleModalUpdateSts = this.handleModalUpdateSts.bind(this);
    this.toggleUpdateTracking = this.toggleUpdateTracking.bind(this);
    this.sendEmailProcess = this.sendEmailProcess.bind(this);
    this.modalJson = this.modalJson.bind(this);
    this.reProcessar = this.reProcessar.bind(this);
  }

  togglePrint(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            <Button color="success" onClick={() => this.print()}>
              Imprimir
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  iPrintBody = () => {
    const props = this.state;
    var libra = 0;
    var price = 0;
    return (
      <React.Fragment>
        <Row className="mb-5" key={new Date().toString()}>
          <Col xs={2}></Col>
          <Col xs={8} className="text-center mb-4">
            <img alt="" className="logo-school-informe" src={LOGO}></img>
          </Col>
          <Col xs={2}></Col>
          <Col xs={1}></Col>
          <Col xs={10} className="text-left mb-3">
            {/* <Label className="font-weight-bold">Fecha : {moment(new Date(props.dateIDairy)).format("YYYY-MM-DD")}</Label> <br></br> */}
            <Label className="font-weight-bold">
              Fecha de impresion:{" "}
              {moment(new Date()).format("YYYY-MM-DD")}
            </Label>{" "}
          </Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={10} className="text-left mb-3">
            {/* <Label className="font-weight-bold">Fecha : {moment(new Date(props.dateIDairy)).format("YYYY-MM-DD")}</Label> <br></br> */}
            <Label className="font-weight-bold">
              Carga #: {props.formLot.lot_id + " " + props.formLot.lot_desc}
            </Label>{" "}
          </Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={10} className="text-center border-print">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center w-300px">ID</th>
                  <th className="text-center w-250px">TRACKING</th>
                  <th className="text-center">CLIENTE</th>
                  <th className="text-center w-300px">CODIGO</th>
                  <th className="text-center w-250px">TARJETA</th>
                  <th className="text-center w-500px">PESO</th>
                  <th className="text-center w-300px">LIBRA</th>
                  <th className="text-center w-250px">PRECIO</th>
                  <th className="text-center w-500px">ENTREGADO</th>
                  <th className="text-center w-500px">RECIBIDO</th>
                </tr>
              </thead>
              <tbody>
                {props.dataFormTracking.map((data, i) => {
                  libra = libra + Number(data.tr_libra);
                  price = price + Number(data.tr_price);
                  return (
                    <tr key={i}>
                      <td className="text-left font-weight-bold">
                        {data.tr_id}
                      </td>
                      <td className="text-center">{data.tr_track}</td>
                      <td className="text-right">{data.usr_name}</td>
                      <td className="text-right">{data.usr_code}</td>
                      <td className="text-right">
                        {data.tr_use_tarjeta === "1" ? "SI" : "NO"}
                      </td>
                      <td className="text-right">
                        {data.tr_type_box === "1" ? "VOLUMEN" : "REAL"}
                      </td>
                      <td className="text-right">{data.tr_libra}</td>
                      <td className="text-right">{data.tr_price}</td>
                      <td className="text-right">{data.tr_date_delivered}</td>
                      <td className="text-right">{data.tr_date_received}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="text-left font-weight-bold">TOTAL</td>
                  <td className="text-center"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="text-right font-weight-bold">{libra}</td>
                  <td className="text-right font-weight-bold">{price}</td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col xs={1}></Col>
        </Row>
      </React.Fragment>
    );
  };

  
  async fetchLot() {
    const response = await getLot();
    this.setState({
      dataFormLot: response,
      isOpenModalAddLot: false,
      isOpenModalEditLot: false,
      loading: false,
      isSaving:false
    });
  }

  async fetchSetting() {
    const response = await getSetting(this.context.usr_id);
    var set_id ="";
    var set_name ="";
    var set_siglas ="";
    var set_price_libra ="";
    var set_address ="";
    var set_price_manejo ="";
    var set_price_tarjeta ="";
    var email_header ="";
    var email_body ="";
    var set_asunto ="";

    if(response.length>0){
     set_id =response[0].set_id;
     set_name =response[0].set_name;
     set_siglas =response[0].set_siglas;
     set_price_libra = response[0].set_price_libra;
     set_address =response[0].set_address;
     set_price_manejo =response[0].set_price_manejo;
     set_price_tarjeta =response[0].set_price_tarjeta;
     email_header =response[0].set_correoheader;
     email_body =response[0].set_correobody;
     set_asunto = response[0].set_asunto;
    }

    this.setState({
      formSetting: {
        set_id: set_id,
        set_name: set_name,
        set_price_libra: set_price_libra,
        set_price_manejo: set_price_manejo,
        set_price_tarjeta: set_price_tarjeta,
        set_siglas: set_siglas,
        set_address: set_address,
        set_correoheader:email_header,
        set_correobody:email_body,
        set_asunto:set_asunto
      },
      loading: false,
      isSaving: false,
    });
  }

  async fetchUser() {
    const response = await getListUser(this.context.usr_id);
    this.setState({
      DataClientes: response,
      loading: false,
    });
  }

  async fetchTracking(lot_id) {
    const response = await getTracking(lot_id);

    this.setState({
      dataFormTracking: response,
      // isOpenModalAddTracking:false,
      isOpenModalEditTracking: false,
      loading: false,
      formTracking: {
        tr_process: "",
        tr_id: "",
        tr_lot: "",
        tra_id: "",
        tr_track: "",
        tr_desc: "",
        tr_price: "",
        tr_user: "",
        tr_type_box: "2",
        tr_libra: "",
        tr_date_delivered: "",
        tr_date_received: moment(new Date()).format("YYYY-MM-DD"),
        tr_status: "",
        usr_code: "",
        usr_handler: "",
        tr_use_tarjeta: "0",
        tr_local: "1",
      },
      isSaving: false,
    });
  }

  toggleUpdateTracking() {
    const props = this.state.formTracking;
    const propsLot = this.state.formLot;
    if (
      propsLot.lot_id.length > 0 &&
      props.tr_track.length > 0 &&
      props.tr_libra.length > 0 &&
      props.tr_price > 0 &&
      props.tr_user.length > 0 &&
      props.tr_use_tarjeta.length > 0 &&
      props.tr_type_box.length > 0
    ) {
      const body = {
        tr_id: props.tr_id,
        tr_track: props.tr_track,
        tr_libra: props.tr_libra,
        tr_price: props.tr_price,
        tr_user: props.tr_user,
        tr_use_tarjeta: props.tr_use_tarjeta,
        tr_type_box: props.tr_type_box,
        // tr_date_received: props.tr_date_received
      };
      putTracking(body);
      this.setState({ isSaving: true });
      setTimeout(() => {
        this.fetchTracking(propsLot.lot_id);
      }, 1000);
    } else {
      this.setState({
        msg_error: "Campos vacios",
      });
    }
  }

  toggleSaveTracking() {
    const props = this.state.formTracking;
    const propsLot = this.state.formLot;
    if (
      propsLot.lot_id.length > 0 &&
      props.tr_track.length > 0 &&
      props.tr_libra.length > 0 &&
      props.tr_price > 0 &&
      props.tr_user.length > 0 &&
      props.tr_use_tarjeta.length > 0 &&
      props.tr_type_box.length > 0
    ) {
      const body = {
        tr_lot: propsLot.lot_id,
        tr_track: props.tr_track,
        tr_libra: props.tr_libra,
        tr_price: props.tr_price,
        tr_user: props.tr_user,
        tr_use_tarjeta: props.tr_use_tarjeta,
        tr_type_box: props.tr_type_box,
        tr_date_received: props.tr_date_received,
        tr_local: props.tr_local,
      };
      postTracking(body);
      this.setState({ isSaving: true });
      setTimeout(() => {
        this.fetchTracking(propsLot.lot_id);
      }, 1000);
    } else {
      this.setState({
        msg_error: "Campos vacios",
      });
    }
  }

  toggleSaveLot() {
    const props = this.state.formLot;
    if (props.lot_desc.length > 0) {
      const body = {
        lot_desc: props.lot_desc,
        lot_date: moment(props.lot_date).format("YYYY-MM-DD"),
      };
      postLot(body);
      this.setState({
        isSaving: true,
      });

      setTimeout(() => {
        this.fetchLot();
      }, 1000);
    } else {
      this.setState({
        msg_error: "Campos vacios",
      });
    }
  }

  toggleEditCarga() {
    const props = this.state.formLot;
    if (props.lot_desc.length > 0) {
      const body = {
        lot_id: props.lot_id,
        lot_desc: props.lot_desc,
        lot_date: moment(props.lot_date).format("YYYY-MM-DD"),
      };
      putLot(body);
      setTimeout(() => {
        this.fetchLot();
      }, 1000);
    } else {
      this.setState({
        msg_error: "Campos vacios",
      });
    }
  }

  onchangeFormLot(e) {
    this.setState({
      formLot: {
        ...this.state.formLot,
        [e.target.name]: e.target.value,
      },
      msg_error: "",
    });
  }

  onchangeFormTracking(e) {

    const props = this.state.formTracking;
    var price_manejo = 0;
    var use_tarjeta = 0;

    if (props.usr_handler === "1") {
      price_manejo = Number(this.state.formSetting.set_price_manejo);
    }
    if (e.target.name === "tr_libra") {
      if (props.tr_use_tarjeta === "1") {
        use_tarjeta = Number(this.state.formSetting.set_price_tarjeta);
      }
      if (e.target.value.length > 0 && props.usr_price !== undefined) {

        this.setState({
          formTracking: {
            ...this.state.formTracking,
            [e.target.name]: e.target.value,
            tr_price:
              Number(Number(props.usr_price) * parseFloat(e.target.value)) +
              price_manejo +
              use_tarjeta,
          },
          msg_error: "",
        });
      }
    } else {
      this.setState({
        formTracking: {
          ...this.state.formTracking,
          [e.target.name]: e.target.value,
        },
        msg_error: "",
      });
    }
  }

  handleModalEditLot = (row) => {
    this.setState((prevState) => ({
      isOpenModalEditLot: !prevState.isOpenModalEditLot,
      formLot: row,
      msg_error: "",
    }));
  };

  handleModalEditTracking = () => {
    this.setState((prevState) => ({
      isOpenModalEditTracking: !prevState.isOpenModalEditTracking,
      formLot: {
        lot_id: "",
        lot_desc: "",
        lot_date: "",
        lot_status: "",
      },
    }));
  };

  handleModalAddLot = () => {
    this.setState((prevState) => ({
      isOpenModalAddLot: !prevState.isOpenModalAddLot,
      formLot: {
        lot_id: "",
        lot_desc: "",
        lot_date: new Date().toString(),
        lot_status: "",
      },
    }));
  };

  rechargeDataTracking() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchTracking(this.state.formLot.lot_id);
    }, 1000);
  }

  rechargeData() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchLot();
      this.fetchUser();
      this.fetchSetting();
    }, 1000);
  }

  handlerSetUser(row) {
    const props = this.state.formTracking;
    var price_manejo = 0;
    var use_tarjeta = 0;
    this.setState({
      formTracking: {
        ...this.state.formTracking,
        tr_user: row.usr_id,
        usr_code: row.usr_code,
        usr_price: row.usr_price,
        usr_handler: row.usr_handler,
      },
    });

    if (row.usr_handler === "1") {
      price_manejo = Number(this.state.formSetting.set_price_manejo);
    }
    if (props.tr_libra !== "") {
      if (props.tr_use_tarjeta === "1") {
        use_tarjeta = Number(this.state.formSetting.set_price_tarjeta);
      }
      if (props.tr_libra.length > 0 && row.usr_price !== undefined) {
        this.setState({
          formTracking: {
            ...this.state.formTracking,
            tr_user: row.usr_id,
            usr_code: row.usr_code,
            usr_name: row.usr_name,
            usr_price:row.usr_price,
            usr_handler:price_manejo,
            tr_price:
              Number(Number(row.usr_price) * parseFloat(props.tr_libra)) +
              price_manejo +
              use_tarjeta,
          },
          msg_error: "",
        });
      }
    }
  }

  handleModalUpdateSts(row) {
    const body = {
      tr_id: row.tr_id,
    };
    putStatusTracking(body);
    setTimeout(() => {
      this.fetchTracking(this.state.formLot.lot_id);
    }, 1000);
  }

  handlerSetLot(row) {
    this.setState((prevState) => ({
      isOpenPanelTracking: !prevState.isOpenPanelTracking,
      formLot: row,
    }));
    this.fetchTracking(row.lot_id);
  }

  handlerDeleteLot(row) {
    const body = {
      lot_id: row.lot_id,
    };
    deleteLot(body);
    setTimeout(() => {
      this.fetchLot();
    }, 1000);
  }

  closeLot(row) {
    this.fetchTracking(row.lot_id);
    setTimeout(() => {
      this.sendEmailProcessDelay();
    }, 2000);



   setTimeout(() => {
    const body = {
      lot_id: row.lot_id,
    };
   processLot(body);
  }, 1000);
    setTimeout(() => {
      this.fetchLot();
    }, 3000);
    this.setState({ isSendEmail: true,formLot:{
      lot_id:row.lot_id,lot_desc:row.lot_desc,lot_date:row.lot_date,lot_status:row.lot_status
    } });

  
  }

  reProcessar() {

    processLotAll();
    setTimeout(() => {
      this.fetchLot();
    }, 1000);
  }

  timer = ms => new Promise(res => setTimeout(res, ms))

 async sendEmailProcessDelay() {
    var user = {};
    const props2 = this.state.dataFormTracking;
    props2.map((data, i) => {
      if (user[data.tr_user]) {
        user[data.tr_user].push({
          usr_name: data.usr_name,
          usr_email: data.usr_email,
          usr_code: data.usr_code,
          tr_track: data.tr_track,
          tr_libra: data.tr_libra,
          tr_use_tarjeta: data.tr_use_tarjeta,
          usr_handler: data.usr_handler,
          tr_price: data.tr_price,
          usr_not: data.usr_not,
          tr_not: data.tr_not,
          tr_user:data.tr_user
        });
      } else {
        user = {
          ...user,
          [data.tr_user]: [
            {
              usr_name: data.usr_name,
              usr_email: data.usr_email,
              usr_code: data.usr_code,
              tr_track: data.tr_track,
              tr_libra: data.tr_libra,
              tr_use_tarjeta: data.tr_use_tarjeta,
              usr_handler: data.usr_handler,
              tr_price: data.tr_price,
              usr_not: data.usr_not,
              tr_not: data.tr_not,
              tr_user:data.tr_user
            },
          ],
        };
      }
      return null
    });
    for (var i = 0; i < Object.keys(user).length; i++) {
      await this.timer(3000);
      this.sendEmailProcess(user[Object.keys(user)[i]]);
    }
     this.setState({ isSendEmail: false, dataFormTracking: [] });
  }


  sendEmailProcess(user){

    const props = this.state;
    var template_params1 = {};
    var hash = {};
    var total_libras = 0;
    var cargo_total = 0;
    var total = 0;
    user.map( async function (key, i) {
          if (key.usr_not === "1" ) {
            template_params1 = {
              from_name: usr_name,
              to_name: key.usr_name,
              to_email: key.usr_email,
              usr_code: key.usr_code,
              date_r: moment(new Date()).format("YYYY-MM-DD"),
              email_header:props.formSetting.set_correoheader,
              email_body:props.formSetting.set_correobody,
              set_asunto:props.formSetting.set_asunto,
            };
          }
      })

      user.map((data, i) => {
        if (data.tr_not === "0") {
          var tarjeta = 0;
          var manejo = 0;
          if (data.tr_use_tarjeta === "1") {
            tarjeta = Number(props.formSetting.set_price_tarjeta);
          }
          if (data.usr_handler === "1") {
            manejo = Number(props.formSetting.set_price_manejo);
          }
          total_libras = total_libras + Number(data.tr_libra);
          cargo_total = cargo_total + Number(manejo) + Number(tarjeta);
          total = total + Number(data.tr_price);
          hash = {
            ...hash,
            id: i + 1,
            track_desc: data.tr_track,
            track_lb: data.tr_libra,
            track_precio: Number(data.tr_price - tarjeta - manejo).toFixed(2),
            track_cargo: (Number(manejo) + Number(tarjeta)).toFixed(2),
            track_total: Number(data.tr_price).toFixed(2),
          };
          delete Object.assign(hash, {
            ["id" + (i + 1).toString()]: hash["id"],
          })["id"];
          delete Object.assign(hash, {
            ["track_desc" + (i + 1).toString()]: hash["track_desc"],
          })["track_desc"];
          delete Object.assign(hash, {
            ["track_lb" + (i + 1).toString()]: hash["track_lb"],
          })["track_lb"];
          delete Object.assign(hash, {
            ["track_precio" + (i + 1).toString()]: hash["track_precio"],
          })["track_precio"];
          delete Object.assign(hash, {
            ["track_cargo" + (i + 1).toString()]: hash["track_cargo"],
          })["track_cargo"];
          delete Object.assign(hash, {
            ["track_total" + (i + 1).toString()]: hash["track_total"],
          })["track_total"];
        }
        return null
      });
      if (Object.keys(hash).length > 0) {
        var dataEmail = {
          service_id: service_id,
          template_id: template_id,
          user_id: user_id,
          template_params: {
            ...template_params1,
            ...hash,
            qty: user.length,
            total_libras: total_libras,
            cargo_total: cargo_total.toFixed(2),
            total: total.toFixed(2)
          },
        };

        sendEmail(dataEmail)
        const body = {
          tr_lot: props.formLot.lot_id,
          tr_user:user[0].tr_user
        };

       puttrackingEmail(body);
      }
     }

  

  modalJson() {
    this.setState((prevState) => ({
      isJsonExport: !prevState.isJsonExport,
    }));
  }

  closePlanelTracking() {
    this.setState((prevState) => ({
      isOpenPanelTracking: !prevState.isOpenPanelTracking,
    }));
    setTimeout(() => {
      this.fetchLot();
    }, 1000);
  }

  handlerModalEditTracking() {
    this.setState((prevState) => ({
      isOpenModalEditTracking: !prevState.isOpenModalEditTracking,
      formTracking: {
        tr_id: "",
        tr_lot: "",
        tra_id: "",
        tr_track: "",
        tr_desc: "",
        tr_price: "",
        tr_user: "",
        tr_type_box: "2",
        tr_libra: "",
        tr_date_delivered: "",
        tr_date_received: moment(new Date()).format("YYYY-MM-DD"),
        tr_status: "",
        usr_code: "",
        tr_use_tarjeta: "0",
        tr_local: "1",
      },
    }));
  }

  handlerModalTracking() {
    this.setState((prevState) => ({
      isOpenModalAddTracking: !prevState.isOpenModalAddTracking,
      formTracking: {
        tr_id: "",
        tr_lot: "",
        tra_id: "",
        tr_track: "",
        tr_desc: "",
        tr_price: "",
        tr_user: "",
        tr_type_box: "2",
        tr_libra: "",
        tr_date_delivered: "",
        tr_date_received: moment(new Date()).format("YYYY-MM-DD"),
        tr_status: "",
        usr_code: "",
        tr_use_tarjeta: "0",
        tr_local: "1",
      },
      msg_error: "",
    }));
  }

  toggleEditLot(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <img
              className="img-status img-search"
              onClick={() => this.handlerSetLot(row)}
              src={SEARCH}
              alt=""
            />
            <img
              className="img-status img-search"
              onClick={() => this.handleModalEditLot(row)}
              src={PENCIL}
              alt=""
            />
                                    <img
              className="img-status img-search"
              onClick={() => this.handlerDeleteLot(row)}
              src={LOGOUT}
              alt=""
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  toggleCloseLot(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            {row.lot_status === "0" ? null : (
              <Button color="success" onClick={() => this.closeLot(row)}>
                Procesar carga
              </Button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.fetchLot();
    this.fetchUser();
    this.fetchSetting();
  }

  setLocal({ local_selected }) {
    this.setState({
      local_selected,
    });
  }

  setLotStatus({ lot_sts }) {
    this.setState({
      formLot: {
        ...this.state.formLot,
        lot_sts,
      },
    });
  }

  handlerSetTracking(row) {
    this.setState((prevState) => ({
      isOpenModalEditTracking: !prevState.isOpenModalEditTracking,
      formTracking: row,
    }));
  }

  setTarjeta({ tr_use_tarjeta }) {
    const props = this.state;
    var set_price_manejo = 0;
    if (tr_use_tarjeta === "1") {
      if (props.formTracking.usr_handler === "1") {
        set_price_manejo = props.formSetting.set_price_manejo;
      }

      if (props.formTracking.tr_libra.length > 0) {
        this.setState({
          formTracking: {
            ...this.state.formTracking,
            tr_use_tarjeta,
            tr_price:
              Number(props.formTracking.usr_price) *
                parseFloat(this.state.formTracking.tr_libra) +
              Number(props.formSetting.set_price_tarjeta) +
              Number(set_price_manejo),
          },
        });
      }
    } else {
      if (this.state.formTracking.tr_libra === "") {
        this.setState({
          formTracking: {
            ...this.state.formTracking,
            tr_use_tarjeta,
            tr_price: "",
          },
        });
      } else {
        if (props.formTracking.usr_handler === "1") {
          set_price_manejo = props.formSetting.set_price_manejo;
        }
        this.setState({
          formTracking: {
            ...this.state.formTracking,
            tr_use_tarjeta,
            tr_price:
              Number(props.formTracking.usr_price) *
                parseFloat(this.state.formTracking.tr_libra) +
              Number(set_price_manejo),
          },
        });
      }
    }
  }

  setTypeCasillero({ tr_type_box }) {
    this.setState({
      formTracking: {
        ...this.state.formTracking,
        tr_type_box,
      },
    });
  }

  print() {
    this.setState({ isPrinting: true });
  }

  toggleEditTracking(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <img
              className="img-status img-search"
              onClick={() => this.handleModalUpdateSts(row)}
              src={LOGOUT}
              alt=""
            />
            <img
              className="img-status img-search"
              onClick={() => this.handlerSetTracking(row)}
              src={PENCIL}
              alt=""
            />

          </Col>
        </Row>
      </React.Fragment>
    );
  }

  handleGoBack() {
    this.setState({ isPrinting: false });
  }

  togleSelectCliente(row, isSelect) {
    if (isSelect===true){
      this.handlerSetUser(row);
    }
  }

  render() {
    const props = this.state;
    // if (props.isSendEmail && props.dataFormTracking.length > 0) {
    //   this.sendEmailProcessDelay();
    // }
    const formatPrice = (cell, row, extra) => {
      let display = 0;

      display = row;
      return <div>{parseFloat(display).toFixed(2)}€</div>;
    };
    return (
      <Container className="div-container">
      <React.Fragment>
{props.isSendEmail &&   <ModalEmail
            // toggleModal={this.handleModalAddLot}
            title="Enviando correos"
            isOpenModal={props.isSendEmail}
          />}
      
        {props.isOpenModalAddLot && (
          <CreateLote
            toggleSaveLot={this.toggleSaveLot}
            onchangeFormLot={this.onchangeFormLot}
            dataFormLot={props.formLot}
            toggleModal={this.handleModalAddLot}
            title="Agregar carga"
            isOpenModal={props.isOpenModalAddLot}
            setLotStatus={this.setLotStatus}
            msg_error={props.msg_error}
            isSaving={props.isSaving}
          />
        )}

        {props.isOpenModalEditLot && (
          <CreateLote
            toggleSaveLot={this.toggleEditCarga}
            onchangeFormLot={this.onchangeFormLot}
            dataFormLot={props.formLot}
            toggleModal={this.handleModalEditLot}
            title="Editar carga"
            isOpenModal={props.isOpenModalEditLot}
            msg_error={props.msg_error}
            isSaving={props.isSaving}
          />
        )}

        {props.isOpenModalAddTracking && (
          <ModalTracking
            setTarjeta={this.setTarjeta}
            DataClientes={props.DataClientes}
            togleSelectCliente={this.togleSelectCliente}
            toggleSaveTracking={this.toggleSaveTracking}
            onchangeFormTracking={this.onchangeFormTracking}
            dataFormTracking={props.formTracking}
            toggleModal={this.handlerModalTracking}
            title="Agregar tracking"
            isOpenModal={props.isOpenModalAddTracking}
            setTypeCasillero={this.setTypeCasillero}
            type_local={props.local_selected}
            msg_error={props.msg_error}
            isSaving={props.isSaving}
          />
        )}

        {props.isOpenModalEditTracking && (
          <ModalTracking
            setTarjeta={this.setTarjeta}
            DataClientes={props.DataClientes}
            togleSelectCliente={this.togleSelectCliente}
            toggleSaveTracking={this.toggleUpdateTracking}
            onchangeFormTracking={this.onchangeFormTracking}
            dataFormTracking={props.formTracking}
            toggleModal={this.handlerModalEditTracking}
            title="Editar tracking"
            isOpenModal={props.isOpenModalEditTracking}
            setTypeCasillero={this.setTypeCasillero}
            type_local={props.local_selected}
            msg_error={props.msg_error}
            isSaving={props.isSaving}
          />
        )}

        {/* {props.isJsonExport && (
          <ModalTracking
          toggleSaveLot,
          onchangeFormJson,
          json={props.jsonData}
          toggleModal={this.modalJson}
          title={"ImportJSON"}
          isOpenModal={props.isJsonExport}
          msg_error={props.msg_error}
          />
        )} */}
        <Row>
          {props.isOpenPanelTracking ? (
            props.isPrinting ? (
              <React.Fragment>
                <Row className="mt-3">
                  <Col xs={12} className="text-right">
                    <Button color="primary" onClick={() => this.handleGoBack()}>
                      Cerrar
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <REACT_PRINT_COMPONENT body={this.iPrintBody()} />
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Col xs={12} className="text-center mt-3">
                  <h4>
                    {" "}
                    #{props.formLot.lot_id + " " + props.formLot.lot_desc}
                  </h4>
                </Col>
                <Col xs={12} className="text-right mt-1">
                  <ClipLoader
                    css={override}
                    size={23}
                    color={"#36D7B7"}
                    loading={this.state.loading}
                  />
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => this.rechargeDataTracking()}
                  >
                    Recargar
                  </Button>
                  <Button
                    color="danger"
                    className="mr-2"
                    onClick={() => this.print()}
                  >
                    PDF
                  </Button>
                  <Button
                    color="secondary"
                    className="mr-2"
                    onClick={() => this.closePlanelTracking()}
                  >
                    Cerrar
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.handlerModalTracking()}
                  >
                    Agregar
                  </Button>
                </Col>
                <Col xs={12} className="text-center scroll-ac">
                  <Datatable
                    data={props.dataFormTracking}
                    columns={COLUMNS_TRACKING_ADMIN(
                      this.toggleEditTracking,
                      formatPrice
                    )}
                    keyProp="tr_id"
                    exportD={false}
                    searchs={true}
                    selectRows={false}
                    RadioBtn={false}
                    optionSelectRow={null}
                  />
                </Col>

              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              {" "}
              <Col xs={12} className="text-center">
                <h4> Carga</h4>
              </Col>
              <Col xs={12} className="text-right mt-1">
                <ClipLoader
                  css={override}
                  size={23}
                  color={"#36D7B7"}
                  loading={this.state.loading}
                />
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => this.rechargeData()}
                >
                  Recargar
                </Button>
                <Button
                  color="primary"
                  onClick={() => this.handleModalAddLot()}
                >
                  Agregar
                </Button>
                <Button
                  color="success"
                  className="ml-2"
                  onClick={() => this.reProcessar()}
                >
                  reProcessar
                </Button>
                
              </Col>
              <Col xs={12} className="scroll-ac">
                <Datatable
                  data={props.dataFormLot}
                  columns={COLUMNS_LOTE_ADMIN(
                    this.toggleEditLot,
                    this.toggleCloseLot
                  )}
                  keyProp="lot_id"
                  exportD={false}
                  searchs={true}
                  selectRows={false}
                  RadioBtn={false}
                  optionSelectRow={null}
                />
              </Col>
            </React.Fragment>
          )}
        </Row>
      </React.Fragment>
      </Container>
    );
  }
}

export default Lote;
