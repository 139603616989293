import React, { Component } from "react";
import { Row, Col, Button, Label, Container } from "reactstrap";
import {
  getOrders,
  getOrdersSinRecibir,
  putDeliveredOrder,
  postOrder,
  getListUser,
  putOrder,
  putStatusOrder
} from "./../Services";
import moment from "moment";
import StoreContext from "./../../Components/Store/Context";
import { OK, PENCIL,LOGOUT } from "../SrcImages";
import Datatable from "../Datatable/Index";
import { ModalTrackingOrder } from "./../Util";
import { COLUMNS_ORDERS } from "../Datatable/DatatableColumns";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  margin: 0 auto;
  position: relative;
  top: 35px;
  right: 10px;
`;

class Orders extends Component {
    static contextType = StoreContext
  constructor() {
    super();
    this.state = {
      DataClientes: [],
      dataOrder: [],
      formOrder: {
        ord_id: "",
        ord_person: "",
        ord_desc: "",
        ord_track:"",
        ord_link: "",
        ord_precio: "",
        ord_date_order: "",
        ord_date_deliverd: "",
        ord_status: "",
        usr_code: "",
        usr_name: "",
        usr_creditcard:"",
        usr_code:"",
        tr_price:"",
        tr_delivered:"",
        tr_date_received:""
      },
      loading: false,
      msg_error: "",
      isOpenModalTracking: false,
      isOpenModalTrackingEdit: false,
      isOpenSinRecibir:false
    };

    this.fetchOrder = this.fetchOrder.bind(this);
    this.handlerPutOrders = this.handlerPutOrders.bind(this);
    this.rechargeData = this.rechargeData.bind(this);
    this.searchByCode = this.searchByCode.bind(this);
    this.openModalAddTracking = this.openModalAddTracking.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.togleSelectCliente = this.togleSelectCliente.bind(this);
    this.handleModalAddTracking = this.handleModalAddTracking.bind(this);
    this.onchageFormOrders = this.onchageFormOrders.bind(this);
    this.toggleDelivered = this.toggleDelivered.bind(this);
    this.handlerSaveOrders = this.handlerSaveOrders.bind(this);
    this.togleSelectTracking = this.togleSelectTracking.bind(this);
    this.handlerSetTracking = this.handlerSetTracking.bind(this);
    this.handlerPutDelivered = this.handlerPutDelivered.bind(this);
    this.deleteOrder = this.deleteOrder.bind(this);
    this.sinRecibir = this.sinRecibir.bind(this);
    this.fetchOrderSinRecibir = this.fetchOrderSinRecibir.bind(this);
  }
  
  async fetchOrderSinRecibir() {
    const response = await getOrdersSinRecibir();
    this.setState({
      dataOrder: response,
      loading: false,
      isOpenModalTracking: false,
      isOpenModalTrackingEdit: false,
    });
  }

  async fetchOrder() {
    const response = await getOrders();
    this.setState({
      dataOrder: response,
      loading: false,
      isOpenModalTracking: false,
      isOpenModalTrackingEdit: false,
      isOpenSinRecibir:false
    });
  }

  handlerSaveOrders() {
    const props = this.state.formOrder;
    if(props.ord_desc.length>0 && props.ord_person.length>0){
        const body = {
            ord_person: props.ord_person,
            ord_desc: props.ord_desc,
            ord_link: props.ord_link,
            ord_precio: props.ord_precio,
            ord_track:props.ord_track,
            ord_date_order: props.ord_date_order,
            ord_date_deliverd: props.ord_date_deliverd,
          };
          postOrder(body);
          setTimeout(() => {
            this.fetchOrder();
          }, 1000);
    }
    else{
        this.setState({msg_error:"Campos vacios"})
    }

  }

  handlerSetTracking(row) {
    this.setState({
      formOrder: row,
      isOpenModalTrackingEdit: true,
    });
  }

  handlerPutOrders() {
    const props = this.state.formOrder;
    const body = {
      ord_id: props.ord_id,
      ord_person: props.ord_person,
      ord_desc: props.ord_desc,
      ord_track:props.ord_track,
      ord_link: props.ord_link,
      ord_precio: props.ord_precio,
    };
    putOrder(body);
    if(props.isOpenSinRecibir){
      setTimeout(() => {
        this.fetchOrderSinRecibir();
      }, 1000);
    }
    else{
      setTimeout(() => {
        this.fetchOrder();
      }, 1000);
    }

  }

  openModalAddTracking() {
    this.setState((prevState) => ({
      isOpenModalTracking: !prevState.isOpenModalTracking,
      formOrder: {
        ord_id: "",
        ord_person: "",
        ord_desc: "",
        ord_link: "",
        ord_track:"",
        ord_precio: "",
        ord_date_order: "",
        ord_date_deliverd: "",
        ord_status: "",
        usr_code: "",
        usr_name: "",
      },
      msg_error:""
    }));
  }

  searchByCode() {
    this.fetchTrackingByCode();
  }

  async fetchUser() {
    const response = await getListUser(this.context.usr_id);
    this.setState({
      DataClientes: response,
      loading: false,
    });
  }

  componentDidMount() {
    this.fetchOrder();
    this.fetchUser();
  }

  handlerSetUser(row) {
    this.setState({
      formOrder: {
        ...this.state.formOrder,
        ord_person: row.usr_id,
        usr_code: row.usr_code,
        usr_name: row.usr_name,
      },
    });
  }

  togleSelectTracking(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <img
              className="img-status img-search"
              onClick={() => this.handlerSetTracking(row)}
              src={PENCIL}
              alt=""
            />
                                    <img
              className="img-status img-search"
              onClick={() => this.deleteOrder(row)}
              src={LOGOUT}
              alt=""
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  deleteOrder(row) {
    const body ={
      ord_id:row.ord_id
    }
    putStatusOrder(body);
          setTimeout(() => {
            this.fetchOrder()
    }, 1000);
  }

  togleSelectCliente(row, isSelect) {
    if (isSelect===true){
      this.handlerSetUser(row);
    }
  }

  handleModalAddTracking = () => {
    this.setState((prevState) => ({
      isOpenModalTracking: !prevState.isOpenModalTracking,
      formOrder: {
        ord_id: "",
        ord_person: "",
        ord_desc: "",
        ord_link: "",
        ord_track:"",
        ord_precio: "",
        ord_date_order: "",
        ord_date_deliverd: "",
        ord_status: "",
        usr_code: "",
        usr_name: "",
      },
      msg_error:"",isOpenSinRecibir:false
    }));
  };

  handleModalEditTracking = () => {
    this.setState((prevState) => ({
      isOpenModalTrackingEdit: !prevState.isOpenModalTrackingEdit,
      msg_error:""
    }));
  };
  
  sinRecibir() {
    this.setState({ loading: true,isOpenSinRecibir:true });
    setTimeout(() => {
      this.fetchOrderSinRecibir();
    }, 1000);
  }

  rechargeData() {
    this.setState({ loading: true,isOpenSinRecibir:false });
    setTimeout(() => {
      this.fetchOrder();
    }, 1000);
  }

  onchageFormOrders(e) {
    this.setState({
      formOrder: {
        ...this.state.formOrder,
        [e.target.name]: e.target.value,
      },
    });
  }

  handlerPutDelivered(row) {
    const props = this.state;
    const body = {
      ord_date_deliverd: moment(new Date()).format("YYYY-MM-DD"),
      ord_id: row.ord_id,
    };
    putDeliveredOrder(body);

    if(props.isOpenSinRecibir){
      setTimeout(() => {
        this.fetchOrderSinRecibir();
      }, 2000);
   
    }
    else{
      setTimeout(() => {
        this.fetchOrder();
      }, 2000);
    }

  }
  
  toggleLink(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            {row.ord_link.length>0?
              <a href={row.ord_link} target="_blank" rel="noopener noreferrer"> <b>LINK</b></a> 
          :null}
         
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  toggleDelivered(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            {row.tr_delivered === "1" ?<Label className="label-black-bold">Entregado</Label>  : row.tr_date_received !== null?
            <Label className="label-green-bold">Disponible</Label>
            :
          <Label className="label-blue-bold">En Transito</Label>
            }
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    document.body.classList.add('body-class');
    const props = this.state;
    return (
      <Container className="div-container">
 <React.Fragment>
        {props.isOpenModalTracking && (
          <ModalTrackingOrder
            DataClientes={props.DataClientes}
            togleSelectCliente={this.togleSelectCliente}
            toggleSaveTracking={this.handlerSaveOrders}
            onchangeFormOrder={this.onchageFormOrders}
            dataFormOrder={props.formOrder}
            toggleModal={this.handleModalAddTracking}
            title="Agregar nuevo pedido"
            isOpenModal={props.isOpenModalTracking}
            msg_error={props.msg_error}
          />
        )}

        {props.isOpenModalTrackingEdit && (
          <ModalTrackingOrder
            DataClientes={props.DataClientes}
            togleSelectCliente={this.togleSelectCliente}
            toggleSaveTracking={this.handlerPutOrders}
            onchangeFormOrder={this.onchageFormOrders}
            dataFormOrder={props.formOrder}
            toggleModal={this.handleModalEditTracking}
            title="Editar pedido"
            isOpenModal={props.isOpenModalTrackingEdit}
            msg_error={props.msg_error}
          />
        )}
        <Row>
          <Col xs={12} className="text-center mt-3">
            <h4> Pedidos</h4>
          </Col>


          <Col xs={12} className="text-right mb-5">
          <Button
              color="info"
              className="mr-2 btn-buscar-2"
              onClick={() => this.sinRecibir()}
            >
              Sin recibir
            </Button>
            <ClipLoader
              css={override}
              size={23}
              color={"#36D7B7"}
              loading={this.state.loading}
            />
            <Button
              color="info"
              className="mr-2 btn-buscar-2"
              onClick={() => this.rechargeData()}
            >
              Recargar
            </Button>
            <Button
              color="primary"
              className="mr-2 btn-buscar-2"
              onClick={() => this.openModalAddTracking()}
            >
              Agregar
            </Button>
          </Col>

        </Row>
        <Row>
          <Col xs={12} className="scroll-ac">
            <Datatable
              data={props.dataOrder}
              columns={COLUMNS_ORDERS(this.togleSelectTracking,this.toggleDelivered,this.toggleLink)}
              keyProp="ord_id"
              exportD={false}
              searchs={true}
              selectRows={false}
              RadioBtn={false}
              optionSelectRow={null}
            />
          </Col>
        </Row>
      </React.Fragment>
      </Container>
     
    );
  }
}

export default Orders;
