import React, { Component } from "react";
import { Row, Col, Label } from "reactstrap";
import {MESSAGE_ICON} from "./../SrcImages";
import StoreContext from "./../../Components/Store/Context";
import {
  putStatusMsg,
  getPersonList,
  getMessages,
  postMessages
} from "./../Services";
import * as Scroll from "react-scroll";
import {
  MESSAGE_CHAT_CONTAINER
} from "./../Util";
import "./../../assets/css/Chat.css";
import moment from "moment";
import { Container } from "@material-ui/core";
let scroll = Scroll.animateScroll;


class Chat extends Component {
  static contextType = StoreContext
  constructor() {
    super();
    this.state = {
      dataPrint: [],
      dataPerson: [],
      personForm: {
        usr_id: "",
        usr_name: "",
        usr_code:""
      },
      dataPrintDetail: [],
      msg_error: "",
      dataMessages: [],
      loading: false,
      formMessages: {
        msg_remitente: "",
        msg_destinatario: "",
        msg_desc: "",
        msg_time: "",
      },
      isOpenMessage: false,
    };
    this.rechargeData2 = this.rechargeData2.bind(this);
    this.rechargeData = this.rechargeData.bind(this);
    this.fetchtPersonList = this.fetchtPersonList.bind(
      this
    );
    this.handlerSendMessage = this.handlerSendMessage.bind(this);
    this.fetchtMessages = this.fetchtMessages.bind(this);
    this.onChangeMessages = this.onChangeMessages.bind(this);
    this.setPerson = this.setPerson.bind(this);
    this.handleOpenMessage = this.handleOpenMessage.bind(this);
    this.toggleSelectUser = this.toggleSelectUser.bind(this);
    this.putStatusMsg = this.putStatusMsg.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  rechargeData2() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchtPersonList();
    }, 1000);
  }

 putStatusMsg(usr_id) {
    const body ={
      usr_id:"1_"+usr_id
    }
    putStatusMsg(body);
      this.fetchtMessages(usr_id);
      setTimeout(() => {
        this.fetchtPersonList();
      }, 1000);
  }

  async fetchtPersonList() {
    var response;
    response = await getPersonList(this.context.usr_id);
    this.setState({ dataPerson: response, loading: false });
  }

  async fetchtMessages(usr_id) {
    const response = await getMessages("1_" + usr_id);
    this.setState({ dataMessages: response, loading: false });
    scroll.scrollToBottom({
      containerId: "scrollContainer",
    });
  }

  onChangeMessages(e) {
    this.setState({
      formMessages: {
        ...this.state.formMessages,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleOpenMessage() {
    this.setState((prevState) => ({
      isOpenMessage: !prevState.isOpenMessage,
      isOpenForum: false,
    }));
  }

  rechargeData() {
    const props = this.state.personForm;
    this.setState({ loading: true });
    if (this.state.isOpenForum) {
      setTimeout(() => {
        this.getMessagesForum({ usr_id: props.usr_id });
      }, 1000);
    } else {
      setTimeout(() => {
        this.fetchtMessages(props.usr_id);
      }, 1000);
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.handlerSendMessage()
    }
  }

  handlerSendMessage() {
    const props = this.state.formMessages;
    const propsdestinatario = this.state.personForm;

    if (this.state.isOpenForum) {
      // const body = {
      //   usr_id: this.context.usr_id,
      //   per_teacher: propsdestinatario.usr_id,
      //   msg_person: "1",
      //   msg_desc: props.msg_desc,
      //   msg_time: moment(new Date()).format("YYYY-MM-DD"),
      //   msg_foro: "1",
      // };
      // postMessagesForumStudent(body);
      setTimeout(() => {
        this.getMessagesForum({ usr_id: propsdestinatario.usr_id });
      }, 1000);
    } else {
      const body = {
        msg_num: this.context.usr_id+"_" + propsdestinatario.usr_id,
        msg_person: this.context.usr_id,
        msg_destinatario: propsdestinatario.usr_id,
        msg_desc: props.msg_desc,
        msg_time: moment(new Date()).format("YYYY-MM-DD"),
        msg_foro: "0",
        msg_grade: "0",
      };
      postMessages(body);
      setTimeout(() => {
        this.fetchtMessages(propsdestinatario.usr_id);
      }, 1000);
    }

    this.setState({
      loading: true,
      formMessages: {
        msg_remitente: "",
        msg_destinatario: "",
        msg_desc: "",
        msg_time: "",
      },
    });
  }

  setPerson(row) {
    this.setState((prevState) => ({
      personForm: {
        usr_id:row.usr_id,
        usr_name:row.usr_name,
      },
      isOpenMessage: true,
    }));
    this.putStatusMsg(row.usr_id)
  }

  toggleSelectUser(cell, row) {
    return (
      <React.Fragment>
        <Row onClick={() => this.setPerson(row)}>
          <Col xs={2} className="d-flex"  >
            <img
              className="img-status img-search"
              onClick={() => this.setPerson(row)}
              src={MESSAGE_ICON}
              alt=""
            />
            </Col>
            <Col xs={8}>
              <Label  onClick={() => this.setPerson(row)}>
              {
              row.usr_name
            }
              </Label>
           
            </Col>
            <Col xs={2}>
              {
                row.count !== "0"?             <Label  onClick={() => this.setPerson(row)}
                className="red-label"
               
              >
                {
                  row.count
                }
              </Label>
              :null

              }
              </Col>
        </Row>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.fetchtPersonList();
  }

  render() {
    document.body.classList.add('body-class');
    const props = this.state;
    return (
      <Container className="div-container">
  <React.Fragment>
        <Row>
          <Col xs={12} className="mt-3">
            <MESSAGE_CHAT_CONTAINER
              usr_id={this.context.usr_id}
              props={props}
              handlerSendMessage={this.handlerSendMessage}
              onChangeMessage={this.onChangeMessages}
              setPerson={this.toggleSelectUser}
              isOpenMessage={props.isOpenMessage}
              handlerGoback={this.handleOpenMessage}
              reload={this.rechargeData}
              reload2 ={this.rechargeData2}
              handleKeyPress={this.handleKeyPress}
            />
          </Col>
        </Row>
      </React.Fragment>
      </Container>
    
    );
  }
}

export default Chat;
