import React, { Component } from "react";
import { Row, Col, FormGroup, Form, Label, Input, Button, Container } from "reactstrap";
import { getReport, getReportMensual } from "./../Services";
import fs from 'fs';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Datatable from "../Datatable/Index";
import { COLUMNS_REPORT_MENSUAL } from "../Datatable/DatatableColumns";
const { EscPos } = require("@tillpos/xml-escpos-helper");
const connectToPrinter = require("./connectToPrinter");
// const fs = require("fs");

const override = css`
  margin: 0 auto;
  position: relative;
  top: 35px;
  right: 10px;
`;

class Home extends Component {
  constructor() {
    super();
    this.state = {
      dataReport: [],
      dataReportMonth: [],
      dateIDairy: new Date(),
      loading: false,
      msg_error: "",
    };

    this.fetchReport = this.fetchReport.bind(this);
    this.onChangeDatePickerMonth = this.onChangeDatePickerMonth.bind(this);
    this.recargar = this.recargar.bind(this);
    this.fetchReportMensual = this.fetchReportMensual.bind(this);
  }

  async fetchReportMensual() {
    const props = this.state;
    const response = await getReportMensual(moment(props.dateIDairy).format("YYYY"), moment(props.dateIDairy).format("MM"));
    this.setState({
      dataReportMonth: response,
      loading: false,
    });
  }

  async fetchReport() {
    const props = this.state;
    const response = await getReport(moment(props.dateIDairy).format("YYYY"), moment(props.dateIDairy).format("MM"));
    this.setState({
      dataReport: response[0],
      loading: false,
    });
  }

  componentDidMount() {
    this.fetchReport();
  }

  onChangeDatePickerMonth(date) {
    this.setState({
      dateIDairy: date
    });
    setTimeout(() => {
      this.fetchReport();
    }, 1000);
  }

  recargar() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchReport();
      this.fetchReportMensual();
    }, 1000);
  }

 

  render() {
    /**
 *
 * @params template - the xml template
 * @params data - the dynamic data which ever to be printed
 *
 * @returns bytes - buffers stream
 */
const generateBuffer = (template, data) => {
  // Will add implementation here to generate buffer
  return EscPos.getBufferFromTemplate(template, data);
};

/**
 *
 * @params host - printer IP address
 * @params port - printer port
 * @params message - the buffer stream generated from `generateBuffer` function
 *
 * @return void
 */
const sendMessageToPrinter = async (host, port, message) => {
  console.log("mm")
  try {
    await connectToPrinter(host, port, message);
  } catch (err) {
    console.log("some error", err);
  }
};

/**
 * Entry function
 */
const testPrint = async () => {
  // const template = fs.readFileSync("./sample.xml", { encoding: "utf8" });

  const PRINTER = {
    device_name: "RONGTA",
    host: "192.168.0.9",
    port: 9100,
  };
  const sampleInputData = {
    title: "Hello World!",
    date: "07-08-2021",
  };
  // const message = generateBuffer("template", sampleInputData);
  await sendMessageToPrinter(PRINTER.host, PRINTER.port, "message");
};

testPrint();
    const props = this.state;
    return (
      <React.Fragment>
        <Container className="div-container">
          <Row>
            <Col xs={12} className="text-center">
              <h4>Reporte mensual</h4>
            </Col>
            <Col xs={7} className="text-left">
              <FormGroup>
                <Label className="font-weight-bold">Mes y año:</Label>
                <br></br>
                <DatePicker
                  selected={new Date(props.dateIDairy)}
                  onChange={this.onChangeDatePickerMonth}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  dropdownMode="select"
                  className="form-control w-100"
                />
              </FormGroup>{" "}

            </Col>
            <Col xs={5} className="text-left">
              <ClipLoader
                css={override}
                size={23}
                color={"#36D7B7"}
                loading={this.state.loading}
              />
              <Button
                color="success"
                className=" btn-buscar-2 bg-image-"
                onClick={() => this.recargar()}
              >
                Buscar
              </Button>
            </Col>
            <Col xs={12}>
              <Form>
                <FormGroup>
                  <Label className="font-weight-bold" for="name">Libras acumuladas</Label>
                  <Input
                    type="text"
                    name="tr_libra"
                    defaultValue={props.dataReport.tr_libra}
                    id="tr_libra"
                    placeholder="Total de libras"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="font-weight-bold" for="name">Usuarios nuevos</Label>
                  <Input
                    type="text"
                    name="new_user"
                    defaultValue={props.dataReport.new_user}
                    id="new_user"
                    placeholder="Total de usuarios nuevos"
                    disabled
                  />
                </FormGroup>
              </Form>
            </Col>
            <Col xs={12} className="text-center">
              <h4>Reporte mensual por usuarios</h4>
            </Col>
            <Col xs={12}>
              <Datatable
                data={props.dataReportMonth}
                columns={COLUMNS_REPORT_MENSUAL()}
                keyProp="tr_id"
                exportD={false}
                searchs={true}
                selectRows={false}
                RadioBtn={false}
                optionSelectRow={null}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Home;