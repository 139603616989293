import React, { Component } from "react";
import { Row, Col, Label, Button, Container } from "reactstrap";
import REACT_PRINT_COMPONENT from "./../Datatable/React_print";
import {
  getCotizacion,
  putCotizacion,
  postCotizacion,
  getCotizacionDetail,
  putCotizacionDetail,
  postCotizacionDetail,
  putCotizacionDetailDelete,
  putCotizacionDelete,
  getSetting
} from "../Services";
import {
  COLUMNS_COTIZACION,
  COLUMNS_COTIZACION_DETAIL,
} from "./../Datatable/DatatableColumns";
import Datatable from "./../Datatable/Index";
import { PENCIL, SEARCH, LOGO,LOGOUT } from "../SrcImages";
import {
  ModalAddCotizacion,
  ModalAddCotizacionDetail,
} from "./../../Components/Util";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import moment from "moment";
import StoreContext from "./../../Components/Store/Context";
const override = css`
  margin: 0 auto;
  position: relative;
  top: 35px;
  right: 10px;
`;

class Cotizaciones extends Component {
  static contextType = StoreContext
  constructor() {
    super();
    this.state = {
      dataCotizacion: [],
      formSetting:{
        set_direccion:"",
        set_celphone:"",
        set_ruc:""
      },
      formCotizacion: {
        cot_id: "",
        cot_name: "",
        cot_ruc: "",
        cot_celular: "",
        cot_date: "",
        cot_observacion: "",
        cot_sts: "",
      },
      dataCotizacionDetail: [],
      formCotizacionDetail: {
        cotd_id: "",
        cotd_art_descripcion: "",
        cotd_qty: "",
        cotd_lin: "",
        cotd_itbms: "",
        cotd_price: "",
        cotd_sts: "",
      },
      loading: false,
      isOpenModalAddCotizacion: false,
      isOpenModalEditCotizacion: false,
      isOpenDetailCotizacion: false,
      isOpenModalAddCotizacionDetail: false,
      isOpenModalEditCotizacionDetail: false,
      isPrintDetail: false,
      msg_error: "",
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onchangeFormCotizacionDetail =
      this.onchangeFormCotizacionDetail.bind(this);
    this.onchangeFormCotizacion = this.onchangeFormCotizacion.bind(this);
    this.toggleSaveCotizacionDetail =
      this.toggleSaveCotizacionDetail.bind(this);
    this.toggleSaveCotizacion = this.toggleSaveCotizacion.bind(this);
    this.handleModalAddCotizacion = this.handleModalAddCotizacion.bind(this);
    this.handleModalEditCotizacion = this.handleModalEditCotizacion.bind(this);
    this.fetchCotizacion = this.fetchCotizacion.bind(this);
    this.handlerSetCotizacion = this.handlerSetCotizacion.bind(this);
    this.fetchCotizacionDetail = this.fetchCotizacionDetail.bind(this);
    this.toggleCotizacion = this.toggleCotizacion.bind(this);
    this.handleModalEditCotizacionDetail =
      this.handleModalEditCotizacionDetail.bind(this);
    this.handleModalAddCotizacionDetail =
      this.handleModalAddCotizacionDetail.bind(this);
    this.rechargeDataDetail = this.rechargeDataDetail.bind(this);
    this.toggleCotizacionDetail = this.toggleCotizacionDetail.bind(this);
    this.handlerSetCotizacionDetail =
      this.handlerSetCotizacionDetail.bind(this);
    this.closePlanelDetail = this.closePlanelDetail.bind(this);
    this.togglePrint = this.togglePrint.bind(this);
    this.print = this.print.bind(this);
    this.setItbms = this.setItbms.bind(this);
    this.itbmsFormmatter = this.itbmsFormmatter.bind(this);
    this.printDetailInvoice = this.printDetailInvoice.bind(this);
    this.handleDeleteCotDetail = this.handleDeleteCotDetail.bind(this);
    this.handleDeleteCot = this.handleDeleteCot.bind(this);
    this.fetchSetting = this.fetchSetting.bind(this);
  }
  handleGoBack() {
    this.setState({ isPrintDetail:false });
  }
  printDetailInvoice() {
    this.fetchCotizacionDetail();
    this.setState({ isPrintDetail: true });
  }

  iPrintBody = () => {

    const props = this.state;
    var total = 0;
    var sub_total = 0;
    var itbms = 0;

    return (
      <React.Fragment>
        <Row className="mt-5 mb-5" key={new Date().toString()}>
          <Col xs={1}></Col>
          <Col xs={10} className="text-center mt-4 mb-4">
            <img alt="" className="logo-school-informe" src={LOGO}></img>
          </Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={5}>
            <Label>
              <b>
               {props.formSetting.set_direccion}
                <br></br>
                TELÉFONO : {props.formSetting.set_celphone}
                <br></br>
                M-TECHNOLOGY
                <br></br>
                RUC : {props.formSetting.set_ruc}
              </b>
            </Label>{" "}
          </Col>
          <Col xs={5} className="text-right">
            <h4 className="font-weight-bold h4-prelative">
              COTIZACIÓN
              <br></br>
            </h4>
            <Label>
              <b> FECHA : </b>
              {moment(props.formCotizacion.cot_date).format("YYYY-MM-DD")}
            </Label>{" "}
            <br></br>
            <b> VALIDO HASTA : </b>
            {moment(props.formCotizacion.cot_date)
              .add(1, "M")
              .format("YYYY-MM-DD")}
            <br></br>
            <Label className="font-weight-bold">
              COTIZACIÓN # {props.formCotizacion.cot_id}
            </Label>{" "}
          </Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={3} className="text-left header-cot  h-25px cliente-div">
            <Label className="font-weight-bold">CLIENTE</Label>
          </Col>
          <Col xs={7} className="text-right"></Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={5} className="text-left">
            <Label>
              <b>NOMBRE : </b> {props.formCotizacion.cot_name.toUpperCase()}
            </Label>{" "}
            <br></br>
            <Label>
              <b>RUC :</b> {props.formCotizacion.cot_ruc.toUpperCase()}
            </Label>{" "}
            <br></br>
            <Label>
              <b>TELÉFONO :</b> {props.formCotizacion.cot_celular.toUpperCase()}
            </Label>{" "}
          </Col>
          <Col xs={5} className="text-right mb-5"></Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={10} className="text-center border-print mt-2">
            <table className="table table-bordered">
              <thead>
                <tr className="header-cot p-unset m-unset">
                  <th className="text-center w-55p p-unset m-unset">
                    DESCRIPCIÓN
                  </th>
                  <th className="text-center p-unset m-unset">CANTIDAD</th>
                  <th className="text-center p-unset m-unset">PRECIO</th>
                  <th className="text-center p-unset m-unset">ITBMS</th>
                  <th className="text-center p-unset m-unset w-15px">TOTAL</th>
                </tr>
              </thead>
              <tbody className="ft-size14">
                {props.dataCotizacionDetail.map((data, i) => {
                  sub_total =
                    sub_total + Number(data.cotd_price * data.cotd_qty);
                  if (data.cotd_itbms !== "0") {
                    itbms =
                      itbms +
                      Number(data.cotd_price) *
                        Number(data.cotd_qty) *
                        (Number(data.cotd_itbms) / 100);
                  }

                  total = total + Number(data.cotd_price * data.cotd_qty);

                  return (
                    <tr key={i}>
                      <td className="text-left  p-unset2 m-unset2">
                        {data.cotd_art_descripcion}
                      </td>
                      <td className="text-center  p-unset2 m-unset2">
                        {data.cotd_qty}
                      </td>
                      <td className="text-right  p-unset2 m-unset2">
                        {Number(data.cotd_price).toFixed(2)}
                      </td>
                      <td className="text-right  p-unset2 m-unset2">
                        {(
                          Number(data.cotd_itbms / 100) *
                          Number(data.cotd_price * data.cotd_qty)
                        ).toFixed(2)}
                      </td>
                      <td className="text-right font-weight-bold bg-total  p-unset2 m-unset2">
                        {(
                          Number(data.cotd_itbms / 100) *
                            Number(data.cotd_price * data.cotd_qty) +
                          Number(data.cotd_qty * data.cotd_price)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="bg-total"></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="bg-total"></td>
                </tr>
                <tr>
                  <td className=""></td>
                  <td className=""></td>
                  <td className=""></td>
                  <td className="text-left p-unset2 m-unset2">
                    <Label className="font-weight-bold">SUBTOTAL</Label>
                  </td>
                  <td className="text-right font-weight-bold bg-total p-unset2 m-unset2">
                    {"$ " + sub_total.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className=""></td>
                  <td className=""></td>
                  <td className=""></td>
                  <td className="text-left p-unset2 m-unset2">
                    <Label className="font-weight-bold">ITBMS</Label>
                  </td>
                  <td className="text-right font-weight-bold bg-total p-unset2 m-unset2">
                    {"$ " + itbms.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className=""></td>
                  <td className=""></td>
                  <td className=""></td>
                  <td className="text-left p-unset2 m-unset2">
                    <Label className="font-weight-bold">TOTAL</Label>
                  </td>
                  <td className="text-right font-weight-bold bg-total2 p-unset2 m-unset2">
                    {"$ " + Number(total + itbms).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={3} className="text-left header-cot  h-25px cliente-div">
            <Label className="font-weight-bold">TÉRMINOS Y CONDICIONES</Label>
          </Col>
          <Col xs={7} className="text-right"></Col>
          <Col xs={1}></Col>
          <Col xs={1}></Col>
          <Col xs={10} className="text-left">
            <Label>{props.formCotizacion.cot_observacion}</Label>
          </Col>

          <Col xs={1}></Col>
        </Row>
      </React.Fragment>
    );
  };

  onchangeFormCotizacionDetail(e) {
    this.setState({
      formCotizacionDetail: {
        ...this.state.formCotizacionDetail,
        [e.target.name]: e.target.value,
      },
      msg_error: "",
    });
  }

  onchangeFormCotizacion(e) {
    this.setState({
      formCotizacion: {
        ...this.state.formCotizacion,
        [e.target.name]: e.target.value,
      },
      msg_error: "",
    });
  }

  async fetchCotizacionDetail() {
    const response = await getCotizacionDetail(
      this.state.formCotizacion.cot_id
    );
    this.setState({
      dataCotizacionDetail: response,
      isOpenModalAddCotizacion: false,
      isOpenModalEditCotizacion: false,
      isOpenModalAddCotizacionDetail:false,
      loading: false,
      msg_error: "",
      formCotizacionDetail: {
        cotd_id: "",
        cotd_art_descripcion: "",
        cotd_qty: "",
        cotd_lin: "",
        cotd_itbms: "",
        cotd_price: "",
        cotd_sts: "",
      },
    });
  }

  async fetchCotizacion() {
    const response = await getCotizacion(this.context.usr_id);
    this.setState({
      dataCotizacion: response,
      isOpenModalAddCotizacion: false,
      isOpenModalEditCotizacion: false,
      isOpenDetailCotizacion:false,
      isOpenModalAddCotizacionDetail:false,
      loading: false,
      msg_error: "",
    });
  }

  toggleSaveCotizacionDetail() {
    const props = this.state.formCotizacionDetail;
    var itbms = 0;
    if (props.cotd_itbms === "0") {
      itbms = "0";
    }
    else{
      itbms ="7";
    }

    if (props.cotd_id.length === 0) {
      if (
        props.cotd_art_descripcion.length > 0 &&
        props.cotd_qty.length > 0 &&
        props.cotd_price.length > 0
      ) {
        const body = {
          cotd_id: this.state.formCotizacion.cot_id,
          cotd_art_descripcion: props.cotd_art_descripcion,
          cotd_qty: props.cotd_qty,
          cotd_lin: Number(this.state.dataCotizacionDetail.length) + 1,
          cotd_itbms: itbms,
          cotd_price: props.cotd_price,
        };
        postCotizacionDetail(body);
        setTimeout(() => {
          this.fetchCotizacionDetail();
          this.setState({ loading:false, isOpenModalEditCotizacionDetail:false, isOpenModalAddCotizacionDetail:false });
        }, 1000);
      } else {
        this.setState({ msg_error: "Campos vacios" });
      }
    } else {
      if (
        props.cotd_art_descripcion.length > 0 &&
        props.cotd_qty.length > 0 &&
        props.cotd_price.length > 0
      ) {
        const body = {
          cotd_n: props.cotd_n,
          cotd_id: props.cotd_id,
          cotd_art_descripcion: props.cotd_art_descripcion,
          cotd_qty: props.cotd_qty,
          cotd_itbms: itbms,
          cotd_price: props.cotd_price,
        };

        putCotizacionDetail(body);
        setTimeout(() => {
          this.fetchCotizacionDetail();
          this.setState({ loading:false, isOpenModalEditCotizacionDetail:false, isOpenModalAddCotizacionDetail:false });
        }, 1000);
      } else {
        this.setState({ msg_error: "Campos vacios" });
      }
    }
  }

  toggleSaveCotizacion() {
    const props = this.state.formCotizacion;
    if (props.cot_id.length === 0) {
      if (props.cot_name.length > 0) {
        const body = {
          cot_name: props.cot_name,
          cot_ruc: props.cot_ruc,
          cot_celular: props.cot_celular,
          cot_date: moment(new Date()).format("YYYY-MM-DD"),
          cot_observacion: props.cot_observacion,
          cot_suc: this.context.usr_id
        };
        postCotizacion(body);
        setTimeout(() => {
          this.fetchCotizacion();
        }, 1000);
      } else {
        this.setState({ msg_error: "Campos vacios" });
      }
    } else {
      if (props.cot_name.length > 0) {
        const body = {
          cot_id: props.cot_id,
          cot_name: props.cot_name,
          cot_ruc: props.cot_ruc,
          cot_celular: props.cot_celular,
          cot_date: moment(new Date()).format("YYYY-MM-DD"),
          cot_observacion: props.cot_observacion,
        };

        putCotizacion(body);
        setTimeout(() => {
          this.fetchCotizacion();
        }, 1000);
      } else {
        this.setState({ msg_error: "Campos vacios" });
      }
    }
  }

  closePlanelDetail = (row) => {
    this.setState((prevState) => ({
      isOpenDetailCotizacion: !prevState.isOpenDetailCotizacion,
      dataCotizacionDetail: [],
      formCotizacionDetail: {
        cotd_id: "",
        cotd_art_descripcion: "",
        cotd_qty: "",
        cotd_lin: "",
        cotd_itbms: "",
        cotd_price: "",
        cotd_sts: "",
      },
      formCotizacion: {
        cot_id: "",
        cot_name: "",
        cot_ruc: "",
        cot_celular: "",
        cot_date: "",
        cot_observacion: "",
        cot_sts: "",
      },
    }));
  };

  handleModalEditCotizacionDetail = (row) => {
    this.setState((prevState) => ({
      isOpenModalEditCotizacionDetail:
        !prevState.isOpenModalEditCotizacionDetail,
      formCotizacionDetail: row,
      msg_error: "",
    }));
  };

  handleModalAddCotizacionDetail = () => {
    this.setState((prevState) => ({
      isOpenModalAddCotizacionDetail: !prevState.isOpenModalAddCotizacionDetail,
      formCotizacionDetail: {
        cotd_id: "",
        cotd_art_descripcion: "",
        cotd_qty: "",
        cotd_lin: "",
        cotd_itbms: "0",
        cotd_price: "",
        cotd_sts: "",
      },
      msg_error: "",
    }));
  };

  handleModalEditCotizacion = (row) => {
    this.setState((prevState) => ({
      isOpenModalEditCotizacion: !prevState.isOpenModalEditCotizacion,
      formCotizacion: row,
      msg_error: "",
    }));
  };

  handleModalAddCotizacion = () => {
    this.setState((prevState) => ({
      isOpenModalAddCotizacion: !prevState.isOpenModalAddCotizacion,
      formCotizacion: {
        cot_id: "",
        cot_name: "",
        cot_ruc: "",
        cot_celular: "",
        cot_date: "",
        cot_observacion: "",
        cot_sts: "",
      },
      msg_error: "",
    }));
  };

  rechargeData() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchCotizacion();
    }, 1000);
  }

  rechargeDataDetail() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchCotizacionDetail();
    }, 1000);
  }
  
  handleDeleteCotDetail(row) {
    const body = {
      cotd_n: row.cotd_n,
    };
    putCotizacionDetailDelete(body);
    setTimeout(() => {
      this.fetchCotizacionDetail();
    }, 1000);
  }

  handleDeleteCot(row) {

    const body = {
      cot_id: row.cot_id,
    };
    putCotizacionDelete(body);
    setTimeout(() => {
      this.fetchCotizacion();
    }, 1000);
  }

  handlerSetCotizacion(row) {
    this.setState((prevState) => ({
      formCotizacion: row,
      msg_error: "",
      isOpenDetailCotizacion: true,
    }));
    setTimeout(() => {
      this.fetchCotizacionDetail();
    }, 1000);
  }

  handlerSetCotizacionDetail(row) {
    this.setState((prevState) => ({
      formCotizacionDetail: row,
      isOpenModalEditCotizacionDetail: true,
      msg_error: "",
    }));
  }

  toggleCotizacion(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <img
              className="img-status img-search"
              onClick={() => this.handlerSetCotizacion(row)}
              src={SEARCH}
              alt=""
            />
            <img
              className="img-status img-search"
              onClick={() => this.handleModalEditCotizacion(row)}
              src={PENCIL}
              alt=""
            />
             <img
              className="img-status img-search"
              onClick={() => this.handleDeleteCot(row)}
              src={LOGOUT}
              alt=""
            />
           
            
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  itbmsFormmatter(column, colIndex, { text }) {
    return (
      <h5>
        <strong>$$ {column.text} $$</strong>
      </h5>
    );
  }

  toggleCotizacionDetail(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <img
              className="img-status img-search"
              onClick={() => this.handlerSetCotizacionDetail(row)}
              src={PENCIL}
              alt=""
            />
             <img
              className="img-status img-search ml-2"
              onClick={() => this.handleDeleteCotDetail(row)}
              src={LOGOUT}
              alt=""
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  async fetchSetting() {
    const response = await getSetting(this.context.usr_id);
    if (response[0] !== undefined) {
      this.setState({
        formSetting: {
          set_direccion:response[0].set_direccion,
          set_celphone:response[0].set_celphone,
          set_ruc:response[0].set_ruc
        },
        loading: false,
      });
    }
  }

  componentDidMount() {
    // document.body.classList.remove('body');
    document.body.classList.remove('body-class');
    this.fetchSetting()
    this.fetchCotizacion();
  }
  print(row) {
    this.setState({ loading: true });
  }

  togglePrint(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            <Button color="success" onClick={() => this.print(row)}>
              Imprimir
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  setItbms({ cotd_itbms }) {
    this.setState((prevState) => ({
      formCotizacionDetail: {
        ...this.state.formCotizacionDetail,
        cotd_itbms,
      },
      msg_error: "",
    }));
  }
  render() {
    document.body.classList.add('body-class');
    const props = this.state;
    var sum=0;
    props.dataCotizacionDetail.map((row, i) => {
      sum = Number(sum) + (Number((row.cotd_price*row.cotd_qty)*(row.cotd_itbms/100)) + Number(row.cotd_price*row.cotd_qty));
      return null
    })

    return (
      <Container className="">
      <React.Fragment>
        {props.isOpenModalAddCotizacion && (
          <ModalAddCotizacion
            dataFormCotizacion={props.formCotizacion}
            toggleSaveCotizacion={this.toggleSaveCotizacion}
            onchangeFormCotizacion={this.onchangeFormCotizacion}
            toggleModal={this.handleModalAddCotizacion}
            title="Agregar nueva cotizacion"
            isOpenModal={props.isOpenModalAddCotizacion}
            msg_error={props.msg_error}
          />
        )}

        {props.isOpenModalEditCotizacion && (
          <ModalAddCotizacion
            dataFormCotizacion={props.formCotizacion}
            toggleSaveCotizacion={this.toggleSaveCotizacion}
            onchangeFormCotizacion={this.onchangeFormCotizacion}
            toggleModal={this.handleModalEditCotizacion}
            title="Editar cotizacion"
            isOpenModal={props.isOpenModalEditCotizacion}
            msg_error={props.msg_error}
          />
        )}

        {props.isOpenModalAddCotizacionDetail && (
          <ModalAddCotizacionDetail
            dataFormCotizacion={props.formCotizacionDetail}
            toggleSaveCotizacion={this.toggleSaveCotizacionDetail}
            onchangeFormCotizacion={this.onchangeFormCotizacionDetail}
            toggleModal={this.handleModalAddCotizacionDetail}
            title="Agregar detalle cotización"
            isOpenModal={props.isOpenModalAddCotizacionDetail}
            msg_error={props.msg_error}
            setItbms={this.setItbms}
          />
        )}

        {props.isOpenModalEditCotizacionDetail && (
          <ModalAddCotizacionDetail
            dataFormCotizacion={props.formCotizacionDetail}
            toggleSaveCotizacion={this.toggleSaveCotizacionDetail}
            onchangeFormCotizacion={this.onchangeFormCotizacionDetail}
            toggleModal={this.handleModalEditCotizacionDetail}
            title="Editar detalle cotizacion"
            isOpenModal={props.isOpenModalEditCotizacionDetail}
            msg_error={props.msg_error}
            setItbms={this.setItbms}
          />
        )}
        <Row>
          <Col xs={12} className="text-center">
            <h4> Cotizaciones</h4>
          </Col>
          {props.isOpenDetailCotizacion ? (
            props.isPrintDetail ? (
              <React.Fragment>
                <Col xs={12} className="text-right">
                  <Button color="primary" onClick={() => this.handleGoBack()}>
                    Cerrar
                  </Button>
                </Col>
                <REACT_PRINT_COMPONENT body={this.iPrintBody()} tittle={props.formCotizacion.cot_name.replace(/ /g, "_") + "_#"+props.formCotizacion.cot_id}/>
              </React.Fragment>
            ) : (
              <React.Fragment>

                <Col xs={12} className="text-right">
                  {props.dataCotizacionDetail.length>0?                 <Button
                    color="info"
                    className="mr-1"
                    onClick={() => this.printDetailInvoice()}
                  >
                    Imprimir
                  </Button> : null }
 
                  <Button
                    color="secondary"
                    className="mr-1"
                    onClick={() => this.closePlanelDetail()}
                  >
                    Cerrar
                  </Button>
                  <ClipLoader
                    css={override}
                    size={23}
                    color={"#36D7B7"}
                    loading={this.state.loading}
                  />
                  <Button
                    color="info"
                    className="mr-1"
                    onClick={() => this.rechargeDataDetail()}
                  >
                    Recargar
                  </Button>
                  <Button
                    color="primary"
                    className="mr-1"
                    onClick={() => this.handleModalAddCotizacionDetail()}
                  >
                    Agregar
                  </Button>
                </Col>

                <Col xs={12} className="scroll-ac">
                  <Datatable
                    data={props.dataCotizacionDetail}
                    columns={COLUMNS_COTIZACION_DETAIL(
                      this.toggleCotizacionDetail,
                      sum
                    )}
                    keyProp="cotd_n"
                    exportD={false}
                    searchs={true}
                    selectRows={false}
                    RadioBtn={false}
                    optionSelectRow={null}
                  />
                </Col>
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <Col xs={12} className="text-right mb-5">
                <ClipLoader
                  css={override}
                  size={23}
                  color={"#36D7B7"}
                  loading={this.state.loading}
                />
                <Button
                  color="info"
                  className="mr-2 "
                  onClick={() => this.rechargeData()}
                >
                  Recargar
                </Button>
                <Button
                  color="primary"
                  className="mr-2 "
                  onClick={() => this.handleModalAddCotizacion()}
                >
                  Agregar
                </Button>
              </Col>
              <Col xs={12} className="scroll-ac">
                <Datatable
                  data={props.dataCotizacion}
                  columns={COLUMNS_COTIZACION(
                    this.toggleCotizacion
                  )}
                  keyProp="cot_id"
                  exportD={false}
                  searchs={true}
                  selectRows={false}
                  RadioBtn={false}
                  optionSelectRow={null}
                />
              </Col>
   
            </React.Fragment>
          )}
        </Row>
      </React.Fragment>
      </Container>
    );
  }
}

export default Cotizaciones;
