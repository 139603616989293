import React, { Component } from "react";
import { CreateUser, FIND_DESC } from "../Util";
import { Row, Col, Button, Label,ButtonGroup, Container} from "reactstrap";
import moment from "moment";
import {LOGO} from '../SrcImages';
import REACT_PRINT_COMPONENT from "../Datatable/React_print";
import { getUsers, postUser, putUser,putChangeStatusUser } from "../Services";
import { PENCIL,COPY } from "../SrcImages";
import Datatable from "../Datatable/Index";
import { COLUMNS_USER_ADMIN } from "../Datatable/DatatableColumns";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import StoreContext from "./../../Components/Store/Context";

const override = css`
  margin: 0 auto;
  position: relative;
  top: 10px;
  right: 10px;
`;

class CreateUsuario extends Component {
  static contextType = StoreContext
  constructor(props) {
    super(props);
    this.state = {
      dataUser: [],
      formUser: {
        usr_id: "",
        usr_name: "",
        usr_user: "",
        usr_password: "",
        usr_celphone: "",
        usr_email: "",
        usr_cedula: "",
        usr_address: "",
        usr_birthday: "",
        usr_language: "1",
        usr_box_vol: "",
        usr_box_pr: "",
        usr_code: "",
        usr_status: "",
        usr_price:"",
        usr_handler:"",
        usr_sex:"1",
        usr_not:"1"
      },
      n_box: "0",
      n_siglas: "",
      set_address: "",
      loading: false,
      isOpenModalAddUser: false,
      isOpenModalEditUser: false,
      msg_error: "",
      isPrinting:false
    };
    this.onchangeFormUser = this.onchangeFormUser.bind(this);
    this.toggleSaveUser = this.toggleSaveUser.bind(this);
    this.setTypeLanguage = this.setTypeLanguage.bind(this);
    this.handleModalAddUsuarios = this.handleModalAddUsuarios.bind(this);
    this.toggleEditUser = this.toggleEditUser.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.handlerSetUsuario = this.handlerSetUsuario.bind(this);
    this.handleModalEditUsuarios = this.handleModalEditUsuarios.bind(this);
    this.togglePrint = this.togglePrint.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.toggleDeleteUser = this.toggleDeleteUser.bind(this);
    this.handlerChangeSts = this.handlerChangeSts.bind(this);
    this.seTypeHandlerPayment = this.seTypeHandlerPayment.bind(this);
    this.seTypeSex = this.seTypeSex.bind(this);
    this.seTypeHandlerNotification = this.seTypeHandlerNotification.bind(this);
  }

  async fetchUser() {
    const response = await getUsers(this.context.usr_id);
    var set_siglas ="";
    var set_price_libra ="";
    var set_address ="";
    if(response.set_siglas.length>0){
      set_siglas = response.set_siglas[0].set_siglas
      set_price_libra = response.set_price_libra[0].set_price_libra
      set_address = response.set_address[0].set_address
    }

    this.setState({
      dataUser: response.usr,
      n_box: response.n_box[0].n_box,
      n_siglas: set_siglas,
      set_price_libra: set_price_libra,
      set_address: set_address,
      isOpenModalAddUser: false,
      isOpenModalEditUser: false,
      loading: false,
      msg_error: "",
    });
  }

  toggleSaveUser() {
    const props = this.state.formUser;
    if (props.usr_id.length === 0) {
      if (FIND_DESC(this.state.dataUser, props.usr_celphone)) {
        this.setState({
          msg_error: "El numero de telefono ya se encuentra registrado",
        });
      } else {
        if (
          props.usr_email.length > 0 &&
          props.usr_cedula.length > 0 &&
          props.usr_name.length > 0 &&
          props.usr_birthday.length > 0 &&
          props.usr_price.length > 0
        ) {
          const body = {
            usr_name: props.usr_name,
            usr_user: props.usr_user,
            usr_password: props.usr_password,
            usr_celphone: props.usr_celphone,
            usr_email: props.usr_email,
            usr_cedula: props.usr_cedula,
            usr_address: props.usr_address,
            usr_birthday: moment(props.usr_birthday).format("YYYY-MM-DD"),
            usr_language: props.usr_language,
            usr_box_vol: props.usr_box_vol,
            usr_box_pr:props.usr_code +" " +props.usr_name.toUpperCase() +"\n" +this.state.set_address,
            usr_code: props.usr_code,
            usr_price:props.usr_price,
            usr_handler:props.usr_handler,
            usr_sex:props.usr_sex,
            usr_not:props.usr_not,
            usr_suc:this.context.usr_id
          };
          postUser(body);
          setTimeout(() => {  
          this.setState({
            loading: true,
          });
            this.fetchUser();
          }, 1000);
        } else {
          this.setState({ msg_error: "Algunos campos estan en blanco" });
        }
      }
    } else {
      const body = {
        usr_id: props.usr_id,
        usr_name: props.usr_name,
        usr_celphone: props.usr_celphone,
        usr_email: props.usr_email,
        usr_cedula: props.usr_cedula,
        usr_address: props.usr_address,
        usr_birthday: moment(props.usr_birthday).format("YYYY-MM-DD"),
        usr_language: props.usr_language,
        usr_box_vol: props.usr_box_vol,
        usr_box_pr: props.usr_code +" " +props.usr_name.toUpperCase() +"\n" +this.state.set_address,
        usr_price:props.usr_price,
        usr_handler:props.usr_handler,
        usr_code: props.usr_code,
        usr_sex:props.usr_sex,
        usr_not:props.usr_not
      };

      putUser(body);
      setTimeout(() => {
        this.setState({
          loading: true,
        });
        this.fetchUser();
      }, 1000);
    }
  }

  onchangeFormUser(e) {
    this.setState({
      formUser: {
        ...this.state.formUser,
        [e.target.name]: e.target.value,
      },
    });
  }
  
  seTypeHandlerNotification({ usr_not }) {
    this.setState({
      formUser: {
        ...this.state.formUser,
        usr_not,
      },
    });
  }

  seTypeHandlerPayment({ usr_handler }) {
    this.setState({
      formUser: {
        ...this.state.formUser,
        usr_handler,
      },
    });
  }

  seTypeSex({ usr_sex }) {
    this.setState({
      formUser: {
        ...this.state.formUser,
        usr_sex,
      },
    });
  }

  setTypeLanguage({ usr_language }) {
    this.setState({
      formUser: {
        ...this.state.formUser,
        usr_language,
      },
    });
  }

  handleModalEditUsuarios = () => {
    this.setState((prevState) => ({
      isOpenModalEditUser: !prevState.isOpenModalEditUser,
      formUser: {
        usr_id: "",
        usr_name: "",
        usr_user: "",
        usr_password: "",
        usr_celphone: "",
        usr_email: "",
        usr_cedula: "",
        usr_address: "",
        usr_birthday: "",
        usr_language: "",
        usr_box_vol: "",
        usr_box_pr: "",
        usr_status: "",
        usr_price:"",
        usr_handler:"",
        usr_sex:"",
        usr_not:"1"
      },
      msg_error: "",
    }));
  };

  handleModalAddUsuarios = () => {
    this.setState((prevState) => ({
      isOpenModalAddUser: !prevState.isOpenModalAddUser,
      formUser: {
        usr_id: "",
        usr_name: "",
        usr_user: "",
        usr_password: "",
        usr_celphone: "",
        usr_email: "",
        usr_cedula: "",
        usr_address: "",
        usr_birthday: "",
        usr_language: "1",
        usr_box_vol: "",
        usr_box_pr: "",
        usr_status: "",
        usr_code: this.state.n_siglas + " " +this.paddy(this.state.n_box, 5),
        usr_price:this.state.set_price_libra,
        usr_handler:"",
        usr_sex:"",
        usr_not:"1"
      },
      msg_error: "",
    }));
  };

  rechargeData() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchUser();
    }, 1000);
  }

  handlerSetUsuario(row) {
    this.setState((prevState) => ({
      isOpenModalEditUser: !prevState.isOpenModalEditUser,
      formUser: row,
    }));
  }
  
  handlerSetCasillero(row) {
    navigator.clipboard.writeText(
    row.usr_box_pr
    );
  }

  toggleEditUser(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="d-flex">
            <img
              className="img-status img-search"
              onClick={() => this.handlerSetUsuario(row)}
              src={PENCIL}
              alt=""
            />
             <img
              className="img-status img-search"
              onClick={() => this.handlerSetCasillero(row)}
              src={COPY}
              alt=""
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
  
  componentDidMount() {
    this.fetchUser();
  }

  paddy(num, padlen, padchar) {
    var pad_char = typeof padchar !== "undefined" ? padchar : "0";
    var pad = new Array(1 + padlen).join(pad_char);
    return (pad + num).slice(-pad.length);
  }

  handleGoBack() {
    this.setState({ isPrinting: false });
  }

  print() {
    this.setState({ isPrinting: true });
  }

  togglePrint(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            <Button color="success" onClick={() => this.print()}>
              Imprimir
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  iPrintBody = () => {
    const props = this.state;
    return (
      <React.Fragment>
        <Row className=" mb-5" key={new Date().toString()}>
            <Col xs={2}>
            </Col>
            <Col xs={8} className="text-center mb-4">
            <img alt="" className="logo-school-informe" src={LOGO}></img>
          </Col>
            <Col xs={2}>
            </Col>
            <Col xs={1}>
          </Col>
          <Col xs={10} className="text-left mb-3">
            <Label className="font-weight-bold">
              Fecha de impresion: {moment(new Date()).format("YYYY-MM-DD")}
            </Label>{" "}
          </Col>
          <Col xs={1}>
          </Col>
          <Col xs={1}>
          </Col>
          <Col xs={10} className="text-center border-print">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center w-300px">ID</th>
                  <th className="text-center w-250px">NOMBRE</th>
                  <th className="text-center">CODIGO</th>
                  <th className="text-center">PRECIO</th>
                  <th className="text-center w-300px">TELEFONO</th>
                  <th className="text-center w-250px">EMAIL</th>
                </tr>
              </thead>
              <tbody>
                {props.dataUser.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-left font-weight-bold">{data.usr_id}</td>
                      <td className="text-left">
                      {data.usr_user}
                      </td>
                      <td className="text-left">{data.usr_code}</td>
                      <td className="text-left">{data.usr_price}</td>
                      <td className="text-left">{data.usr_celphone}</td>
                      <td className="text-left">{data.usr_email}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
          <Col xs={1}>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  handlerChangeSts(row) {
    const body = {
      usr_id: row.usr_id,
      usr_status: row.usr_status,
    };

    this.setState({ loading: true });
    putChangeStatusUser(body);
    setTimeout(() => {
      this.fetchUser();
    }, 1000);
  }

  toggleDeleteUser = (cell, row) => {
    return (
      <Row>
        <Col sm={12} className="text-center m-auto">
          <ButtonGroup>
            <Button outline onClick={() => this.handlerChangeSts(row)} active={row.usr_status === "1" ? true : false}>
              SI
            </Button>
            <Button outline onClick={() => this.handlerChangeSts(row)} active={row.usr_status === "0" ? true : false}>
              NO
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    );
  };

  render() {
    document.body.classList.add('body-class');
    const props = this.state;
    return (
      <Container className="div-container" >
         <React.Fragment>
        {props.isOpenModalAddUser && (
          <CreateUser
            toggleSaveUser={this.toggleSaveUser}
            onchangeFormUser={this.onchangeFormUser}
            dataFormUser={props.formUser}
            setTypeLanguage={this.setTypeLanguage}
            toggleModal={this.handleModalAddUsuarios}
            title="Agregar Usuario"
            isOpenModal={props.isOpenModalAddUser}
            n_box={props.n_siglas + " "+ this.paddy(props.n_box, 5)}
            set_address={props.set_address}
            msg_error={props.msg_error}
            seTypeHandlerPayment ={this.seTypeHandlerPayment}
            seTypeSex={this.seTypeSex}
            seTypeHandlerNotification={this.seTypeHandlerNotification}
            loading ={props.loading}
          />
        )}

        {props.isOpenModalEditUser && (
          <CreateUser
            toggleSaveUser={this.toggleSaveUser}
            onchangeFormUser={this.onchangeFormUser}
            dataFormUser={props.formUser}
            setTypeLanguage={this.setTypeLanguage}
            toggleModal={this.handleModalEditUsuarios}
            title="Editar Usuario"
            isOpenModal={props.isOpenModalEditUser}
            n_box={props.formUser.usr_code}
            set_address={props.set_address}
            msg_error={props.msg_error}
            seTypeHandlerPayment ={this.seTypeHandlerPayment}
            seTypeSex={this.seTypeSex}
            seTypeHandlerNotification={this.seTypeHandlerNotification}
            loading ={props.loading}
          />
        )}
         {props.isPrinting ? (
           <Row className="mt-3">
                         <Col xs={1}></Col>
             <Col xs={10} className="text-right">
             <Button
                color="primary"
                onClick={() => this.handleGoBack()}
              >
                Cerrar
              </Button>
             </Col>
             <Col xs={1}></Col>
             <Col xs={12}>
             <REACT_PRINT_COMPONENT body={this.iPrintBody()} />
             </Col>
           </Row>
            ) :
            <Row>
            <Col xs={12} className="text-center mt-3">
              <h4> Usuarios</h4>
            </Col>
            <Col xs={12} className="text-right mt-2">
              <ClipLoader
                css={override}
                size={23}
                color={"#36D7B7"}
                loading={this.state.loading}
              />
              <Button
                color="info"
                className="mr-2"
                onClick={() => this.rechargeData()}
              >
                Recargar
              </Button>
              <Button color="danger" className="mr-2" onClick={() => this.print()}>
              PDF
            </Button>
              <Button
                color="primary"
                onClick={() => this.handleModalAddUsuarios()}
              >
                Agregar
              </Button>
            </Col>
            <Col xs={12} className="scroll-ac">
              <Datatable
                data={props.dataUser}
                columns={COLUMNS_USER_ADMIN(this.toggleEditUser,this.toggleDeleteUser)}
                keyProp="usr_id"
                exportD={false}
                searchs={true}
                selectRows={false}
                RadioBtn={false}
                optionSelectRow={null}
              />
            </Col>
          </Row>
            }

      </React.Fragment>
        </Container>
     
    );
  }
}

export default CreateUsuario;
