import React, { Component } from "react";
import { Row, Col, Button, Label, Input, Container } from "reactstrap";
import REACT_PRINT_COMPONENT from "../Datatable/React_print";
import {LOGO} from '../SrcImages';
import {
  getTrackingAll,
  putDelivered,
  getTrackingByCodeUser,NOMBRE_EMPRESA,RUC,UBICACION,TELEFONO,print_receipt
} from "../Services";
import moment from "moment";
import Datatable from "../Datatable/Index";
import { COLUMNS_TRACKING_SELECT } from "../Datatable/DatatableColumns";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  margin: 0 auto;
  position: relative;
  top: 10px;
  right: 10px;
`;

class ConsultarUser extends Component {
  constructor() {
    super();
    this.state = {
      dataFormLot: [],
      formLot: {
        lot_id: "",
        lot_desc: "",
        lot_date: new Date().toString(),
        lot_status: "",
      },
      dataPrint:[],
      DataClientes: [],
      dataTracking: [],
      formTracking: {
        tr_id: "",
        tr_lot: "",
        tra_id: "",
        tr_track: "",
        tr_desc: "",
        tr_price: "",
        tr_user: "",
        tr_type_box: "2",
        tr_libra: "",
        tr_date_delivered: "",
        tr_date_received: moment(new Date()).format("YYYY-MM-DD"),
        tr_status: "",
        usr_code: "",
        tr_use_tarjeta: "0",
        tr_local: "0",
      },
      local_selected: "1",
      loading: false,
      isOpenModalAddLot: false,
      isOpenModalEditLot: false,
      isOpenPanelTracking: false,
      isOpenModalAddTracking: false,
      isOpenModalEditTracking: false,
      msg_error: "",
      isPrinting:false
    };

    this.fetchTracking = this.fetchTracking.bind(this);
    this.fetchTrackingByCode = this.fetchTrackingByCode.bind(this);
    this.onchangeFormLot = this.onchangeFormLot.bind(this);
    this.rechargeData = this.rechargeData.bind(this);
    this.handlerPutDelivered = this.handlerPutDelivered.bind(this);
    this.toggleMakeDelivered = this.toggleMakeDelivered.bind(this);
    this.searchByCode = this.searchByCode.bind(this);
    this.onChangeData = this.onChangeData.bind(this);
    this.togglePrint = this.togglePrint.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.print = this.print.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.printReceipt = this.printReceipt.bind(this);
  }

  async fetchTrackingByCode() {
    const props = this.state.formTracking;
    const response = await getTrackingByCodeUser(props.usr_code);
    this.setState({
      dataTracking: response,
      loading: false,
    });
  }

  searchByCode() {
    this.fetchTrackingByCode();
  }

  async fetchTracking() {
    const response = await getTrackingAll();
    this.setState({
      dataTracking: response,
      loading: false,
    });
  }

  onchangeFormLot(e) {
    this.setState({
      formLot: {
        ...this.state.formLot,
        [e.target.name]: e.target.value,
      },
      msg_error: "",
    });
  }

  rechargeData() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchLot();
    }, 1000);
  }

  componentDidMount() {
    this.fetchTracking();
  }

  handlerPutDelivered(row) {
    const body = {
      tr_date_delivered: moment(new Date()).format("YYYY-MM-DD"),
      tr_id: row.tr_id,
    };
    putDelivered(body);
    setTimeout(() => {
      this.fetchTracking();
    }, 1000);
  }

  toggleMakeDelivered(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            {row.tr_date_delivered !== null ? moment(row.tr_date_delivered).format("YYYY-MM-DD")  : (
              <Button
                color="primary"
                onClick={() => this.handlerPutDelivered(row)}
              >
                Entregar
              </Button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  onChangeData(e) {
    this.setState({
      formTracking: {
        ...this.state.formTracking,
        [e.target.name]: e.target.value,
      },
    });
  }

  rechargeDataTracking() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchTracking();
    }, 1000);
  }

  print() {
    this.setState({ isPrinting: true });
  }
  
  iPrintBody = () => {
    const props = this.state;

    return (
      <React.Fragment>
        <Container className="div-container">
        <Row className="mb-5" key={new Date().toString()}>
            <Col xs={2}>
            </Col>
            <Col xs={8} className="text-center mb-4">
            <img alt="" className="logo-school-informe" src={LOGO}></img>
          </Col>
            <Col xs={2}>
            </Col>
            <Col xs={1}>
          </Col>
          <Col xs={10} className="text-left mb-3">
            {/* <Label className="font-weight-bold">Fecha : {moment(new Date(props.dateIDairy)).format("YYYY-MM-DD")}</Label> <br></br> */}
            <Label className="font-weight-bold">
              Fecha de impresion: {moment(new Date()).format("YYYY-MM-DD")}
            </Label>{" "}
          </Col>
          <Col xs={1}>
          </Col>
          <Col xs={1}>
          </Col>
          <Col xs={10} className="text-center border-print">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center w-300px">TRACKING</th>
                  <th className="text-center w-250px">USUARIO</th>
                  <th className="text-center">CODIGO</th>
                  <th className="text-center w-300px">PRECIO</th>
                  <th className="text-center w-250px">DESCRIPCION</th>
                  <th className="text-center w-250px">ENTREGADO</th>
                </tr>
              </thead>
              <tbody>
                {props.dataTracking.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-left font-weight-bold">{data.tr_track}</td>
                      <td className="text-left">
                      {data.usr_name}
                      </td>
                      <td className="text-left">{data.usr_code}</td>
                      <td className="text-left">{data.tr_price}</td>
                      <td className="text-left">{data.lot_desc}</td>
                      <td className="text-left">{moment(data.tr_date_delivered).format("YYYY-MM-DD") }</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
          <Col xs={1}>
          </Col>
        </Row>
        </Container>
 
      </React.Fragment>
    );
  };

  togglePrint(cell, row) {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            <Button color="success" onClick={() => this.print()}>
              Imprimir
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
  handleGoBack() {
    this.setState({ isPrinting: false });
  }

  printReceipt = () => {
    const props = this.state.dataPrint;
    var total=0;
    var total_libras=0;
    props.map((data,i)=>
    {
      total=total+Number(data.tr_price);
      total_libras=total_libras+Number(data.tr_libra)
    })
    const body = {
      NOMBRE_EMPRESA: NOMBRE_EMPRESA,
      RUC_DV: RUC,
      UBICACION: UBICACION,
      TELEFONO: TELEFONO,
      NAME:props[0].usr_name,
      USR_CODE:props[0].usr_code,
      total:total,
      total_qty:props.length,
      total_libras:total_libras,
    //  fecha:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      dataprint:props
    };
    print_receipt(body);
  }

  handleOnSelectAll = (isSelect, rows) => {
    var body = {
      usr_name: isSelect.usr_name,
      usr_code: isSelect.usr_code,
      tr_track: isSelect.tr_track,
      tr_id: isSelect.tr_id,
      tr_price: isSelect.tr_price,
      tr_libra: isSelect.tr_libra,
      lot_id:isSelect.lot_id,
    };
    if(rows){
      this.setState({ dataPrint: this.state.dataPrint.concat(body)})
    }
    else{
      var result = this.state.dataPrint.filter((data, i) => {
        if (isSelect.tr_id !== data.tr_id) {
          return data;
        }
      });
      this.setState({ dataPrint: result})
    }
  }

  render() {
    document.body.classList.add('body-class');
    const props = this.state;

    return (
      <Container className="div-container">
      <React.Fragment>
        {props.isPrinting ? (
           <Row className="mt-3">
                         <Col xs={1}></Col>
             <Col xs={10} className="text-right">
             <Button
                color="primary"
                onClick={() => this.handleGoBack()}
              >
                Cerrar
              </Button>
             </Col>
             <Col xs={1}></Col>
             <Col xs={12}>
             <REACT_PRINT_COMPONENT body={this.iPrintBody()} />
             </Col>

           </Row>
             
            ) : <React.Fragment>
                 <Row>
                 <Col xs={12} className="text-center mt-3">
              <h4> Busqueda de paquetess</h4>
            </Col>
            <Col xs={8}>
              <Input
                type="text"
                name="usr_code"
                rows="5"
                onChange={this.onChangeData}
                defaultValue={props.formTracking.usr_code}
                id="usr_code"
                placeholder="Codigo de cliente o tracking"
                min={0}
                max={1000}
              />
            </Col>
            <Col xs={4}>
              <Button
              color="primary"
                className="info label-area-desc-bold text-right"
                onClick={() => this.searchByCode()}
              >
                Buscar
              </Button>
            </Col>
            <Col xs={12} className="text-right">
              <ClipLoader
                css={override}
                size={23}
                color={"#36D7B7"}
                loading={this.state.loading}
              />
              {props.dataPrint.length>0 && (
                            <Button
                            color="success"
                            className="mr-2  ml-2 btn-buscar-2"
                            onClick={() => this.printReceipt()}
                          >
                            Imprimir
                          </Button>
              )}

              <Button
                color="info"
                className="mr-2  ml-2 btn-buscar-2"
                onClick={() => this.rechargeDataTracking()}
              >
                Recargar
              </Button>
              <Button color="danger" className="mr-2  btn-buscar-2" onClick={() => this.print()}>
                PDF
              </Button>
            </Col>
          </Row>
          <Row>

            <Col xs={12} className="scroll-ac mt-5">
              <Datatable
                data={props.dataTracking}
                columns={COLUMNS_TRACKING_SELECT(this.toggleMakeDelivered)}
                keyProp="tr_id"
                keyField='tr_id'
                exportD={false}
                searchs={true}
                selectRows={true}
                RadioBtn={false}
                optionSelectRow={this.handleOnSelectAll}
                // selectRow={ selectRow }
              />
            </Col>
         
          </Row>
              </React.Fragment>
           }
      </React.Fragment>
      </Container>
    );
  }
}

export default ConsultarUser;